import { getStructureAndFloorAtPoint } from '../../../../src/utils/geom.js'

import testScript1 from './testScript1.js'

const TSCRIPT = testScript1

function SimLocationProvider (script = TSCRIPT, speed = 1) {
  if (!script || !script.length)
    throw Error('pass in a non-zero length script')

  let si = 0 // script index

  function start (cb) {
    const next = () => {
      if (si < 0)
        return // script was stopped

      const [timestamp, latitude, longitude, accuracy, floorLevel] = script[si]
      cb(
        Date.now(),
        latitude,
        longitude,
        accuracy,
        floorLevel,
        'Simulator'
      )
      si++
      if (si < script.length)
        setTimeout(next, (script[si][0] - timestamp) / speed) // call next with timing matched to script
    }
    setTimeout(next, 500) // start script in 5 seconds
  }

  function stop () {
    si = -1
  }

  return {
    start,
    stop
  }
}

let bluedotScript
let watchId
let clearClickWatch

function bluedotRecord () {
  if (watchId)
    navigator.geolocation.clearWatch(watchId)

  bluedotScript = []
  window.bluedotScript = bluedotScript

  watchId = navigator.geolocation.watchPosition(position => {
    const { latitude, longitude, altitude, accuracy, altitudeAccuracy, heading, speed, floorLevel } = position.coords // eslint-disable-line no-unused-vars
    bluedotScript.push(
      [position.timestamp,
        latitude,
        longitude,
        accuracy,
        floorLevel])
  })

  window.alert('Recording bluedot...')
}

function emailRecordedScript () {
  if (!bluedotScript || bluedotScript.length === 0)
    return alert("No positions have been recorded. To record, enter 'debug:recordbluedot") // eslint-disable-line

  if (watchId)
    navigator.geolocation.clearWatch(watchId)
  watchId = null

  if (clearClickWatch)
    clearClickWatch()
  clearClickWatch = null

  alert("Launching Email Client") // eslint-disable-line
  const url = `mailto:?subject=${encodeURIComponent('bluedot tracking')}&body=${encodeURIComponent(JSON.stringify(bluedotScript))}`
  document.location.href = url
}

function makeBluedotScript (app, log) {
  bluedotScript = []
  window.bluedotScript = bluedotScript

  clearClickWatch = app.bus.on('map/click', async ({ lat, lng, ord }) => {
    const mapviewBBox = await app.bus.get('map/getViewBBox')
    bluedotScript.push([
      Date.now(),
      lat,
      lng,
      20,
      // eslint-disable-next-line no-undef
      ordToCLFloor(_venueData.structures, lat, lng, ord, mapviewBBox)
    ])
  })

  log.info('Recording clicks as a bluedot Script - into variable bluedotScript')
  window.alert('Watching your clicks...')
}

const ordToCLFloor = (buildings, lat, lng, ord, mapviewBBox) => {
  // eslint-disable-next-line no-unused-vars
  const { _structure, floor } = getStructureAndFloorAtPoint(buildings, lat, lng, ord, mapviewBBox)
  return floor ? floor.clfloor : null
}

export function initDebugTools (app) {
  const log = app.log.sublog('bluedotSim')

  // Handle debug:recordbluedot
  app.bus.on('homeview/performConfirmedSearch', ({ term, script }) => {
    term = term.toLowerCase()
    if (term === 'debug:recordbluedot')
      bluedotRecord()
    if (term === 'debug:emailbluedot')
      emailRecordedScript()
    if (term === 'debug:makebluedotscript')
      makeBluedotScript(app, log)
  })

  window.makeBluedotScript = () => makeBluedotScript(app, log)
}

export default SimLocationProvider
