import { __, compose, includes, not, prop, find, toLower, drop, last, propEq } from 'ramda'

import { findBoundsOfWaypoints } from '../../../src/utils/bounds.js'
import { distance as getGeoDistanceMetres } from '../../../src/utils/geodesy.js'

import SEGMENT_BADGES from './segmentBadges.js'
import SEGMENT_CATEGORIES from './segmentCategories.js'

/**
 * @typedef Step
 * @property {string} primaryText
 * @property {string} secondaryText
 * @property {string} icon
 * @property {Position} animationAnchor
 * @property {number} eta
 * @property {number} distance
 * @property {boolean} isAccessible
 * @property {Bounds} bounds
 * @property {SecurityWaitTime} securityWaitTimes
 *
 * @typedef Position
 * @property {number} lat
 * @property {number} lng
 * @property {string} floorId
 * @property {number} ordinal
 * @property {string} structureId
 *
 * @param {Segment[]} segments
 * @param {string} startName
 * @param {string} destinationName
 * @param floorIdToNameMap
 * @param {function} T - translate function
 * @param {QueueTypes} queueTypes
 * @param {boolean} requiresAccessibility
 * @return {Step[]} steps - list of navigation steps
 */
function getSteps (segments, startName = '', destinationName = '', floorIdToNameMap, T, queueTypes = {}, requiresAccessibility, securityPois = []) {
  return segments.map((segment, index) => {
    const securityWaitTimes = findPropWaypoints('securityWaitTimes')(segment.waypoints)
    const eta = (securityWaitTimes && !securityWaitTimes.isTemporarilyClosed) // if there is dynamic wait time and checkpoint is open, then use it
      ? securityWaitTimes.queueTime
      : Math.round(calculateSegmentEta(segment.waypoints))
    const distance = Math.round(calculateSegmentDistance(segment.waypoints))
    const icon = getIcon(segment.segmentCategory)
    const animationAnchor = getAnimationAnchor(segment.segmentCategory, segment.waypoints)
    const primaryText = getPrimaryText(segments, index, startName, destinationName, floorIdToNameMap, T, securityPois, queueTypes)
    const secondaryText = getSecondaryText(segment, eta, T)
    const bounds = findBoundsOfWaypoints(segment.waypoints)
    const isAccessible = checkIfAccessible(segment)

    const step = {
      primaryText,
      secondaryText,
      icon,
      animationAnchor,
      eta,
      distance,
      bounds,
      isAccessible,
      securityWaitTimes
    }

    if (segment.poiId)
      step.poiId = segment.poiId

    return step
  })
}

function calculateSegmentEta (waypoints) {
  if (waypoints.length === 1) return waypoints[0].eta // for stairs/elevator segments. should be removed once segment builder will be refactored to not append waypoint from previous semgnent
  return waypoints
    .map(prop('eta'))
    .slice(1) // first waypoint is end of previous segment and is added only to connect segments for navline calculation
    .reduce((segmentEta, eta) => segmentEta + eta, 0.0)
}

function calculateSegmentDistance (waypoints) {
  if (waypoints.length === 1) return waypoints[0].distance // for stairs/elevator segments. should be removed once segment builder will be refactored to not append waypoint from previous semgnent
  return waypoints
    .map(waypoint => waypoint.distance)
    .slice(1) // first waypoint is end of previous segment and is added only to connect segments for navline calculation
    .reduce((segmentDistance, distance) => segmentDistance + distance, 0.0)
}

function getIcon (segmentCategory) {
  switch (segmentCategory) {
    case SEGMENT_CATEGORIES.START:
      return SEGMENT_BADGES.START
    case SEGMENT_CATEGORIES.WALKING_TO_END:
      return SEGMENT_BADGES.END
    case SEGMENT_CATEGORIES.ELEVATOR:
      return SEGMENT_BADGES.ELEVATOR
    case SEGMENT_CATEGORIES.ELEVATOR_UP:
      return SEGMENT_BADGES.ELEVATOR_UP
    case SEGMENT_CATEGORIES.ELEVATOR_DOWN:
      return SEGMENT_BADGES.ELEVATOR_DOWN
    case SEGMENT_CATEGORIES.STAIRS:
      return SEGMENT_BADGES.STAIRS
    case SEGMENT_CATEGORIES.STAIRS_UP:
      return SEGMENT_BADGES.STAIRS_UP
    case SEGMENT_CATEGORIES.STAIRS_DOWN:
      return SEGMENT_BADGES.STAIRS_DOWN
    case SEGMENT_CATEGORIES.ESCALATOR:
      return SEGMENT_BADGES.ESCALATOR
    case SEGMENT_CATEGORIES.ESCALATOR_UP:
      return SEGMENT_BADGES.ESCALATOR_UP
    case SEGMENT_CATEGORIES.ESCALATOR_DOWN:
      return SEGMENT_BADGES.ESCALATOR_DOWN
    case SEGMENT_CATEGORIES.WALKING_TO_PORTAL:
    case SEGMENT_CATEGORIES.WALK:
    case SEGMENT_CATEGORIES.WALK_DOWN:
    case SEGMENT_CATEGORIES.WALK_UP:
      return SEGMENT_BADGES.WALK
    case SEGMENT_CATEGORIES.TRAIN:
      return SEGMENT_BADGES.TRAIN
    case SEGMENT_CATEGORIES.TRAIN_UP:
      return SEGMENT_BADGES.TRAIN_UP
    case SEGMENT_CATEGORIES.TRAIN_DOWN:
      return SEGMENT_BADGES.TRAIN_DOWN
    case SEGMENT_CATEGORIES.BUS:
      return SEGMENT_BADGES.BUS
    case SEGMENT_CATEGORIES.BUS_UP:
      return SEGMENT_BADGES.BUS_UP
    case SEGMENT_CATEGORIES.BUS_DOWN:
      return SEGMENT_BADGES.BUS_DOWN
    case SEGMENT_CATEGORIES.SECURITY_CHECKPOINT:
      return SEGMENT_BADGES.SECURITY_CHECKPOINT
    case SEGMENT_CATEGORIES.RAMP:
      return SEGMENT_BADGES.RAMP
    case SEGMENT_CATEGORIES.RAMP_UP:
      return SEGMENT_BADGES.RAMP_UP
    case SEGMENT_CATEGORIES.RAMP_DOWN:
      return SEGMENT_BADGES.RAMP_DOWN
    default:
      return SEGMENT_BADGES.WALK
  }
}

function getAnimationAnchor (segmentCategory, waypoints) {
  let index
  switch (segmentCategory) {
    case SEGMENT_CATEGORIES.START:
      index = 0
      break
    case SEGMENT_CATEGORIES.WALKING_TO_END:
      index = waypoints.length - 1
      break
    case SEGMENT_CATEGORIES.WALKING_TO_PORTAL:
      index = Math.min(waypoints.length - 1, Math.ceil(waypoints.length / 2))
      break
    default:
      index = waypoints.length - 1
  }
  return waypoints[index].position
}

function getPrimaryText (segments, index, startName, destinationName, floorIdToNameMap, T, securityPois, queueTypes) {
  const segment = segments[index]
  switch (segment.segmentCategory) {
    case SEGMENT_CATEGORIES.START:
      return getPositionName(segment.waypoints[0].position, startName)
    case SEGMENT_CATEGORIES.WALKING_TO_END:
      return getPositionName(segment.waypoints[segment.waypoints.length - 1].position, destinationName)
    case SEGMENT_CATEGORIES.WALKING_TO_SECURITY_CHECKPOINT: {
      const lookAheadWaypoints = segments[index + 1].waypoints
      const laneName = getSecurityLaneName(queueTypes, lookAheadWaypoints)
      const translated = T(`wayfinder:Security Lane`)
      const securityLane = laneName ? `${laneName} ${translated}` : null // add translation

      const poiTitle = getClosestSecurityPoiTitle(lookAheadWaypoints, securityPois)
      const displayName = securityLane || poiTitle // Use laneName if defined; otherwise, poiTitle
      return displayName || T(`wayfinder:${segment.type}`)
    }
    case SEGMENT_CATEGORIES.WALKING_TO_PORTAL:
      return T(`wayfinder:${segments[index + 1].type}`)
    case SEGMENT_CATEGORIES.SECURITY_CHECKPOINT: {
      const laneName = getSecurityLaneName(queueTypes, segment.waypoints)
      const translated = T(`wayfinder:Security Lane`)
      const securityLane = laneName ? `${laneName} ${translated}` : null
      const poiTitle = getClosestSecurityPoiTitle(segment.waypoints, securityPois)
      const displayName = securityLane || poiTitle // Use securityLane if defined; otherwise, poiTitle
      return displayName || getLevelName(segment, floorIdToNameMap)
    }
    case SEGMENT_CATEGORIES.ELEVATOR:
    case SEGMENT_CATEGORIES.ELEVATOR_DOWN:
    case SEGMENT_CATEGORIES.ELEVATOR_UP:
    case SEGMENT_CATEGORIES.ESCALATOR:
    case SEGMENT_CATEGORIES.ESCALATOR_DOWN:
    case SEGMENT_CATEGORIES.ESCALATOR_UP:
    case SEGMENT_CATEGORIES.STAIRS:
    case SEGMENT_CATEGORIES.STAIRS_DOWN:
    case SEGMENT_CATEGORIES.STAIRS_UP:
      return getLevelName(segment, floorIdToNameMap)
    default: return T(`wayfinder:${segment.type}`)
  }
}

function getPositionName (position, poiName) {
  if (position.name) { return position.name }
  return poiName
}

function getLevelName (segment, floorIdToNameMap) {
  return floorIdToNameMap[last(segment.waypoints).position.floorId]
}

function getSecondaryText (segment, minutes, T) {
  const zeroOrOtherKeys = translateZeroOrOther(minutes, T)
  const travelVerb = 'Proceed'
  switch (segment.segmentCategory) {
    case SEGMENT_CATEGORIES.START: return T('wayfinder:Begin route at')
    case SEGMENT_CATEGORIES.ELEVATOR: return T('wayfinder:Take elevator to')
    case SEGMENT_CATEGORIES.ELEVATOR_UP: return T('wayfinder:Take elevator up to')
    case SEGMENT_CATEGORIES.ELEVATOR_DOWN: return T('wayfinder:Take elevator down to')
    case SEGMENT_CATEGORIES.STAIRS: return T('wayfinder:Take stairs to')
    case SEGMENT_CATEGORIES.STAIRS_UP: return T('wayfinder:Take stairs up to')
    case SEGMENT_CATEGORIES.STAIRS_DOWN: return T('wayfinder:Take stairs down to')
    case SEGMENT_CATEGORIES.ESCALATOR: return T('wayfinder:Take escalator to')
    case SEGMENT_CATEGORIES.ESCALATOR_UP: return T('wayfinder:Take escalator up to')
    case SEGMENT_CATEGORIES.ESCALATOR_DOWN: return T('wayfinder:Take escalator down to')
    case SEGMENT_CATEGORIES.WALK:
    case SEGMENT_CATEGORIES.WALKING_TO_SECURITY_CHECKPOINT:
    case SEGMENT_CATEGORIES.WALKING_TO_PORTAL:
    case SEGMENT_CATEGORIES.WALKING_TO_END: return zeroOrOtherKeys(`${travelVerb} <1 minute to`, `${travelVerb} xx minute to`)
    case SEGMENT_CATEGORIES.WALK_DOWN: return zeroOrOtherKeys(`${travelVerb} <1 minute down to`, `${travelVerb} xx minute down to`)
    case SEGMENT_CATEGORIES.WALK_UP: return zeroOrOtherKeys(`${travelVerb} <1 minute up to`, `${travelVerb} xx minute up to`)
    case SEGMENT_CATEGORIES.TRAIN: return zeroOrOtherKeys('Take train <1 minute', 'Take train xx minute')
    case SEGMENT_CATEGORIES.BUS: return zeroOrOtherKeys('Take bus <1 minute', 'Take bus xx minute')
    case SEGMENT_CATEGORIES.SECURITY_CHECKPOINT: {
      return T('wayfinder:Through')
    }
    case SEGMENT_CATEGORIES.RAMP: return T('wayfinder:Take ramp to')
    case SEGMENT_CATEGORIES.RAMP_UP: return T('wayfinder:Take ramp up to')
    case SEGMENT_CATEGORIES.RAMP_DOWN: return T('wayfinder:Take ramp down to')
    default: return ''
  }
}

const translateZeroOrOther = (count, T) => (zeroKey, otherKey) => count === 0
  ? T('wayfinder:' + zeroKey)
  : T('wayfinder:' + otherKey, { count })

const checkIfAccessible = compose(
  not, includes(__, ['escalator', 'stairs']), toLower, prop('type'))

const getSecurityLaneName = (queueTypes, waypoints) => {
  const lane = findPropWaypoints('securityLane')(waypoints)
  if (!lane) return
  const types = prop(lane.type, queueTypes)
  const laneType = find(propEq(lane.id, 'id'), types)
  return prop('displayText', laneType)
}

const findPropWaypoints = propName => compose(
  prop(propName),
  find(prop(propName)),
  drop(1)) // because first waypoint is the end of previous segment and can be security checkpoint

/**
 * Given an array of waypoints (with exactly one having portalType "Security Checkpoint")
 * and an array of securityPois, this function uses the checkpoint's position
 * to determine the closest security POI.
 *
 * @param {Array} waypoints - Array of waypoint objects.
 * @param {Array} securityPois - Array of security POI objects.
 * @returns {string|null} - The name of the closest security POI, or null if none found.
 */
const getClosestSecurityPoiTitle = (waypoints, securityPois) => {
  if (!waypoints || waypoints.length === 0) return null

  const checkpointWaypoint = waypoints[0]
  const { lat, lng, floorId } = checkpointWaypoint.position
  // Use all POIs with a valid position on the same floor as the checkpoint.
  const sameFloorCandidates = securityPois.filter(
    poi => poi.position && poi.position.floorId === floorId
  )
  let closest = null
  let minDistance = Infinity
  sameFloorCandidates.forEach(poi => {
    const d = getGeoDistanceMetres(
      lat,
      lng,
      poi.position.latitude,
      poi.position.longitude
    )
    if (d < minDistance) {
      minDistance = d
      closest = poi
    }
  })
  return closest ? closest.name : null
}

export default getSteps
export const internal = {
  getIcon,
  getClosestSecurityPoiTitle
}
