import { DateTime } from 'luxon'

/**
 * Pass in a luxon DateTime object and receive a simple formatted time string
 * This adjusts for odd handling of Icelandic times by Chromium/Blink browser engines.
 * @param {DateTime|Date} datetime A JS Date or a luxon DateTime holding the time you wish to display
 * @param {string} locale The locale (2 character country code - plus optional variant)
 * @returns {string} A time formatted for display
 */
const formatTime = (datetime, locale = 'en') => {
  if (datetime instanceof Date)
    datetime = DateTime(datetime)

  // The following is to force Icelandic (is) locale to display in 24 hour format (the "ca" locale
  // is an arbitrarily selected locale that uses 24 hour format)
  // This is due to Chrome/Chromium/Blink not supporting Icelandic well. See https://issues.chromium.org/issues/40624456
  if (locale === 'is')
    locale = 'ca'

  return datetime.setLocale(locale).toLocaleString(DateTime.TIME_SIMPLE)
}

// Get array of hours by interval, (8:00, 8:30...)
const getHoursByInterval = (startTimeEarliest, startTimeLatest, interval, usFormat = true) => {
  const hours = []
  const dt = getDateFromTimeString(startTimeEarliest)
  const endHour = startTimeLatest.split(':')[0]
  const endMinute = startTimeLatest.split(':')[1]

  while (dt.getDate() === 1 && (dt.getHours() < endHour || (dt.getHours().toString() === endHour && dt.getMinutes().toString() <= endMinute))) {
    const formattedTime = dt.toLocaleTimeString(usFormat && ['en-US'], { hour: '2-digit', minute: '2-digit' }).toLowerCase()
    hours.push({ time: dt.getTime(), formattedTime })
    dt.setMinutes(dt.getMinutes() + interval)
  }
  return hours
}

// Date in 24h format
const getDateFromTimeString = (time) => {
  const startHour = time.split(':')[0]
  const startMinute = time.split(':')[1]

  return new Date(1970, 0, 1, startHour, startMinute)
}

const getDateTimeSummaryString = (meetingDate, selectedHour, durations, timeZone) => {
  const duration = durations.find(el => el.isDefault) // find currently selected duration

  // Get chosen date in the timezone
  const zoned = DateTime.fromJSDate(meetingDate, { zone: timeZone })
  const shortDate = `${zoned.weekdayShort}, ${zoned.monthShort} ${zoned.day}`

  let endTime = new Date(selectedHour.time + duration.mins * 60000) // add duration time to currently selected hour
  endTime = endTime.toLocaleTimeString(['en-US'], { hour: '2-digit', minute: '2-digit' }).toLowerCase()

  const calculatedOffset = zoned.offset / 60
  let offset = calculatedOffset > 0 ? '+' : '-'
  offset += calculatedOffset > 10 ? calculatedOffset : `0${Math.abs(calculatedOffset)}`
  offset += ':00'

  return `${shortDate}, ${selectedHour.formattedTime} - ${endTime} (UTC ${offset})`
}

const convertMS = (time) => {
  const date = new Date(time)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const miliseconds = date.getMilliseconds()

  return {
    date,
    hours,
    minutes,
    miliseconds
  }
}

const minToMs = mins => mins * 60 * 1000
const hToMs = hours => hours * 60 * 60 * 1000
const msToMin = ms => Math.ceil(ms / 60 / 1000)

function createTodayWithTime (time) {
  return createDateWithTime(new Date(), time)
}

/**
 * @param date: Date
 * @param time: String 'hh:mm'
 * @returns {Date}
 */
function createDateWithTime (date, time) {
  const [hours, minutes] = time.split(':').map(Number)
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, 0, 0)
}

/**
 * Generate array of days objects
 * @param {Number} dateRange - Count of days to generate.
 * @param {Boolean} showSatSun - Should Saturday and Sunday show
 */
const generateDays = (dateRange, showSatSun) => {
  const days = []

  const local = DateTime.local()
  const startTime = new Date(local)

  while (days.length < dateRange) {
    // Check if Saturdays and Sundays are allowed days before adding them
    if (showSatSun || (!showSatSun && startTime.getDay() !== 6 && startTime.getDay() !== 0)) {
      const rawDate = new Date(startTime.getTime()) // copy without reference
      const formattedDate = formatToDateString(rawDate)

      // Set first date as active
      const isActive = days.length === 0

      days.push({ rawDate, formattedDate, isActive })
    }

    startTime.setDate(startTime.getDate() + 1)
  }
  return days
}

// Formats date to  "Mon, Sep 23"
const formatToDateString = (date) => {
  const luxonDate = DateTime.fromJSDate(date)
  return `${luxonDate.weekdayShort}, ${luxonDate.monthShort} ${luxonDate.day}` // Mon, Sep 23
}

/**
   * Check which hours can be booked for certain date - check if timeslot was already booked or timeslot has passed
   * @param {Array} schedule - Scheduled slots for the resource
   * @param {Array} hours - Hours generated in intervals
   * @param {Date} day - Certain date
   * @param {Number} meetingDuration - Chosen meeting duration in minutes
   * @param {String} timeZone - Venue timezone (IANA type)
   */
const calculateDayHoursAvailability = (schedule, hours, day, meetingDuration, timeZone) => {
  return hours.map(hour => {
    const time = convertMS(hour.time)
    day.setHours(time.hours, time.minutes, 0, 0)

    // Cast timeslot from the timezone to the local time so it can be compared to the booked time retrieved from the API
    const startTime = new Date(day.getTime())
    const localStartTime = DateTime.fromObject({ year: startTime.getFullYear(), month: startTime.getMonth() + 1, day: startTime.getDate(), hour: startTime.getHours(), minute: startTime.getMinutes(), zone: timeZone })
    const localStartTimeDate = new Date(localStartTime.ts)

    const endTime = new Date(day.getTime() + meetingDuration * 60000)
    const localEndTime = DateTime.fromObject({ year: endTime.getFullYear(), month: endTime.getMonth() + 1, day: endTime.getDate(), hour: endTime.getHours(), minute: endTime.getMinutes(), zone: timeZone })
    const localEndTimeDate = new Date(localEndTime.ts)

    const isAvailable = compareToLocalTimeZoneTime(localStartTimeDate) && checkIfAvailable(schedule, localStartTimeDate, localEndTimeDate)
    return { ...hour, isAvailable }
  })
}

/**
   * Check if chosen meeting time can be booked.
   * @param {Array} schedule - Scheduled slots for the resource.
   * @param {Date} startTime - Meeting start time.
   * @param {Date} endTime - Meeting end time.
   */
const checkIfAvailable = (schedule, startTime, endTime) => {
  const isOccupied = schedule.some(booking => checkIfDatesOverlap(booking.start, booking.end, startTime, endTime))

  return !isOccupied
}

/**
  * Check if the meeting starts or ends in between already booked time.
  * @param {Date} bookedStart - The booked time start.
  * @param {Date} bookedEnd - The booked time end.
  * @param {Date} start - Meeting start time.
  * @param {Date} end - Meeting end time.
  */
const checkIfDatesOverlap = (bookedStart, bookedEnd, start, end) => {
  const bookedStartTime = new Date(bookedStart).getTime()
  const bookedEndTime = new Date(bookedEnd).getTime()
  const startTime = new Date(start).getTime()
  const endTime = new Date(end).getTime()

  // If start time or end time are in between booked time return true
  if ((startTime >= bookedStartTime && startTime < bookedEndTime) ||
    (endTime > bookedStartTime && endTime <= bookedEndTime)
  ) return true

  return false
}

/**
  * Compare local time to the venue timezone and check if this timeslot has already passed.
  * @param {Date} day - Day in venue the timezone to compare.
  */
const compareToLocalTimeZoneTime = (zonedStartTimeDate) => {
  const now = new Date()

  return zonedStartTimeDate.getTime() >= now.getTime()
}

export {
  formatTime, formatToDateString, getHoursByInterval, getDateTimeSummaryString, convertMS,
  createDateWithTime, createTodayWithTime, minToMs, hToMs, generateDays, calculateDayHoursAvailability,
  msToMin
}
