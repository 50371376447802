import React, { useEffect, useState } from 'react'

import DirectionsResultControlsViewMulti from './DirectionsResultControlsViewMulti.jsx'

const DirectionsResultControls = ({
  onBackFromNavigationClicked,
  onBackButtonClicked,
  onBackToSearchButtonClicked,
  onSwitchDirectionsButtonClicked,
  onSwitchRouteTypeButtonClicked,
  onInputClearButtonClick,
  handleChange,
  widgetState,
  handleInputSelect,
  isBackButtonDisabled,
  isDesktop,
  logoUrl,
  clientName,
  handleSearchInputsChange,
  handleAddStopSelect,
  T
}) => {
  const [state, setWidgetState] = useState(widgetState.getState())
  useEffect(() => widgetState.addCallback(setWidgetState))

  return (
    <DirectionsResultControlsViewMulti {...state}
      isNavigating={true}
      logoUrl={logoUrl}
      clientName={clientName}
      isBackButtonDisabled={isBackButtonDisabled}
      onBackButtonClicked={onBackButtonClicked}
      onBackFromNavigationClicked={onBackFromNavigationClicked}
      onSwitchDirectionsButtonClicked={onSwitchDirectionsButtonClicked}
      onSwitchRouteTypeButtonClicked={onSwitchRouteTypeButtonClicked}
      onInputClearButtonClick={onInputClearButtonClick}
      onInputChange={handleChange}
      onInputSelect={handleInputSelect}
      searchInputs={widgetState.lastState.searchInputs}
      handleSearchInputsChange={handleSearchInputsChange}
      handleAddStopSelect={handleAddStopSelect}
      T={T}
      widgetState={widgetState}
      isMobile={!isDesktop()}
    />
  )
}

export default DirectionsResultControls
