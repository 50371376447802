import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import styled, { withTheme } from 'styled-components'

import { Button } from '../../../src/ui/buttons/Button.jsx'
import { ColoredIcon } from '../../../src/ui/icons/Icon.jsx'
import Submenu from '../../../src/ui/submenu/submenu.jsx'

import MenuSelection from './MenuSelection.jsx'

const Wrapper = styled.div`
  &.-mobile {
    position: absolute;
    z-index: 950;
    top: 74px;
    right: 10px;
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  }
`

const MenuWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 3px;

  &.-mobile {
    top: 15px;
    right: 10px;
    width: 220px;
  }
`

const MenuCopyright = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: 12px;
  line-height: 14px;
  background: #ebebeb;
  width: inherit;
  height: 40px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
`

const TriangleWrapper = styled.div`
  position: absolute;
  right: 16px;
`

const Triangle = styled.div`
  width: 0;
  height: 0;
  filter: drop-shadow(5px 2px 4px rgba(0, 0, 0, 0.2));
  z-index: 1;

  &.-desktop {
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: ${({ themeColor }) => `19px solid ${themeColor}`};;
    bottom: 8px;
    right: -16px;
    position: absolute;
  }

  &.-mobile {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: ${({ themeColor }) => `19px solid ${themeColor}`};
  }
`
const StyledButton = styled(Button)`
&& {
  border: none;
  outline: none;
  margin-left: -10px;
  &:focus-visible ${ColoredIcon}{
    outline: 1px solid orange;
    outline-offset: -1px;
    border-radius: 4px;
  }
}
`

const UserMenu = withTheme(({ theme, widgetState, isDesktop, T }) => {
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const isMobile = !isDesktop
  const ref = useRef(null)
  const { menuOptions } = widgetState.getState()

  const handleClickOutside = (event) => {
    // Handle outside clicks excluding the menu button
    if (ref.current && !ref.current.contains(event.target) && (event.currentTarget.activeElement.id !== 'mobileUserMenuButton')) {
      widgetState.update({ isMobileMenuOpen: false })
    }
  }
  const handleHideMenu = () => {
    setIsMobileMenuOpen(false)
  }

  if (isMobile) return (
    <>
      <StyledButton className="userMenu" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} title="User Menu" data-cy='HeaderUserMenuButton'>
        <ColoredIcon id="submenu" height={40} width={40} fillColor={theme.colors.widgetIconFill} />
      </StyledButton>
      {isMobileMenuOpen && <Wrapper ref={ref} className="-mobile" data-cy='UserMenuMobile' role='dialog' aria-modal={false} aria-label={T('userMenu:User menu')}>
        <TriangleWrapper>
          <Triangle className="-mobile" themeColor={theme.colors.background} />
        </TriangleWrapper>
        <MenuWrapper className="-mobile" themeColor={theme.colors.background}>
          <MenuSelection menuOptions={menuOptions} isMobile={true} hideMenu={handleHideMenu} />
          <MenuCopyright>
          Map Data ©2015-{new Date().getFullYear().toString().substr(-2)} Atrius
          </MenuCopyright>
        </MenuWrapper>
      </Wrapper>}
    </>
  )
  if (isDesktop) return <Submenu menuItems={menuOptions} tooltipContent={T('userMenu:User menu')} data-cy='UserMenuButtonDesktop'></Submenu>

  return null
})

UserMenu.propTypes = {
  bus: PropTypes.shape({
    on: PropTypes.func.isRequired,
    send: PropTypes.func.isRequired
  })
}

export default UserMenu
