export const getDebugBoundsLayers = boundsSourceName => [
  {
    id: 'debug-map-center',
    filter: ['==', ['geometry-type'], 'Point'],
    type: 'circle',
    source: boundsSourceName,
    layout: {
      visibility: 'visible'
    },
    paint: {
      'circle-color': '#ffffff',
      'circle-radius': 5,
      'circle-stroke-color': '#ef476f',
      'circle-stroke-width': 2
    }
  },
  {
    id: 'debug-bounds',
    filter: ['==', ['geometry-type'], 'LineString'],
    type: 'line',
    source: boundsSourceName,
    paint: {
      'line-color': ['case', ['has', 'color'], ['get', 'color'], '#118ab2'],
      'line-width': ['case', ['has', 'width'], ['get', 'width'], 3]
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
      'line-round-limit': 0
    }
  }
]
