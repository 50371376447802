import React from 'react'
import { withTheme } from 'styled-components'

import { ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'
import { RouteOptionsClassification, RouteType } from '../constants.js'

import { RouteTypeInfo, RouteTypeButton, RouteTypeSwitcher } from './styles.js'

const RouteOptions = ({ routeTypes, routeAccessibiltyChoice, onSwitchRouteTypeButtonClicked, theme, T }) => {
  const noAccessibleRouteInfo = <RouteTypeInfo data-cy='NoAccessibleRoute'>
    <ColoredIcon className="icon" width={24} height={24} id="warning" fillColor={theme.colors.widgetIconFill} />
    {T('getDirectionsFromTo:No accessible route found')}
  </RouteTypeInfo>

  const defaultRouteAccessibleInfo = <RouteTypeInfo data-cy='DefaultAccessibleInfo'>
    <ColoredIcon className="icon" width={24} height={24} id="accessible" fillColor={theme.colors.widgetIconFill} />
    {T('getDirectionsFromTo:This route is accessible')}
  </RouteTypeInfo>

  const routeTypeSwitchButtons = (
    <>
      <RouteTypeButton
        active={routeAccessibiltyChoice === RouteType.DIRECT}
        onClick={() => onSwitchRouteTypeButtonClicked('direct')}
        data-cy='RouteTypeDirect'
        aria-label={T('getDirectionsFromTo:Direct')}
        role='radio'
        aria-checked={routeAccessibiltyChoice === RouteType.DIRECT}>
        <ColoredIcon className="icon" width={24} height={24} id="walking" fillColor={theme.colors.widgetIconFill} />
        <div>{T('getDirectionsFromTo:Direct')}</div>
      </RouteTypeButton>

      <RouteTypeButton
        active={routeAccessibiltyChoice === RouteType.ACCESSIBLE}
        onClick={() => onSwitchRouteTypeButtonClicked('accessible')}
        data-cy='RouteTypeAccessible'
        aria-label={T('getDirectionsFromTo:Accessible')}
        role='radio'
        aria-checked={routeAccessibiltyChoice === RouteType.ACCESSIBLE}>
        <ColoredIcon className="icon" width={24} height={24} id="accessible" fillColor={theme.colors.widgetIconFill} />
        <div>{T('getDirectionsFromTo:Accessible')}</div>
      </RouteTypeButton>
    </>
  )

  const getRouteType = () => {
    switch (routeTypes) {
      case RouteOptionsClassification.NO_ACCESSIBLE_ROUTES: return noAccessibleRouteInfo
      case RouteOptionsClassification.DEFAULT_IS_ACCESSIBLE: return defaultRouteAccessibleInfo
      default: return routeTypeSwitchButtons
    }
  }
  return <RouteTypeSwitcher data-cy='RouteTypeInfo' role='radiogroup' aria-label='route type radio group'>{getRouteType()}</RouteTypeSwitcher>
}

export default withTheme(RouteOptions)
