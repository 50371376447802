import * as R from 'ramda'

import {
  animatedNavLineFeaturesAtTime,
  prepareRawAnimatedNavlineSections
} from './animatedSectionBuilder.js'
import { createMapFeature, setSource } from './utils.js'

export default function NavlineController (app, mapInitialized) {
  const NAV_LINE_SOURCE = 'llNavSource'
  const ANIM_NAV_LINE_SOURCE = 'llAnimatedNavSource'
  const ANIMATION_DURATION = 5000

  let state = {
    ordinal: null,
    isAnimating: false,
    navlineFeatures: [],
    rawAnimatedNavLineSections: [],
    featureThemer: app.bus.get('map/getMapFeatureThemer')
  }

  const initMapSources = (sources) => {
    return {
      ...sources,
      [NAV_LINE_SOURCE]: createMapFeature([]),
      [ANIM_NAV_LINE_SOURCE]: createMapFeature([])
    }
  }

  // The category is appended to 'nav.' to determine the color theme to be
  // used. i.e. if catgory = 'primary', the theme color used will be nav.primary
  // Currently themed navline categories include:
  // primary : for the currently "focused" path (when multipoint, this is current leg)
  // alternative : when choosing direct/accessible, this is the non-selected path
  // multipoint : Non-currently active legs of a multipoint route.
  // alternativemultipoint : when choosing direct/accessible, this is the non-selected path of the non-currently active legs of a multipoint route.
  app.bus.on('map/showNavlineFeatures', ({ segments, category = 'primary' }) => {
    mapInitialized().then(async map => {
      const themer = await state.featureThemer

      const navline = R.chain(toGeoJson, segments).map(themer)

      let newNavlineFeatures
      if (category !== 'primary') {
        newNavlineFeatures = navline.map(feature => themer({
          ...feature,
          properties: { ...feature.properties, category: `nav.${category}`, badge: null }
        }))
      } else {
        newNavlineFeatures = navline
      }
      state.navlineFeatures = state.navlineFeatures.concat(newNavlineFeatures)

      filterFeaturesOnOrdinal(map)

      // if its the current primary navline then animate it
      if (category === 'primary') {
        state.rawAnimatedNavLineSections = prepareRawAnimatedNavlineSections([segments])[0]
        startAnimation(map)
      }
    })
  })

  const _toLineStringGeoJson = wayfindingSegment => ({
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: wayfindingSegment.coordinates
    },
    properties: {
      category: wayfindingSegment.segmentType,
      ordinalId: wayfindingSegment.ordinalId
    }
  })

  const _toBadgeGeoJson = wayfindingSegment => badge => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: badge.coordinates
    },
    properties: {
      badge: badge.canonicalName,
      ordinalId: wayfindingSegment.ordinalId
    }
  })

  const toGeoJson = wayfindingSegment => {
    const badges = R.map(_toBadgeGeoJson(wayfindingSegment), wayfindingSegment.badges)
    if (wayfindingSegment.shouldDrawSegment) {
      const line = _toLineStringGeoJson(wayfindingSegment)
      return R.prepend(line, badges)
    } else return badges
  }

  function reset () {
    mapInitialized().then(map => {
      setSource(NAV_LINE_SOURCE, [], map)
      setSource(ANIM_NAV_LINE_SOURCE, [], map)
      state.navlineFeatures = []
      state.rawAnimatedNavLineSections = []
      state.isAnimating = false
    })
  }

  app.bus.on('map/resetNavlineFeatures', reset)

  app.bus.on('map/cleanMap', reset)

  const startAnimation = async (map) => {
    const themer = await state.featureThemer
    state.isAnimating = true
    animate(Date.now(), Date.now(), map, themer)
    setTimeout(() => {
      setSource(ANIM_NAV_LINE_SOURCE, [], map)
      state.isAnimating = false
    }, ANIMATION_DURATION)
  }
  const animate = async (startTime, nowTime, map, themer) => {
    if (!state.isAnimating)
      return

    if (!R.length(state.rawAnimatedNavLineSections)) return

    const timeoffset = nowTime - startTime
    const allAnimatedNavlineFeatures = animatedNavLineFeaturesAtTime(timeoffset, state.rawAnimatedNavLineSections)

    const animatedNavlineFeatures = allAnimatedNavlineFeatures
      .filter(R.pathEq(state.ordinal, ['properties', 'ordinal']))
      .map(themer)

    setSource(ANIM_NAV_LINE_SOURCE, animatedNavlineFeatures, map)
    requestAnimationFrame(() => animate(startTime, Date.now(), map, themer))
  }

  app.bus.on('map/ordinalChanged', ({ ordinal }) => mapInitialized().then(map => {
    state.ordinal = ordinal
    filterFeaturesOnOrdinal(map)
  }))

  const filterFeaturesOnOrdinal = map => {
    const navlineFeaturesOnOrdinal = state.navlineFeatures.filter(R.pathEq(`ordinal: ${state.ordinal}`, ['properties', 'ordinalId']))
    setSource(NAV_LINE_SOURCE, navlineFeaturesOnOrdinal, map)
  }

  const runTest = async (initialState, testRoutine) => {
    state = R.mergeRight(state, initialState)
    await testRoutine()
    return state
  }

  return { initMapSources, runTest }
}
