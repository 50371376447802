/*
  This component renders a list of child components that can be of varying heights. It will render
  the list in a "windowed" fashion, meaning it will only render the visible items regardless of
  the length of the list - then as the user scrolls, it will render the newly visible items in the
  list. This is done for performance, as rendering a 1000 item list in React is too slow.

  This uses the VariableSizeList component from "react-window" to accomplish this. This library
  does not actually support auto-sizing of variable sized list items - hence the need for
  this component to do a bit more work.
*/

import { rgba } from 'polished'
import { curry } from 'ramda'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList as List } from 'react-window'
import styled from 'styled-components'

const StyledList = styled(List)`
  &::-webkit-scrollbar {
    display: none;
  }

  div > .poiListWrapper:hover {
    background-color: ${({ theme }) => rgba(theme.colors.primary, 0.05)};
  }

  &::before {
    content: '';
    position: sticky;
    top: 0;
    height: 10px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
    z-index: 1;
    width: ${({ width }) => `${width}px`};
    transform: translateY(-10px);
  }
`

const Wrapper = styled.div`
  height: 100vh;
  min-height: 10em;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`

const SectionTitle = styled.div`
  background: ${({ theme }) => rgba(theme.colors.primary, 0.05)};
  font-weight: 500;
  ${({ theme }) => theme.fontSize('14px')};
  line-height: 22px;
  color: ${({ theme }) => theme.colors.primaryText};
  text-transform: uppercase;
  padding: 7px 16px;
  box-shadow: 0px 1px 0px #EBEBEB, 0px -1px 0px #EBEBEB;
`

const Row = styled.div`
  border-top: 1px solid #EBEBEB;
  &:first-of-type{
    border-top: none;
  }
`

export const sectionTitleRow = sectionTitle =>
  <SectionTitle>{sectionTitle}</SectionTitle>

export const ResultList = ({ items, className }) => {
  const componentRef = useRef(null)
  const wrapperRef = useRef(null)
  const getHeight = useCallback(index => itemHeights[index] || 50)

  const [itemHeights] = useState([])

  const createRow = useCallback(({ data, index, style }) => {
    const rowRef = useRef(null)
    useEffect(() => {
      if (rowRef.current) {
        const itemHeight = rowRef.current.getBoundingClientRect().height
        if (itemHeight !== itemHeights[index]) {
          itemHeights[index] = itemHeight

          // This causes the VariableSizeList to re-size its children now that we've changed a height
          // (see https://react-window.vercel.app/#/api/VariableSizeList)
          componentRef.current?.resetAfterIndex(index)
        }
      }
    }, [])

    return <Row style={style} key={index}>
      <div ref={rowRef}>
        {data[index]}
      </div>
    </Row>
  })

  return <Wrapper className={className} ref={wrapperRef}>
    <AutoSizer>
      {({ width, height }) =>
        <StyledList
          key={items.length}
          itemCount={items.length}
          itemData={items}
          itemSize={getHeight}
          height={height}
          width={width}
          ref={componentRef}
        >
          {createRow}
        </StyledList>
      }
    </AutoSizer>
  </Wrapper>
}
