import queryString from 'query-string'
import { mergeDeepRight, map } from 'ramda'
import Zousan from 'zousan-plus'

import * as wePkg from '../package.json'

import debugTools from './debugTools.js'
import { buildEnv } from './env.js'
import { create as createBus } from './extModules/bustle.js'
import { initLog } from './extModules/log.js'
import i18nInit from './utils/i18n.js'

const isBrowser = typeof window !== 'undefined'
const TRACE = false

async function setupPlugin (app, id, config) {
  let name = id
  if (name.includes('/')) {
    const split = name.split('/')
    name = split[split.length - 1]
  }

  if (config.active !== undefined) {
    if ((config.active === false) || (config.active === 'notLocalhost' && app.env.isLocalhost())) {
      app.log.info(`Plugin ${id} explicitly deativated`)
      return null
    }
  }

  return import(`../plugins/${id}/src/${name}.js`)
    .then(pluginModule => {
      app.log.info(`Creating plugin ${id}`)
      return pluginModule.create(app, config)
    })
}

// takes the `lang` query parameter and returns the most specific supported language
// Recognizes the following optional configuration parameters:
//   supportedLanguages : array of supported language strings. i.e. [ "en", "en-US", "fr", "ja" ]
//   defaultLanguage : if all fails, use this language. default = "en"
const getLang = config => {
  const supportedLanguages = config.supportedLanguages || ['ar', 'de', 'en', 'es', 'fr', 'hi', 'is', 'it', 'ja', 'ko', 'pl', 'pt', 'zh-Hans', 'zh-Hant']

  if (typeof window !== 'undefined') { // if this is a browser...
    const queryParms = queryString.parse(location.search)
    // eslint-disable-next-line no-constant-condition
    let lang = queryParms.lang || (typeof navigator ? navigator.language : null)
    while (lang)
      if (lang && supportedLanguages.includes(lang))
        return lang
      else
        lang = lang.substring(0, lang.lastIndexOf('-'))
  }

  return config.defaultLanguage || 'en'
}

async function loadConfig (name) {
  return isBrowser
    ? import(`./configs/${name}.json`)
    : import(`./configs/${name}.json.js`) // bit of a hack - but supports all versions of node. See https://gitlab.com/locuslabspublic/node-sdk/-/merge_requests/1
}

async function extendConfig (config, extendsConfigs) {
  let newConfig = {}
  const extConfigFiles = await Promise.all(extendsConfigs.map(loadConfig))
  for (const extendsConfigMod of extConfigFiles) {
    let extendsConfig = extendsConfigMod.default
    extendsConfig = extendsConfig.extends ? await extendConfig(extendsConfig, extendsConfig.extends) : extendsConfig // enable recursive extends
    newConfig = mergeDeepRight(newConfig, extendsConfig) // default is JSON data in ES6 modules import
  }
  newConfig = mergeDeepRight(newConfig, config)
  return newConfig
}

// const isSimpleName = name => /^[-a-zA-Z0-9]+$/.test(name) // composed of only alphanumeric and dash

const createPostProcessor = name =>
  config => import(`./configs/postproc-${name}.js`)
    .then(pp => pp.process(config))

const handleConfigPostProcess = async config =>
  config.configPostProc
    ? Zousan.series(config, ...config.configPostProc.map(createPostProcessor))
    : config

async function create (rawConfig) {
  const appInstance = Object.create(null)

  let config = rawConfig.extends ? await extendConfig(rawConfig, rawConfig.extends) : rawConfig

  // this handles error reporting, so we want it activated ASAP
  if (config.plugins.monitoring)
    import('../plugins/monitoring/src/monitoring.js').then(mon => mon.activate(config.plugins.monitoring))

  config = await handleConfigPostProcess(config)

  const lang = getLang(config)
  const i18n = await i18nInit(lang, { debug: config.debug })
  appInstance.i18n = () => i18n
  appInstance.gt = () => i18n.t.bind(i18n) // get translation function - don't hold this, it is bound to current lang
  appInstance.config = config

  appInstance.plugins = {}
  const appLog = initLog('web-engine', { enabled: !!config.debug, isBrowser, color: 'cyan', logFilter: config.logFilter, truncateObjects: !isBrowser, trace: TRACE })

  appInstance.log = appLog.sublog(config.name)
  appInstance.bus = createBus({ showEvents: true, reportAllErrors: true, log: appLog })

  appInstance.info = { wePkg: wePkg.default } // web-engine package

  if (typeof window !== 'undefined') { // Prepare for non-browser environments
    if (config.debug) {
      appInstance.debug = map(fn => fn.bind(appInstance), debugTools)
      debugTools.dndGo.call(appInstance) // setup DnD by default...
    } else
      appInstance.debug = { } // no tools unless in debug mode.. (good idea?)

    window._app = appInstance
    if (window.document && window.document.title && config.setWindowTitle)
      document.title = config.name
  }

  appInstance.env = buildEnv(appInstance)

  if (config.theme) { // the following is only needed when UI is active - which requires a theme
    await Zousan.evaluate(
      { name: 'ThemeManagerModule', value: import('./themeManager.js') },
      { name: 'HistoryManager', value: import('./historyManager.js') },
      { name: 'LayerManager', value: import('./layerManager.js') }
    ).then(async ({ LayerManager, HistoryManager, ThemeManagerModule }) => {
      const ThemeManager = ThemeManagerModule.initThemeManager(appInstance)
      appInstance.themePack = await ThemeManager.buildTheme(config.theme, config.defaultTheme)

      LayerManager.initLayerManager(appInstance)
      HistoryManager.initHistoryManager(appInstance)

      appInstance.destroy = () => LayerManager.destroy(appInstance)
    })
  } else
    appInstance.destroy = () => { } // noop

  if (config.plugins) {
    for (const id in config.plugins) {
      try {
        const pluginConfig = config.plugins[id]
        if (appInstance.plugins[id]) { throw Error(`Duplicate plugin name "${id}"`) }
        const plugin = await setupPlugin(appInstance, id, pluginConfig)
        if (plugin)
          appInstance.plugins[id] = plugin
      } catch (e) {
        appLog.error('Error instantiating plugin ' + id)
        appLog.error(e)
      }
    }

    for (const id in appInstance.plugins) {
      appInstance.plugins[id].init()
    }
  }

  return appInstance
}

export {
  create
}
