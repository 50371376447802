import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'
import styled, { withTheme } from 'styled-components'

import { Button } from '../../../../src/ui/buttons/Button.jsx'
import { CircleIcon } from '../../../../src/ui/icons/CircleIcon.jsx'
import { ColoredIcon } from '../../../../src/ui/icons/Icon.jsx'
import { Input2 } from '../../../../src/ui/inputs/Input2.jsx'
import ToolTipComponent from '../../../../src/ui/tooltips/ToolTipComponent.jsx'
import { getThemeColor } from '../../../../src/utils/styled-component-tools.js'
import { handleKeyboardSelect } from '../../../utils/keyboardUtils.js'

const NavBar = styled.div`
  width: 100%;
  height: 44px;
  background: ${getThemeColor('widgetText', '#333333', 0.1)};
  border-radius: 8px;
  display: flex;
  padding: 8px;
  align-items: center;

  ${Button} {
    top: 0;
    right: 2px;
    width: 24px;
    height: 24px;
  }

  button {
    width: 32px;
    border: none;
    background: transparent;
    padding: 0;
    position: relative;

    &:hover > .tooltip {
      display: block;
      position: absolute;
      padding: 6px 7px;
    }
  }
`

const NavBarComponent = styled.div`
  flex: 0 1 auto;
`

const VerticalBar = styled(NavBarComponent)`
  background-color: ${getThemeColor('widgetText', '#333333')};
  width: 1px;
  height: 100%;
  margin: 0 6px;
`

const Input2Styled = styled(Input2)`
  flex: 1 1 auto;
  padding: 0;
`

const FunctionalButton = styled.button`
  cursor: pointer;
`

const ReturnHomeFromCustomActionWrapper = styled.button`&&&{
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  height: 24px;
  background-color: transparent;
  border: none;
}
`
const StyledHomeText = styled.u`
  color: ${getThemeColor('widgetText', '#333333')};
  margin-left: 4px;
`
const StyledCustomActionTitle = styled.h1`
  color: ${getThemeColor('widgetText', '#333333')};
  font-size: 20px !important;
  margin-left: 12px !important;
  font-weight: 500 !important;
`

const IconAndTitleWrapper = styled.div`
  display: flex;
  margin-left: -5px;
  align-items: center;
`

const StyledCircleIcon = styled(CircleIcon)`
  cursor: default;
`

const SearchNav = withTheme(
  ({
    searchPlaceholder,
    onOpenDirectionsSearchButtonClick,
    onSearchTermChange,
    onFocus,
    onBackButtonClicked,
    handleKeyPress,
    widgetState,
    theme,
    T
  }) => {
    const [state, setWidgetState] = useState(widgetState.getState())
    const [isSearchActive, setIsSearchActive] = useState(false)
    const inputRef = useRef(null)

    useEffect(() => widgetState.addCallback(setWidgetState))

    useEffect(() => {
      setIsSearchActive(!!state.term.length) // If input was cleared component shouldn't be active anymore, otherwise set to active
    }, [state.term])

    // Set component as focused when input was focused
    const handleFocus = () => {
      onFocus()
      setIsSearchActive(true)
    }

    const handleBackClick = (source) => {
      onBackButtonClicked()
      setIsSearchActive(false)
    }

    const focusInput = () => inputRef.current && inputRef.current.focus()

    return (
      <div data-cy={'Header'}>
        {state.customActionName && state.customActionCategory
          ? (
            <>
              <ReturnHomeFromCustomActionWrapper
                onClick={onBackButtonClicked}
                data-cy='BackButton'
                tabIndex={0}
                role='button'
                aria-label={T('headerOnline:Home')}
                onKeyDown={handleKeyboardSelect(() => onBackButtonClicked())}>
                <ColoredIcon
                  className="icon"
                  width={24}
                  height={24}
                  id="arrow-left"
                  fillColor={theme.colors.widgetIconFill}
                />
                <StyledHomeText>{T('headerOnline:Home')}</StyledHomeText>
              </ReturnHomeFromCustomActionWrapper>
              <IconAndTitleWrapper>
                <StyledCircleIcon data-cy='CustomActionIcon' aria-hidden="true" tabIndex={-1} id={state.customActionSVGId ? state.customActionSVGId : state.customActionCategory} size={36} />
                <StyledCustomActionTitle data-cy="CustomActionText" aria-label={`${state.customActionName}`}>{state.customActionName}</StyledCustomActionTitle>
              </IconAndTitleWrapper>
            </>
          )
          : (
            <NavBar>
              <ToolTipComponent
                tooltipText={isSearchActive ? T('headerOnline:Home') : T('headerOnline:Search')}
                arrowPosition="top"
              >
                {isSearchActive
                  ? (
                    <FunctionalButton
                      data-cy="searchBackButton"
                      aria-label={T('headerOnline:Home')}
                      onClick={handleBackClick}
                      tabIndex={'0'}
                      onKeyDown={handleKeyboardSelect(handleBackClick)}
                    >
                      <ColoredIcon
                        id="arrow-left"
                        width={32}
                        height={32}
                        fillColor={theme.colors.widgetIconFill}
                      />
                    </FunctionalButton>
                  )
                  : (
                    <FunctionalButton
                      onClick={focusInput}
                      aria-label={T('headerOnline:Search')}
                      data-cy="searchIconButton"
                    >
                      <ColoredIcon
                        id="search"
                        width={32}
                        height={32}
                        fillColor={theme.colors.widgetText}
                      />
                    </FunctionalButton>
                  )}
              </ToolTipComponent>
              <Input2Styled
                forwardRef={inputRef}
                color={theme.colors.widgetText}
                placeholder={searchPlaceholder}
                onKeyPress={handleKeyPress}
                value={state.term}
                onChange={onSearchTermChange}
                onFocus={handleFocus}
                title={searchPlaceholder}
                tabIndex={isSearchActive ? '0' : '-1'}
                handleClearButtonKeyDown={handleKeyboardSelect(() => handleBackClick('CLEAR'))}
                T={T}
                data-cy="SearchNavInput"
                aria-autocomplete="list"
                aria-haspopup={false}
                aria-controls="SearchSuggestions"
              />
              <VerticalBar />
              <NavBarComponent>
                <ToolTipComponent
                  tooltipText={T('headerOnline:Get Directions')}
                  arrowPosition="left"
                >
                  <FunctionalButton
                    onClick={onOpenDirectionsSearchButtonClick}
                    data-cy={'OpenNavigationButton'}
                    aria-label={T('headerOnline:Get Directions')}
                  >
                    <ColoredIcon
                      id="directions-filled"
                      height={32}
                      width={32}
                      fillColor={theme.colors.widgetIconFill}
                    />
                  </FunctionalButton>
                </ToolTipComponent>
              </NavBarComponent>
            </NavBar>
          )}
      </div>
    )
  }
)
SearchNav.propTypes = {
  searchPlaceholder: PropTypes.string,
  onOpenDirectionsSearchButtonClick: PropTypes.func.isRequired,
  widgetState: PropTypes.object.isRequired,
  onSearchTermChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired
}

export default SearchNav
