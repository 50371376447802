import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { listElementHoverCss } from '../../../../src/ui/hoverStates.js'
import { CircleIcon } from '../../../../src/ui/icons/CircleIcon.jsx'
import ScrollableDropShadow from '../../../../src/ui/ScrollableDropShadow.jsx'
import { handleKeyboardSelect } from '../../../utils/keyboardUtils.js'

const PoiCategories = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  max-height: 100%;
  width: auto;
  flex-wrap: wrap;

  &::after {
    content: "";
    flex: auto;
  }
`

const PoiCategoriesLi = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: none;
  ${({ theme }) => theme.fontSize('14px')};
  height: 52px;
  position: relative;
  width: 100%;

  &:hover, &:active, .selected {
    ${listElementHoverCss};
  }

  &.mobile {
    height: 72px;
    flex-direction: column;
    position: relative;
    justify-content: center;

    .category {
      display: flex;
      align-items: center;
      width: 100%;

    }
  }

  .category {
    display: flex;
    align-items: center;
    ${props => props.isMobile ? 'margin: 0 24px; padding: 0 0 0 14px; ' : 'margin: 0 16px 0 20px; padding: 0;'}
  }

  .divider {
    width: calc(100% - 32px);
    height: 1px;
    background: #EBEBEB;
    position: absolute;
    bottom: 0;
  }
`

const PoiCategoriesButton = styled.button`
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
`

const PoiCategoryTitle = styled.span`
  display: inline-block;
  user-select: none;
  color: ${({ theme }) => theme.colors.dkGray};
  font-size: 16px;
  line-height: 150%;
  margin-left: 14px;
  text-align: left;
`

export const CategoryListWrapper = styled.div`
  padding: ${props => props.isMobile ? '0' : '8px 0'};
  min-height: 10em;
`

export const SearchResultsWrapper = styled.div`
  padding-bottom: ${props => props.hasPadding ? '40px' : '0'};

  .-mobile & {
    height: auto;
    min-height: 100%;
  }
`

export const CategoryList = ({ isMobile, poiCategories, handlePoiCategoryButtonClick, T }) => {
  return (
    <PoiCategories data-cy='poiCategoriesList' className={isMobile ? 'mobile' : null} aria-label={T('homeView:Navigation Items')}>
      {poiCategories && poiCategories.length && poiCategories.map((poiCategory, index) => (
        <PoiCategoriesLi key={ poiCategory.name }>
          <PoiCategoriesButton
            className={isMobile ? 'mobile' : null}
            isMobile={isMobile}
            onClick={() => handlePoiCategoryButtonClick(poiCategory)}
            data-cy='searchCategory'
            onKeyDown={handleKeyboardSelect(() => handlePoiCategoryButtonClick(poiCategory))}
            tabIndex={0}
          >
            <div className="category">
              <CircleIcon id={poiCategory.svgId || poiCategory.category} backgroundColor={poiCategory.backgroundColor} size={isMobile ? 30 : 40} />
              <PoiCategoryTitle>{poiCategory.name}</PoiCategoryTitle>
            </div>
            <div className={isMobile ? 'divider' : null} />
          </PoiCategoriesButton>
        </PoiCategoriesLi>
      ))}
    </PoiCategories>
  )
}

const CategoryListView = ({ widgetState, isMobile, onPoiCategoryButtonClick, T }) => {
  const [state, setWidgetState] = useState(widgetState.getState())
  useEffect(() => widgetState.addCallback(setWidgetState))

  return (
    <ScrollableDropShadow T={T} isDesktop={!isMobile}>
      <CategoryListWrapper isMobile={isMobile}>
        <CategoryList
          isMobile={isMobile}
          poiCategories={state.poiCategories}
          handlePoiCategoryButtonClick={onPoiCategoryButtonClick}
          T={T}
        />
      </CategoryListWrapper>
    </ScrollableDropShadow>)
}

CategoryListView.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  onPoiCategoryButtonClick: PropTypes.func.isRequired
}

export default CategoryListView
