import { DateTime } from 'luxon'
import { rgba } from 'polished'
import * as R from 'ramda'
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import { Button } from '../../../../../src/ui/buttons/Button.jsx'
import { Icon, ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'
import { SecuritySmallLabel } from '../../../../../src/ui/labels/SecuritySmallLabel.jsx'
import ScrollableDropShadow from '../../../../../src/ui/ScrollableDropShadow.jsx'
import { formatTime } from '../../../../../src/utils/date.js'
import { metersToYards } from '../../../../../src/utils/distance.js'
import { getPoiIconId } from '../../../../../src/utils/poi.js'
import { DISPLAY_UNITS_YARDS } from '../constants.js'

const DirList = styled.div`
  margin: 12px auto 0;
  padding: 0;
  list-style: none;
  transition: 0.2s ease-in-out height;
  margin-block-start: 0;
  margin-block-end: 0;

  &.visible {
    height: auto;
  }

  &.collapsed {
    height: 0;
  }
`
const FlippingButton = styled(Button)`
    display: block;
    background-color: 'transparent';
    color:  ${props => props.theme.colors.mapNavText};
    padding: 0 12px;
    border: none;
    border-radius: unset;
    position: relative;

    transform: ${({ isOpen }) => isOpen ? 'none' : 'rotate(180deg)'};
`

const StyledColoredIcon = styled(ColoredIcon)`
    width:24;
    height:24;
    fill: ${({ theme }) => `${theme.colors.secondary}`};
`

export const ChevronButton = ({ onClick, isOpen, ariaLabel }) => {
  return <FlippingButton data-cy="ChevronButton" onClick={onClick} aria-label={ariaLabel} isOpen={isOpen} role='button' aria-expanded={isOpen}>
    <StyledColoredIcon id="chevron-up" />
  </FlippingButton>
}

const StopIconWrapper = styled.div`
  width: 36px;
  text-align: center;

  ${Icon} {
    vertical-align: middle;
  }
`

const StopAccordian = styled.div`
  padding: 12px;
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: ${({ theme }) => `1px solid ${theme.colors.secondaryButton}`};
`

const StopElem = styled.div`
  display: block;
  padding: 12px;
  position: relative;
  margin: 0;
  flex-direction: row;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.secondaryButton}`};
  &:first-child {
    border-top: ${({ theme }) => `1px solid ${theme.colors.secondaryButton}`};
  }
`

const StepElem = styled.button`
  padding: 12px;
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  border: none;
  align-items: center;
  background-color: ${({ theme, completed }) => completed ? rgba(theme.colors.primary, 0.05) : 'transparent'};
  cursor: ${({ isNavigating }) => isNavigating ? 'pointer' : 'default'};

  &:before {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 3px;
    background-color: ${({ theme, currentStep }) => currentStep ? theme.colors.primary : 'transparent'};
    border-radius: 0 4px 4px 0;
  }

  &:first-child {
    border-top: ${({ theme }) => `1px solid ${theme.colors.secondaryButton}`};
  }

  &:not(:last-child):after {
    content: "";
    position: absolute;
    width: calc(100% - 32px);
    margin: auto;
    bottom: 0;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.secondaryButton};
  }
`
const StepIconWrapper = styled.div`
  width: 36px;
  text-align: center;

  ${Icon} {
    vertical-align: middle;
  }
`
const SecondaryInfo = styled.div`
  display: inline-block;

  color: ${({ theme }) => theme.colors.secondaryText};
  ${({ theme }) => theme.fontSize('14px')};
  line-height: 18px;
`
const PrimaryLabel = styled.span`
  color: ${({ theme }) => theme.colors.primaryText};
  ${({ theme }) => theme.fontSize('20px')};
  font-weight: 500;
  line-height: 150%;
`
const SecondaryLabel = styled.div`
  color: ${({ theme }) => theme.colors.secondaryText};
  ${({ theme }) => theme.fontSize('14px')};
  line-height: 18px;
`

const LabelGroup = styled.div`
  margin-left: 12px;

  .StopName {
    color: ${({ theme }) => theme.colors.primaryText};
    ${({ theme }) => theme.fontSize('16px')};
    font-weight: 500;
    line-height: 150%;
  }
`
const IconGroup = styled.div`
  display: flex;
  padding-top: 10px;
`

const StepInfo = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .info {
    color: ${({ theme }) => theme.colors.primaryText};
    ${({ theme }) => theme.fontSize('16px')};
    font-weight: 500;
    line-height: 150%;
  }
`

const SecuritySmallLabelStyled = styled(SecuritySmallLabel)`
  margin-right: 6px;
`

const StepsList = styled.ul`
  margin: 0px;
  padding: 0px;
  display: ${({ isOpen }) => !isOpen && 'none'};
  list-style: none;
`

const MultiStopStepList = ({ legs, searchInputs, className, locale, ...props }) => <ScrollableDropShadow displayBottomShadow={props.isNavigating && props.isOpen} T={props.T} isDesktop={props.isDesktop}>
  <DirList className={className} data-cy="DirList" >
    {
      legs.map((arrayOfSteps, index) => <Stop key={index} steps={arrayOfSteps} stopIndex={index} legs={legs} locale={locale} searchInput={searchInputs[index + 1]} {...props}></Stop>)
    }
  </DirList>
</ScrollableDropShadow>

export const Stop = ({
  steps,
  legs,
  stopIndex,
  times,
  distances,
  preferredUnits,
  searchInput,
  locale,
  T,
  ...props
}) => {
  const activeStop = stopIndex === props.currentStop
  const [isOpen, setIsOpen] = useState(activeStop)

  const icon = searchInput?.chosenPoi && getPoiIconId(searchInput.chosenPoi)
  const getStepIcon = (step, index) => index === (steps.length - 1) && icon ? icon : step.icon

  // Just show steps if there's only 1 stop
  if (legs.length <= 1) {
    return <StepsList data-cy="Steps" isOpen={isOpen} aria-label={T('getDirectionsFromTo:Directions steps list')}>
      {
        steps.map((values, stepIndex) => <Step
          key={stepIndex}
          step={values}
          stepIndex={stepIndex}
          stopIndex={stopIndex}
          icon={getStepIcon(values, stepIndex)}
          T={T}
          {...props}
        ></Step>
        )
      }
    </StepsList>
  } else {
    useEffect(() => {
      if (activeStop)
        setIsOpen(true)
      else
        setIsOpen(false)
    }, [props.currentStop])

    // use the last step in each leg of the route as the different stops
    const { primaryText } = steps.at(-1)
    const stopEta = formatTime(DateTime.now().plus({ minutes: R.sum(R.take(stopIndex + 1, times)) }), locale)

    // When in mobileview, first just show the stops when we're not navigating
    if (props.mobileView && !props.isNavigating) {
      return <StopElem data-cy={`Stop-${stopIndex}`}>
        <LabelGroup data-cy="PrimaryStopInfo">
          <PrimaryLabel className='info'>{`Stop ${stopIndex + 1}`}</PrimaryLabel>
          <SecondaryLabel data-cy="TimeDistanceInfo">
            {`${T('getDirectionsFromTo:_minutes_ min', { minutes: times[stopIndex] })} • ${preferredUnits === DISPLAY_UNITS_YARDS
              ? T('getDirectionsFromTo:_distance_yards', { distance: metersToYards(distances[stopIndex]) })
              : T('getDirectionsFromTo:_distance_m', { distance: distances[stopIndex] })
              }`}
          </SecondaryLabel>
        </LabelGroup>
        <IconGroup data-cy='StopIconGroup'>
          <StopIconWrapper data-cy='StepIconWrapper'>
            <Icon id={icon} width={36} height={40} />
          </StopIconWrapper>
          <LabelGroup data-cy='StopInfo'>
            <div className="StopName" data-cy='StopName'>{primaryText}</div>
            <SecondaryInfo data-cy="SecondaryInfo">
              {T('getDirectionsFromTo:_time_ eta', { time: stopEta })}
            </SecondaryInfo>
          </LabelGroup>
        </IconGroup>
      </StopElem>
    }
    return <div data-cy={`Stop-${stopIndex}`}>
      <StopAccordian data-cy='StopAccordian' >
        <ChevronButton onClick={() => {
          setIsOpen(!isOpen)
        }} isOpen={isOpen} ariaLabel={T('ui:Toggle content')} />
        <StopIconWrapper data-cy='StepIconWrapper'>
          <Icon id={icon} width={36} height={40} />
        </StopIconWrapper>
        <LabelGroup data-cy='StopInfo'>
          <div className="StopName" data-cy='StopName'>{primaryText}</div>
          <SecondaryInfo data-cy="SecondaryInfo">
            {`${T('getDirectionsFromTo:Stop _stop_', { stop: stopIndex + 1 })} • ${T('getDirectionsFromTo:_time_ eta', { time: stopEta })}`}
          </SecondaryInfo>
        </LabelGroup>
      </StopAccordian>
      <StepsList data-cy="Steps" isOpen={isOpen}>
        {
          steps.map((values, stepIndex) => <Step
            key={stepIndex}
            step={values}
            stepIndex={stepIndex}
            stopIndex={stopIndex}
            icon={getStepIcon(values, stepIndex)}
            T={T}
            {...props}
          ></Step>
          )
        }
      </StepsList>
    </div>
  }
}

export const Step = ({ step, stopIndex, icon, stepIndex, onStepClicked = () => { }, T, currentStop, currentStep, isNavigating }) => {
  const isCurrentStep = isNavigating && (stepIndex === currentStep && stopIndex === currentStop)
  const isCompleted = isNavigating && (currentStop > stopIndex || (currentStep >= stepIndex && currentStop === stopIndex))

  const stepRef = useRef()
  useEffect(() => {
    const stepEl = stepRef.current
    if (stepEl && isCurrentStep) {
      stepEl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })
    }
  }, [isCurrentStep])

  const { primaryText, secondaryText, securityWaitTimes } = step

  const statusText = securityWaitTimes?.isTemporarilyClosed
    ? T('ui:Closed').toUpperCase()
    : T('ui:+xx min', { count: securityWaitTimes?.queueTime })

  return (
    <li data-cy={`Item-${stepIndex}`}>
      <StepElem
        isNavigating={isNavigating}
        data-cy={`Step-${stepIndex}`}
        completed={isCompleted}
        onClick={
          isNavigating ? () => onStepClicked({ stopIndex, stepIndex }) : null
        }
        currentStep={isCurrentStep}
        ref={stepRef}
        id="Stepslist"
        aria-label={`${
          isCurrentStep
            ? T('getDirectionsFromTo:Current step is step')
            : T('getDirectionsFromTo:Step')
        } ${stepIndex + 1}: ${secondaryText} ${primaryText} ${statusText}`}
      >
        <StepIconWrapper data-cy="StepIconWrapper" aria-hidden="true">
          <Icon id={icon} width={36} height={40} aria-hidden="true" />
        </StepIconWrapper>
        <StepInfo data-cy="StepInfo" aria-hidden="true">
          <SecondaryInfo data-cy="RouteInstruction" aria-hidden="true">
            <div data-cy="WaitTime" aria-hidden="true">
              <SecuritySmallLabelStyled
                dynamicData={securityWaitTimes}
                T={T}
                statusText={statusText}
                aria-hidden="true"
              />
            </div>
            {secondaryText}
          </SecondaryInfo>
          <div className="info" data-cy="StepName">
            {primaryText}
          </div>
        </StepInfo>
      </StepElem>
    </li>
  )
}

export default MultiStopStepList
