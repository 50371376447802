import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'

import { Icon } from '../../../../src/ui/icons/Icon.jsx'
import { getBearing } from '../../../../src/utils/geodesy.js'
import { getStructureAndFloorAtPointForCL, isPointWithinBounds } from '../../../../src/utils/geom.js'

const LOST_LOCATION_TIME = 1000 * 60 * 1 // no report in 1 minute = lost it...
const HIDE_MARKER_TIME = 1000 * 60 * 5 // hide marker after 5 minutes no report

const bluedotEnabled = <Icon id="bluedot.enabled" height={30} />
const bluedotDisabled = <Icon id="bluedot.disabled" height={30} />

// This contains the bluedot itself. If we rotate this marker
// we can rotate the bluedot. We have a "guessed" bearing, but
// I am turning this off for now, as the icon isn't really intended
// to indicate direction. We can turn it back on if we wish with:
// transform: rotate(${({ bearing }) => 90 + bearing}deg);
const AccuracyField = styled.div`
  border-radius: 100%;
  background: ${({ theme }) => rgba(theme.colors.primaryButton, 0.15)};
  width: ${({ radius }) => radius * 2}px;
  height: ${({ radius }) => radius * 2}px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transform: rotate(0deg);
`

export default function createMarker (app, log, buildings, venueBounds, widgetState) {
  let lastReading = null
  let lastBearing = null
  widgetState.addCallback(state => app.bus.send('bluedot/locationUpdate', { ...state, ...lastReading }))

  function timeoutCheck () {
    const timeSinceLastReading = lastReading ? Date.now() - lastReading.time : 0
    if (widgetState.getState().hasLocation && timeSinceLastReading > LOST_LOCATION_TIME) {
      const T = app.gt()
      followMeCaption(T('bluedot:My Location Lost'))
      log.warn('bluedot:My Location Lost.  timesincelast: ' + timeSinceLastReading)
      widgetState.update({ isFollowing: false, hasLocation: false })
      refresh()
    }
    if (timeSinceLastReading > HIDE_MARKER_TIME && !widgetState.getState().connectionTimedOut)
      widgetState.update({ connectionTimedOut: true, isMonitoring: false })

    setTimeout(timeoutCheck, 5000)
  }

  setTimeout(timeoutCheck, 5000)

  function refresh () {
    if (lastReading) {
      const { time, latitude, longitude, accuracy, clFloor } = lastReading
      const userPositionSource = widgetState.getState().userPositionSource
      updatePos(time, latitude, longitude, accuracy, clFloor, userPositionSource, true)
    }
  }

  // Displays a caption above the follow-me button, and then auto clears after 10 seconds (or specified ms)
  function followMeCaption (msg, ms = 10000) {
    widgetState.update({ followMeCaption: msg })
    setTimeout(() => widgetState.update({ followMeCaption: '' }), ms)
  }

  const getLastReading = () => lastReading

  /**
   * This is called by the location provider (either the browser WebGeoLocationProvider or the simulator) when an update to the user
   * position is being reported.
   * @param {integer} time timestamp
   * @param {float} latitude
   * @param {float} longitude
   * @param {float} accuracy
   * @param {integer} clFloor Core Location floor - not necessarily same as floor
   * @param {string} userPositionSource
   * @param {boolean} refreshFlag this is set to true when coming from bluedot.refresh() function
   */
  async function updatePos (time, latitude, longitude, accuracy, clFloor, userPositionSource, refreshFlag) {
    log.info(`Got ${refreshFlag ? 'refresh' : 'position'} - ${time}: ${latitude}/${longitude} - floor: ${clFloor} (accuracy: ${accuracy}) - state: `, widgetState.getState())
    const T = app.gt()

    // if (time instanceof window.GeolocationPositionError) // user declined permissions (or some other issue)
    //   return widgetState.update({ isFollowing: false, isMonitoring: false, hasLocation: false })
    if (!latitude) // GeolocationPositionError is not standard - was crashing in iPhone - this should suffice
      return widgetState.update({ isFollowing: false, isMonitoring: false, hasLocation: false })
    else
      widgetState.update({ latitude, longitude, accuracy })

    const inVenueBounds = isPointWithinBounds(latitude, longitude, venueBounds.n, venueBounds.s, venueBounds.e, venueBounds.w)
    let floorId = null
    let ordinal = null
    let structureId = null

    const bearing = lastReading
      ? latitude === lastReading.latitude && longitude === lastReading.longitude
        ? lastBearing // if lat,lng didn't change, use last bearing
        : Math.floor(getBearing(lastReading.latitude, lastReading.longitude, latitude, longitude))
      : 0
    lastBearing = bearing

    if (inVenueBounds) {
      let state = widgetState.getState()

      if (userPositionSource && state.userPositionSource !== userPositionSource)
        widgetState.update({ userPositionSource })

      // if we DID have a location, and now getting no clFloor, clear marker and tell user
      if (clFloor == null && state.hasLocation) {
        state = widgetState.update({ hasLocation: false })
        app.bus.send('map/removeMarker', { id: 'bluedot' })
        followMeCaption(T('bluedot:My Location Lost'))
      } else
        if (!state.hasLocation && !refreshFlag) // if this is first reported location in venue bounds, auto-follow (update: no longer autofollow)
          state = widgetState.update({ isMonitoring: true, hasLocation: true })

      const accuracyRadius = accuracy < 30
        ? 20
        : 50

      if (clFloor) {
        const content = <AccuracyField bearing={bearing} radius={accuracyRadius}>{state.hasLocation ? bluedotEnabled : bluedotDisabled}</AccuracyField>
        const mapviewBBox = await app.bus.get('map/getViewBBox')
        const { structure, floor } = getStructureAndFloorAtPointForCL(buildings, latitude, longitude, clFloor, mapviewBBox, true)
        if (floor) {
          structureId = structure.id
          floorId = floor.id
          ordinal = floor.ordinal
          app.bus.send('map/addMarker', { id: 'bluedot', ordinal, lat: latitude, lng: longitude, markerComponent: content, markerOptions: { anchor: 'center', rotationAlignment: 'map' } })
          app.bus.send('user/physicalLocation', { latitude, longitude, structureId, floorId, ordinal })
          widgetState.update({ floorId, ordinal }) // we have a floor now..
          if (state.isFollowing)
            app.bus.send('map/animateToPoint', { lat: latitude, lng: longitude, zoom: 18, floorId: floor ? floor.id : undefined })
        } else
          ; // hmmm.. we had a clFloor, but when attempting to locate the structure/floor for it, we failed. Yikes!
      }

      if (floorId == null) {
        // we are either outside, or in a structure Apple didn't map, or for some other reason Core Location can't figure out our floor.
        widgetState.update({ floorId: null, ordinal: null, hasLocation: false })

        // Hmm.. I don't think we should show a marker if we have no floor - its confusing!
        // app.bus.send('map/addMarker', { id: 'bluedot', ordinal, lat: latitude, lng: longitude, markerComponent: content, markerOptions: { anchor: 'center', rotationAlignment: 'map' } }) // create on same floor user is on.. set this state so we can turn dot gray...
        // app.bus.send('user/physicalLocation', { latitude, longitude })

        if (!state.isFollowing) {
          if (widgetState.getState().hasLocation) { // if we DID have location, we don't now.. hide marker and notify user
            widgetState.update({ isFollowing: false, hasLocation: false })
            app.bus.send('map/removeMarker', { id: 'bluedot' })
            followMeCaption(T('bluedot:My Location Lost'))
          }
        }
      }
    }

    lastReading = { time, latitude, longitude, accuracy, clFloor, floorId, ordinal, structureId }
  }

  return {
    getLastReading,
    refresh,
    updatePos
  }
}
