import PropTypes from 'prop-types'
import { path } from 'ramda'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import { ColoredIcon } from '../icons/Icon.jsx'

import PillLabel from './PillLabel.jsx'

const StyledPillLabel = styled(PillLabel)`
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    margin-left: 3px;
  }
`

const SecurityLabel = withTheme(({ poi, T, className, theme, statusText }) => {
  const dynamicData = path(['dynamicData', 'security'], poi)

  if (!dynamicData) return null
  const { isTemporarilyClosed } = dynamicData
  const shouldShowIcon = !isTemporarilyClosed

  const backgroundColor = isTemporarilyClosed
    ? theme.colors.statusClosedBackground
    : theme.colors.statusCancelledBackground

  const color = isTemporarilyClosed
    ? theme.colors.statusClosedText
    : theme.colors.statusCancelledText

  return <StyledPillLabel
    backgroundColor={backgroundColor}
    color={color}
    className={className}>
    {shouldShowIcon && <ColoredIcon id="opening-hours" width={20} height={20} fillColor="white" />}
    {statusText}
  </StyledPillLabel>
})

SecurityLabel.propTypes = {
  poi: PropTypes.object,
  T: PropTypes.func.isRequired,
  statusText: PropTypes.string
}

export { SecurityLabel }
