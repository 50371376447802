import turfCircle from '@turf/circle'
import { pipe, assoc, filter, pathEq, compose, mergeRight } from 'ramda'
import React from 'react'

import { Icon } from '../../../src/ui/icons/Icon.jsx'

import { getDebugBoundsLayers } from './debugBoundsLayers.js'
import { getNavGraphFeatureLayers } from './navGraphFeaturesLayers.js'
import { createMapFeature, setSource } from './utils.js'

export default function DebugToolsController (app, mapInitialized) {
  const SOURCE_NAVGRAPH_NODES = 'llDebugGraphNodesSource'
  const SOURCE_NAVGRAPH_EDGES = 'llDebugGraphEdgesSource'
  const SOURCE_DEBUG_BOUNDS = 'llDebugBoundsSource'

  let state = {
    navGraphFeatures: {
      nodes: [],
      edges: []
    },
    ordinal: null
  }

  const featureThemer = app.bus.get('map/getMapFeatureThemer')

  exposeMapState()

  function exposeMapState () {
    mapInitialized().then(map => { window._map = map })
    app.bus.monitor('map/poiClicked', ({ poi }) => { window._poi = poi })
  }

  const initMapSources = pipe(
    assoc(SOURCE_NAVGRAPH_EDGES, createMapFeature([])),
    assoc(SOURCE_NAVGRAPH_NODES, createMapFeature([])),
    assoc(SOURCE_DEBUG_BOUNDS, createMapFeature([])))

  const initMapLayers = layers => layers
    .concat(getNavGraphFeatureLayers(SOURCE_NAVGRAPH_NODES, SOURCE_NAVGRAPH_EDGES))
    .concat(getDebugBoundsLayers(SOURCE_DEBUG_BOUNDS))

  app.bus.on('map/showOrphanedGraphNodes', ({ orphanedNodes }) =>
    orphanedNodes.forEach((n, index) => app.bus.send('map/addMarker', {
      id: `orphan-${index}`,
      lat: n.lat,
      lng: n.lng,
      ordinal: n.ordinal,
      markerComponent: <Icon id='dot.eat' width={12} height={12} />
    })))

  app.bus.on('map/showNavGraphFeatures', ({ navGraph }) => mapInitialized().then(async map => {
    const themer = await featureThemer
    const nodes = navGraph.nodes.map(toNodeGeoJsonFeature)
    const edges = navGraph.edges
      .map(compose(themer, toEdgeGeoJsonFeature))
    state.navGraphFeatures = { nodes, edges }
    filterFeaturesOnOrdinal(map)
    app.bus.send('map/highlightEntities', { ids: ['0'] })
  }))

  app.bus.on('map/showVenueBounds', async ({ venueCenter, venueRadius, bounds }) => {
    const mapCenterFeature = toPointGeoJsonFeature(venueCenter)

    const circle = turfCircle([venueCenter.lng, venueCenter.lat], venueRadius / 1000)
    circle.geometry.coordinates = circle.geometry.coordinates[0]
    circle.geometry.type = 'LineString'
    const boundFeatures = toSquare(bounds)
    mapInitialized().then(map => setSource(SOURCE_DEBUG_BOUNDS, [mapCenterFeature, circle, boundFeatures], map))
  })

  const latLngSwap = point => [point[1], point[0]]
  const vertsLatLngSwap = vertsArray => vertsArray.map(latLngSwap)

  app.bus.on('map/showBuildingBounds', async ({ nameFilter }) => {
    const venueData = await app.bus.get('venueData/getVenueData')

    const boundingBoxFeatures = venueData.structures
      .filter(s => nameFilter ? s.name === nameFilter : true)
      .map(s => toSquare2(s.bounds))

    const polyOutlineFeatures = venueData.structures
      .filter(s => nameFilter ? s.name === nameFilter : true)
      .map(s => toLineStringGeoJson(vertsLatLngSwap(s.boundsPolygon)))

    const buildingFeatures = boundingBoxFeatures.concat(polyOutlineFeatures)

    mapInitialized().then(map => setSource(SOURCE_DEBUG_BOUNDS, buildingFeatures, map))
  })

  const toNodeGeoJsonFeature = node => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [node.lng, node.lat]
    },
    properties: {
      nodeId: node.id,
      ordinal: node.ordinal,
      isOrphaned: node.isOrphaned
    }
  })

  // Convert bounds { ne: {lat,lng}, sw: { lat,lng} } to a feature
  const toSquare = bounds => {
    const boundsRectangle = [
      [bounds.ne.lng, bounds.ne.lat],
      [bounds.sw.lng, bounds.ne.lat],
      [bounds.sw.lng, bounds.sw.lat],
      [bounds.ne.lng, bounds.sw.lat],
      [bounds.ne.lng, bounds.ne.lat]
    ]
    return toLineStringGeoJson(boundsRectangle)
  }

  // Same as above, but treat bounds as { n, s, e, w}
  const toSquare2 = bounds => {
    const boundsRectangle = [
      [bounds.e, bounds.n],
      [bounds.w, bounds.n],
      [bounds.w, bounds.s],
      [bounds.e, bounds.s],
      [bounds.e, bounds.n]
    ]
    return toLineStringGeoJson(boundsRectangle)
  }

  const toEdgeGeoJsonFeature = (edge) => ({
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [
        edge.startCoordinates,
        edge.endCoordinates
      ]
    },
    properties: {
      ordinal: edge.ordinal,
      category: edge.category,
      strokeColor: edge.defaultStrokeColor,
      isDriveway: edge.isDriveway
    }
  })

  const toPointGeoJsonFeature = location => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [location.lng, location.lat]
    }
  })

  const toLineStringGeoJson = coordinates => ({
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates
    }
  })

  const clearNavGraphState = () => mapInitialized().then(map => {
    state.navGraphFeatures = { nodes: [], edges: [] }
    setSource(SOURCE_NAVGRAPH_NODES, [], map)
    setSource(SOURCE_NAVGRAPH_EDGES, [], map)
  })
  app.bus.on('map/resetNavGraphFeatures', clearNavGraphState)

  const clearBoundsSource = () => mapInitialized().then(map =>
    setSource(SOURCE_DEBUG_BOUNDS, [], map))
  app.bus.on('map/resetVenueBounds', clearBoundsSource)

  app.bus.on('map/cleanMap', () => {
    clearNavGraphState()
    clearBoundsSource()
  })

  app.bus.on('map/ordinalChanged', ({ ordinal }) => mapInitialized().then(map => {
    state.ordinal = ordinal
    filterFeaturesOnOrdinal(map)
  }))

  const filterFeaturesOnOrdinal = map => {
    const filterOnOrdinal = filter(pathEq(state.ordinal, ['properties', 'ordinal']))
    const nodeFeatures = filterOnOrdinal(state.navGraphFeatures.nodes)
    const edgeFeatures = filterOnOrdinal(state.navGraphFeatures.edges)
    setSource(SOURCE_NAVGRAPH_NODES, nodeFeatures, map)
    setSource(SOURCE_NAVGRAPH_EDGES, edgeFeatures, map)
  }

  app.bus.on('map/togglePadding', () => {
    mapInitialized().then(map => { map.showPadding = !map.showPadding })
  })

  const runTest = async (initialState, testRoutine) => {
    state = mergeRight(state, initialState)
    await testRoutine()
    return state
  }

  return { initMapLayers, initMapSources, runTest }
}
