import * as R from 'ramda'
import { throttle } from 'throttle-debounce'

import { getFloorAt, getStructureAtPoint } from '../../../src/utils/geom.js'

import { setSource, createMapFeature } from './utils.js'

export default function FloorController (app, mapInitialized) {
  const ORDINAL_SOURCE = 'llOrdinalSource'
  const BACKGROUND_SOURCE = 'llBackgroundSource'

  let state = {
    currentOrdinal: null,
    floors: [],
    structures: [],
    currentFloorId: null,
    currentStructureId: null
  }

  const featureThemer = app.bus.get('map/getMapFeatureThemer')

  const initMapSources = (sources) => {
    return {
      ...sources,
      [BACKGROUND_SOURCE]: createMapFeature([]),
      [ORDINAL_SOURCE]: createMapFeature([])
    }
  }

  mapInitialized().then(map => {
    dispatchChangeFloorIfNeeded(map)

    map.on('move', (e) => {
      // only consider dispatching level change if the event was user interaction, not programmatic
      if (e.originalEvent) {
        dispatchChangeFloorIfNeeded(map, true)
      }
    })

    map.on('moveend', () => dispatchChangeFloorIfNeeded(map))
  })

  const dispatchChangeFloor = throttle(500, false, (floor, structure, userInitiated) => {
    const newStructureId = structure ? structure.id : null
    const newFloorId = floor ? floor.id : null
    if (state.currentStructureId !== newStructureId)
      app.bus.send('map/structureChanged', { structure, floor, userInitiated })
    app.bus.send('map/floorChanged', { structure, floor })
    state = { ...state, currentFloorId: newFloorId, currentStructureId: newStructureId }
  })

  const dispatchChangeFloorIfNeeded = (map, userInitiated = false) => {
    const { structures, currentOrdinal } = state
    if (!map.getCenter)
      throw Error('*** Error in floorController:55 - map.getCenter not a function!')
    const { lat, lng } = map.getCenter()
    const structure = getStructureAtPoint(structures, lat, lng)
    const floor = getFloorAt(structures, lat, lng, currentOrdinal)
    // eslint-disable-next-line eqeqeq
    const structureOrFloorChanged = floor?.id != state.currentFloorId || structure?.id != state.currentStructureId // use non-strict to handle null/undefined as same
    if (structureOrFloorChanged) {
      dispatchChangeFloor(floor, structure, userInitiated)
    }
  }

  const getStructure = (structureId) => R.find(R.propEq(structureId, 'id'))(state.structures)

  const getFloor = (floorId) => R.find(R.propEq(floorId, 'id'))(state.floors)

  const getStructureForFloorId = floorId => state.structures.find(s => Object.values(s.levels).find(l => l.id === floorId))

  // Reposition the map to focus (select) the specified structure. If
  // changeToDefaultFloor is true (default), change ord to this building's default floor.
  const selectStructure = (structureId, changeToDefaultFloor = true) => {
    const structure = getStructure(structureId)
    if (!structure)
      throw Error(`Unknown structure ID ${structureId}`)
    app.bus.send('map/centerInBounds', { bounds: structure.bounds })
    if (changeToDefaultFloor)
      changeFloor(structure.defaultLevelId)
  }

  // Reposition the map to focus (select) the specified floor.
  const selectFloor = id => {
    const structure = getStructureForFloorId(id)
    if (!structure)
      throw Error(`Unknown floor ${id}.`)
    app.bus.send('map/centerInBounds', { bounds: structure.bounds })
    changeFloor(id)
  }

  // switch the ordinal to match that of the selected floor. Does not
  // move or zoom the map
  const changeFloor = (floorId) => {
    const floor = getFloor(floorId)
    if (!floor)
      throw Error(`Unknown floor ${floorId}.`)
    const ordinal = floor.ordinal
    changeOrdinal(ordinal)
  }

  const changeOrdinal = (ordinal) => {
    mapInitialized().then(map => {
      if (ordinal !== state.currentOrdinal) {
        state = { ...state, currentOrdinal: ordinal }
        if (!state.ordinalFeatureSets[ordinal]) {
          console.warn(`Trying to display ordinal '${ordinal}' but no source found for it!`, state.ordinalFeatureSets)
        }
        setSource(ORDINAL_SOURCE, state.ordinalFeatureSets[ordinal], map)
        dispatchChangeFloorIfNeeded(map)
        app.bus.send('map/ordinalChanged', { ordinal })
      }
    })
  }

  app.bus.on('venueData/mapDataLoaded', async (params) => {
    const { structures, mapFeatures, defaultOrdinal, venueId } = params
    const floors = R.chain(R.pipe(R.prop('levels'), R.values), structures)
    const themer = await featureThemer
    const backgroundFeatures = mapFeatures[venueId].map(themer)
    mapInitialized().then(map => {
      setSource(BACKGROUND_SOURCE, backgroundFeatures, map)
      app.bus.send('map/mapReadyToShow', { map })
    })
    const ordinalFeatureSets = {}
    floors.forEach(floor => {
      if (!ordinalFeatureSets[floor.ordinal]) {
        ordinalFeatureSets[floor.ordinal] = []
      }
      const themedFeatures = mapFeatures[floor.id]?.map(themer)
      if (themedFeatures)
        ordinalFeatureSets[floor.ordinal].push(...themedFeatures)
    })
    state = { ...state, floors, structures, ordinalFeatureSets, defaultOrdinal, currentOrdinal: null, currentFloorId: null }
    changeOrdinal(defaultOrdinal)
  })

  // TERMINOLOGY:
  //   select = zoom/position map (Focus)
  //   change = no map repositioning (i.e. changeFloor or changeOrdinal)

  app.bus.on('mapLevelSelector/selectLevel', ({ id }) => changeFloor(id))

  // Reposition / Zoom map to "select" (focus) onto the building specified.
  // This is aimed at servicing the levelSelector (hense name) so it also changes
  // the ordinal to match the default floor of the structure.
  // This should probably be renamed to map/selectBuilding.
  app.bus.on('mapLevelSelector/selectBuilding', ({ id, changeToDefaultFloor = true }) => selectStructure(id, changeToDefaultFloor))

  app.bus.on('map/selectFloor', ({ id }) => selectFloor(id))

  app.bus.on('map/changeFloor', ({ id }) => changeFloor(id))

  app.bus.on('map/changeOrdinal', ({ ordinal }) => changeOrdinal(ordinal))

  app.bus.on('map/getCurrentOrdinal', () => {
    return state.currentOrdinal
  })

  app.bus.on('app/reset', () => {
    changeOrdinal(state.defaultOrdinal)
  })

  app.bus.on('map/getFloorAt', ({ lat, lng, ordinal = null }) => {
    const { currentOrdinal, structures } = state
    const correctOrdinal = ordinal === null ? currentOrdinal : ordinal
    return getFloorAt(structures, lat, lng, correctOrdinal)
  })

  app.bus.on('map/mutateFeature', ({ functor }) => mapInitialized().then(map => {
    state.ordinalFeatureSets = R.map(R.map(functor), state.ordinalFeatureSets)
    if (state.currentOrdinal !== null)
      setSource(ORDINAL_SOURCE, state.ordinalFeatureSets[state.currentOrdinal], map)
  }))

  const runTest = async (initialState, testRoutine) => {
    state = R.mergeRight(state, initialState)
    await testRoutine()
    return state
  }

  return { initMapSources, runTest }
}
