import { rgba } from 'polished'
import styled from 'styled-components'

import { FlatButton } from '../../../../../src/ui/buttons/Button.jsx'
import { ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'
import { ANIMATION_TIME, ANIMATION_DELAY } from '../constants.js'

export const RouteTypeInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  margin: auto;
  ${({ theme }) => theme.fontSize('14px')};
  background-color: ${({ theme }) => theme.colors.widgetIconFill};
  color: ${({ theme }) => theme.colors.widgetBackground};
  ${({ theme }) => theme.borderRadius(8)};

  ${ColoredIcon} {
    fill: ${({ theme }) => theme.colors.widgetBackground};
    margin-right: 2px;
    position: relative;
    right: 6px;
  }
`

export const WarningTheme = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  margin: auto;
  ${({ theme }) => theme.fontSize('14px')};
  background-color: ${({ theme }) => theme.colors.statusWarningBackground};
  color: ${({ theme }) => theme.colors.widgetBackground};
  ${({ theme }) => theme.borderRadius(8)};

  ${ColoredIcon} {
    fill: ${({ theme }) => theme.colors.widgetBackground};
    margin-right: 2px;
    position: relative;
    right: 6px;
  }
`

export const RouteTypeSwitcher = styled.div`
  z-index: 0;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  ${({ theme }) => theme.borderRadius(8)};
  color: ${({ theme }) => theme.colors.widgetIconFill};
  ${({ theme }) => theme.fontSize('14px')};
  background-color: ${({ theme }) => rgba(theme.colors.widgetText, 0.1)};
  font-weight: 500;
  margin-top: 18px;
  user-select: none;

  ${ColoredIcon} {
    margin-right: 2px;
    position: relative;
    right: 6px;
  }
`

export const RouteTypeButton = styled(FlatButton)`
  flex: 1 0 50%;
  padding: 3px 0;
  margin: 0;
  color: ${({ active, theme }) => active ? theme.colors.widgetBackground : theme.colors.widgetIconFill} !important;
  background-color: ${({ active, theme }) => active ? theme.colors.widgetIconFill : 'transparent'} !important;
  ${({ theme }) => theme.borderRadius(8)};
  z-index: ${({ active }) => active ? '1' : '0'};
  position: relative;
  ${({ theme }) => theme.fontSize('14px')};

  ${ColoredIcon} {
    fill: ${({ active, theme }) => active ? theme.colors.widgetBackground : theme.colors.widgetIconFill} !important;
  }

  &:focus, &:active, &:hover {
    background-color: ${({ active, theme }) => active ? theme.colors.widgetIconFill : 'transparent'} !important;
    color: ${({ active, theme }) => active ? theme.colors.widgetBackground : theme.colors.widgetIconFill} !important;
    ${ColoredIcon} {
      fill: ${({ active, theme }) => active ? theme.colors.widgetBackground : theme.colors.widgetIconFill} !important;
    }
  }

  &:hover {
    &::before {
      background-color: ${({ active, theme }) => active ? 'unset' : rgba(theme.colors.widgetText, 0.25)};
    }
  }

  &:first-child::before {
    right: ${({ active }) => active ? '0' : 'auto'};
    left: ${({ active }) => active ? 'auto' : '0'};
  }

  &::before {
    ${({ theme }) => theme.borderRadius(8)};
    position: absolute;
    content: '';
    width: calc(100% + 14px);
    right: 0;
    height: 100%;
    background-color: transparent;
  }
`

export const LevelInfo = styled.div`
  position: absolute;
  transform: translateY(100%);
  bottom: -8px;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  animation: fade-out ${ANIMATION_TIME}s forwards;
  animation-delay:${ANIMATION_DELAY}s;

  @keyframes fade-out{
    from {opacity :1;}
    to {opacity :0;}
  }

  @-webkit-keyframes fade-out{
      from {opacity :1;}
      to {opacity :0;}
  }

  span {
    padding: 7px 12px;
    text-align: center;
    background-color: rgba(32, 37, 48, 0.95);
    width: auto;
    max-width: 60%;
    ${({ theme }) => theme.fontSize('14px')};
    color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    ${({ theme }) => theme.borderRadius('2')};
  }
`

export const TopButtons = styled.div`
  display: flex;
  margin-bottom: 14px;

  div, button {
    text-align: center;
    user-select: none;
    color: ${({ theme }) => theme.colors.widgetIconFill};
    ${({ theme }) => theme.fontSize('12px')};
    cursor: pointer;
    position: relative;

    &:last-child {
      margin-left: auto;
      position: relative;
    }

    &:hover > .tooltip {
      display: block;
      position: absolute;
      color: #fff;
      margin: 0 4px;
    }
  }

  button {
    background-color: transparent;
    border: none;
  }
`
