import * as R from 'ramda'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import { StandardButton } from '../../../../../src/ui/buttons/Button.jsx'
import { Icon, ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'
import { metersToYards } from '../../../../../src/utils/distance.js'
import { handleKeyboardSelect } from '../../../../utils/keyboardUtils.js'
import { DISPLAY_UNITS_METERS } from '../constants.js'

import MultiStopStepList from './multiStopStepList.jsx'
import { RouteInfo, ButtonsContainer, StepIconWrapper } from './styles.js'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`
const MultiStopListStyled = styled(MultiStopStepList)`
  overflow: auto;
  width: 100%;
`

const PrimaryButton = styled(StandardButton)`
  width: 138px;
  height: 28.77px;
`

const StyledButtonsContainer = styled(ButtonsContainer)`
  padding-bottom: 10px;
`

export const DirectionsResultViewImpl = ({ className, endpoints, times, distances, searchInputs, steps, currentStep, currentStop, onPrevStepClick, onNextStepClick, theme, currentRouteType, isNavigating, onStepClicked, preferredUnits, T, isDesktop, locale }) => {
  const distanceDisplay = preferredUnits === DISPLAY_UNITS_METERS
    ? T('getDirectionsFromTo:_distance_m', { distance: R.sum(distances) })
    : T('getDirectionsFromTo:_distance_yards', { distance: metersToYards(R.sum(distances)) })
  const minutesText = R.sum(times)
    ? T('getDirectionsFromTo:_minutes_ min total', { minutes: R.sum(times) })
    : T('getDirectionsFromTo:<1 min')

  return (
    <Wrapper className={className} data-cy='DirectionsResultView'>
      {isNavigating && <RouteInfo data-cy='RouteInfo'>
        <StepIconWrapper>
          <Icon id={'wayfinding.end'} width={32} height={45} />
        </StepIconWrapper>
        <div>
          <span className="info" data-cy='InfoPrimary'>{minutesText}{' • '}{distanceDisplay}</span>
          <span className="label" data-cy='InfoSecondary'>{`${T(`getDirectionsFromTo:To`)} ${endpoints[endpoints.length - 1].title}`}</span>
        </div>
      </RouteInfo>
      }

      <StyledButtonsContainer>
        <PrimaryButton
          onClick={onPrevStepClick}
          disabled={currentStop === 0 && currentStep === 0}
          data-cy='PrevStepButton'
          aria-label={T('getDirectionsFromTo:Previous step')}
          aria-controls='Stepslist'
          onKeyDown={handleKeyboardSelect(() => onPrevStepClick())}>
          <ColoredIcon className="icon" width={24} height={24} id={'chevron-left'} fillColor={theme.colors.primary} />
          {T('getDirectionsFromTo:Prev')}
        </PrimaryButton>
        <PrimaryButton
          onClick={onNextStepClick}
          disabled={currentStop === steps.length - 1 && currentStep === steps.at(-1).length - 1}
          data-cy='NextStepButton'
          aria-label={T('getDirectionsFromTo:Next Step')}
          aria-controls='Stepslist'
          onKeyDown={handleKeyboardSelect(() => onNextStepClick())}>
          {T('getDirectionsFromTo:Next')}
          <ColoredIcon className="icon" width={24} height={24} id={'chevron-right'} fillColor={theme.colors.primary} />
        </PrimaryButton>
      </StyledButtonsContainer>
      <MultiStopListStyled
        legs={steps}
        times={times}
        searchInputs={searchInputs}
        onStepClicked={onStepClicked}
        T={T}
        isNavigating={isNavigating}
        currentStop={currentStop}
        currentStep={currentStep}
        isDesktop={isDesktop}
        locale={locale}
      />

    </Wrapper>
  )
}

export default withTheme(DirectionsResultViewImpl)
