import { rgba } from 'polished'
import PropTypes from 'prop-types'
import React, { createRef } from 'react'
import styled, { withTheme } from 'styled-components'

import { IconButton } from './buttons/IconButton.jsx'
import { ColoredIcon } from './icons/Icon.jsx'

// z-index is 152 so it sits above the map and map overlay
const Wrapper = styled.div`
  position: relative;
  z-index: 152;
  overflow-y: ${props => props.isOpen && !props.isDragging && !props.hasInnerScrollingElement ? 'auto' : 'hidden'};
  max-height: ${props => props.maxHeight};
  height: ${props => props.isOpen && !props.isDragging ? props.maxHeight : props.componentHeight};
  box-shadow: 0px -4px 20px ${({ theme }) => rgba('#000', 0.1)};
  background-color: #fff;

  &.static {
    transition: 0.3s ease-in-out height;
  }
`

export const ContentWrapper = styled.div`
  ${props => !props.hasAccordion ? 'padding-top: 19px;' : null};
  padding-bottom: 1px;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  top: 0;
  max-height: ${props => props.hasInnerScrollingElement && props.isOpen ? props.maxHeight : 'none'};
`

const Toggler = styled.div`
  width: 100%;
  height: 19px;
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  padding-top: 10px;

  ${ColoredIcon} {
    margin-top: -5px;
  }
`

class ExpandableContent extends React.PureComponent {
  state = {
    isOpen: false,
    isDraggable: true,
    isClicked: false,
    isDragging: true,
    cursorPositionY: 0,
    minDistance: 20,
    componentHeight: 0,
    minComponentHeight: 0,
    maxComponentHeight: 0,
    innerComponentHeight: 0,
    innerComponentUpdated: false
  }

  myNode = createRef(null) // to contain outer-most node

  componentDidMount = () => {
    this.setState({
      innerComponentHeight: this.elementRef.offsetHeight,
      isDraggable: this.checkIfIsDraggable(),
      componentHeight: this.calculateComponentHeight(this.props.initialHeight),
      minComponentHeight: this.calculateComponentHeight(this.props.initialHeight),
      maxComponentHeight: this.calculateComponentHeight(this.props.maxHeight)

    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { initialHeight, maxHeight, isMapDisabled, bus } = this.props
    const { innerComponentHeight, innerComponentUpdated, isOpen } = this.state

    if (innerComponentHeight >= window.innerHeight && prevState.isOpen !== isOpen && isMapDisabled) bus.send('map/disableMap', { disable: isOpen })

    if (prevState.innerComponentHeight !== innerComponentHeight) {
      const calculatedMaxHeight = this.calculateComponentHeight(maxHeight)
      const initialHeightCalc = this.calculateComponentHeight(initialHeight)
      const newMinComponentHeight = innerComponentHeight < initialHeightCalc ? innerComponentHeight : initialHeightCalc
      const newMaxComponentHeight = innerComponentHeight > calculatedMaxHeight ? calculatedMaxHeight : innerComponentHeight

      // Report POI view full height
      this.props.bus.send('expandableContent/reportHeight', { height: innerComponentHeight })

      this.setState({
        isDraggable: this.checkIfIsDraggable(),
        maxComponentHeight: newMaxComponentHeight,
        componentHeight: initialHeightCalc,
        minComponentHeight: newMinComponentHeight
      })
    }

    if (innerComponentUpdated) {
      // wait unitl DOM is rendered to get element size
      window.requestAnimationFrame(() => {
        // const node = ReactDOM.findDOMNode(this)
        const node = this.myNode.current
        if (node !== undefined) {
          this.setState({ innerComponentHeight: this.elementRef.offsetHeight })
        }
      })
      this.setState({ innerComponentUpdated: false })
    }
  }

  componentWillUnmount = () => {
    const bus = this.props.bus
    bus.send('map/disableMap', { disable: false })
    bus.send('map/resize') // Ensure map renders with 100% container size
  }

  calculateComponentHeight = (height) => {
    let componentHeight = height.toString().match(/\d+/g)[0] // get numbers from height passed in px or vh
    if (!componentHeight) return height

    if (/vh/.test(height)) { // if the unit of height was vh (rather than px)
      componentHeight = window.innerHeight * (componentHeight / 100) // calculate height in px from vh
    }

    return Math.floor(componentHeight)
  }

  setIsOpen = (isOpen) => {
    this.setState({ isOpen })
  }

  checkIfIsDraggable = () => {
    const { initialHeight, isDraggable } = this.props
    const { innerComponentHeight } = this.state

    if (isDraggable) return true // if the component is draggable by default

    if (!innerComponentHeight) return false

    let componentHeight = initialHeight.match(/\d+/g)[0] // get numbers from height passed in px or vh
    if (!componentHeight) return false

    if (/vh/.test(initialHeight)) { // if the unit of initialHeight was vh (rather than px)
      componentHeight = window.innerHeight * (componentHeight / 100) // calculate height in px from vh
    }
    return innerComponentHeight > componentHeight // if inner component is bigger than wrapper height, then make this component draggable
  }

  // Check content height
  refCallback = element => {
    if (element) {
      this.elementRef = element
      this.setState({ innerComponentHeight: element.offsetHeight })
    }
  }

  // calculate relative position to the mouse and set dragging=true
  onMouseDown = (e) => {
    e.stopPropagation()

    // only left mouse button
    if (e.button !== 0) return

    this.setState({ isClicked: true, cursorPositionY: e.pageY })
  }

  onTouchStart = (e) => {
    e.stopPropagation()

    this.setState({ isClicked: true, cursorPositionY: e.touches[0].clientY })
  }

  onMouseUp = (e) => {
    e.stopPropagation()

    const newCursorPositionY = e.pageY
    this.onTouchFinish(newCursorPositionY)
  }

  onTouchEnd = (e) => {
    e.stopPropagation()

    const newCursorPositionY = e.changedTouches[0].clientY
    this.onTouchFinish(newCursorPositionY)
  }

  onTouchFinish = (newCursorPositionY) => {
    const { cursorPositionY, isOpen, isDragging, minDistance, maxComponentHeight, minComponentHeight } = this.state

    const absY = Math.abs(newCursorPositionY - cursorPositionY)
    if (isDragging) {
      if (absY > minDistance) {
        if (newCursorPositionY < cursorPositionY) this.setState({ isOpen: true })
        if (newCursorPositionY > cursorPositionY) this.setState({ isOpen: false })
      } else {
        isOpen ? this.setState({ componentHeight: maxComponentHeight }) : this.setState({ componentHeight: minComponentHeight }) // if the drag wasn't big enough, go back to previous state
      }
    }

    this.setState({ isClicked: false, isDragging: false })
  }

  onMouseMove = (e) => {
    const { isClicked, cursorPositionY, isDragging, isDraggable } = this.state

    if (!isClicked || !isDraggable) return
    if (!cursorPositionY || !isDragging) this.setState({ isDragging: true })

    const newCursorPositionY = e.pageY

    this.onMove(newCursorPositionY)
  }

  onTouchMove = (e) => {
    const { isClicked, isDragging, isDraggable } = this.state

    if (!isClicked || !isDraggable) return
    if (!isDragging) this.setState({ isDragging: true })

    const newCursorPositionY = e.touches[0].clientY

    this.onMove(newCursorPositionY)
  }

  onMove = (newCursorPositionY) => {
    const { isOpen, cursorPositionY, maxComponentHeight, minComponentHeight } = this.state

    const howManyPxDragged = (newCursorPositionY - cursorPositionY) * -1

    const initialComponentHeight = isOpen ? maxComponentHeight : minComponentHeight

    let newHeight = 0
    if (initialComponentHeight + howManyPxDragged > maxComponentHeight) {
      newHeight = maxComponentHeight
    } else {
      newHeight = initialComponentHeight + howManyPxDragged
    }

    this.setState({ componentHeight: Math.floor(newHeight) })
  }

  notifyAboutChange = () => {
    this.setState({ innerComponentUpdated: true })
  }

  currentComponentHeight = () => {
    const { maxHeight, initialHeight } = this.props
    const { isOpen, componentHeight, isDragging } = this.state

    if (isOpen && !isDragging) {
      return this.calculateComponentHeight(maxHeight)
    } else if (!isOpen && !isDragging) {
      return this.calculateComponentHeight(initialHeight)
    } else {
      return componentHeight
    }
  }

  render () {
    const { id, children, theme, className, hasInnerScrollingElement, maxHeight, isMapDisabled, T, poi } = this.props
    const { isOpen, isDraggable, isDragging, maxComponentHeight } = this.state
    const canScroll = isOpen && !isDragging

    return <Wrapper id={id} className={`${className} ${!isDragging && 'static'}`} isOpen={isOpen} isDragging={isDragging} componentHeight={`${this.currentComponentHeight()}px`} maxHeight={(!isMapDisabled && isOpen) ? '100vh' : `${maxComponentHeight}px`} hasInnerScrollingElement={hasInnerScrollingElement}
      onMouseDown={(e) => this.onMouseDown(e)}
      onTouchStart={(e) => this.onTouchStart(e)}
      onMouseUp={(e) => this.onMouseUp(e)}
      onTouchEnd={(e) => this.onTouchEnd(e)}
      onMouseMove={(e) => this.onMouseMove(e)}
      onTouchMove={(e) => this.onTouchMove(e)}
      ref={this.myNode}
      role='dialog'
      aria-modal="true"
      aria-label={poi?.name}
    >
      <ContentWrapper hasAccordion={isDraggable} ref={this.refCallback} isOpen={isOpen} hasInnerScrollingElement={hasInnerScrollingElement} maxHeight={`${this.calculateComponentHeight(maxHeight)}px`}>
        {isDraggable && <Toggler>
          <IconButton data-cy='ChevronToggle' aria-label={T('ui:Toggle content')} onClick={(e) => {
            e.stopPropagation()
            this.setState({ isOpen: !isOpen, isDragging: false })
          }}
          >
            <ColoredIcon className="icon nopointer" width={32} height={32} id={isOpen ? 'chevron-down' : 'chevron-up'} fillColor={theme.colors.secondary} />
          </IconButton>
        </Toggler>
        }
        {React.cloneElement(children, { isOpen: isOpen, setIsOpen: this.setIsOpen, notifyAboutChange: this.notifyAboutChange, canScroll: canScroll })}
      </ContentWrapper>
    </Wrapper>
  }
}

export default withTheme(ExpandableContent)

ExpandableContent.propTypes = {
  isMapDisabled: PropTypes.bool,
  bus: PropTypes.object,
  maxHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  initialHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  isDraggable: PropTypes.bool,
  hasInnerScrollingElement: PropTypes.bool
}

ExpandableContent.defaultProps = {
  isMapDisabled: true,
  maxHeight: '100vh',
  initialHeight: '50vh',
  isDraggable: false,
  hasInnerScrollingElement: false
}
