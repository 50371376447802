import PropTypes from 'prop-types'
import styled from 'styled-components'

const PillLabel = styled.span`
  width: auto;
  white-space: nowrap;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  width: fit-content;

  margin: 0;
  padding: 3px 8px;

  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  ${({ theme }) => theme.borderRadius(theme.globalRadius)};

  line-height: 18px;
  color: ${({ color }) => color};
  ${({ theme }) => theme.fontSize('12px')};
  font-weight: 500;
  text-transform: uppercase;
`

PillLabel.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
}

export default PillLabel
