import * as R from 'ramda'
import * as React from 'react'

import { getLocalized } from '../../../../src/utils/configUtils.js'
import { getLocation } from '../../../../src/utils/location.js'
import { sortPois, adjustBoundsToPois } from '../../../../src/utils/poi.js'
import { StateObjMultiple } from '../../../../src/utils/stateObj.js'
import { widgetEnum } from '../../../../utils/constants.js'
import { SEARCH_VIEW_BODY_ID, SEARCH_VIEW_CATEGORY_LIST_ID, SEARCH_VIEW_TOGGLER, MAX_UI_RESULTS_LENGTH, ToggleViewState } from '../constants.js'

import CategoryListView from './CategoryListView.js'
import SearchResultView from './SearchResultView.js'
import SearchViewToggler from './SearchViewToggler.jsx'

function sendHandoffEvent (app) {
  app.bus.send('session/submitEvent', {
    type: 'userAction',
    action: 'qrCodeHandoff',
    query: 'screen=home',
    position: { }
  })
}

function create (app, config) {
  const hiddenCategories = new Set() // if you wish to hide a category, add it to this list
  let lastHasLocation = false

  const isLayerShowing = async id => app.bus.send('layers/isShowing', { id }).then(res => res.length > 0 && res[0]) // layerManager is not loaded for SDK

  function setPOICategoryState () {
    // First get the list of configuration-defined locale-sensitive POI Categories
    let poiCategories = getLocalized(config, 'poiCategories', app.i18n().language)

    // Next, remove any categories that have been requested to be hidden
    poiCategories = poiCategories.filter(({ category }) => !hiddenCategories.has(category))

    // Next, if we have the user's location and they are on a mobile device, add "nearby" catogory
    if (lastHasLocation) {
      const nearbyCategory = {
        name: app.gt()('homeView:Nearby'),
        searchTerm: app.gt()('homeView:Nearby'),
        category: 'nearby'
      }
      poiCategories = [
        nearbyCategory,
        ...poiCategories
      ]
    }

    searchResultsWidgetState.update({ poiCategories })
  }

  const init = () => {
    app.bus.send('search/registerSpecialQuery', { term: app.gt()('homeView:Nearby'), event: 'search/queryNearby', params: {} })

    setPOICategoryState()
    app.bus.on('i18n/langChanged', setPOICategoryState) // refresh categories when language changes

    const dlp = config.deepLinkProps
    if (dlp && dlp.home !== undefined) {
      sendHandoffEvent(app)
    }

    // This is bad - we shouldn't depend or know about poiView. But we already commit this crime.
    // A refactor which is more declaritive would be an improvement and could eliminate the need
    // to be aware of the poiView.
    if (app.config?.plugins['online/poiView']?.deepLinkProps?.poiId)
      searchResultsWidgetState.update({ poiView: true })
  }
  const searchResultsDefaultWidgetState = {
    keywords: [],
    pois: [],
    nearby: [],
    locations: [],
    isSearchConfirmed: false,
    toggleState: ToggleViewState.SWITCH_TO_MAP
  }
  const searchResultsWidgetState = new StateObjMultiple({ ...searchResultsDefaultWidgetState })
  searchResultsWidgetState.addCallback(state => app.bus.send('headerOnline/updateSearchConfirmed', { isSearchConfirmed: state.isSearchConfirmed }))

  async function performConfirmedQuery (term, searchMethod, referrer) {
    searchResultsWidgetState.update({ pois: [], locations: [], nearby: [], isSearchConfirmed: true })

    if (term) { // exclude empty term
      if (term.toLowerCase() === app.gt()('homeView:Nearby').toLowerCase()) {
        const pois = await app.bus.get('search/queryNearby')
        app.bus.send('event/search', { referrer, searchMethod, query: term, entities: pois.map(poi => poi.poiId) })
        return pois
      } else {
        const result = await app.bus.send('search/queryWithSpecial', { term })
        if (result.length === 1) {
          const pois = result[0] // no gaurentee this is pois since special events can be anything.. but often is..
          // if its an array, assume an empty array is an empty list of POIs.. else ensure the items are POIs
          if (Array.isArray(pois) && (pois.length === 0 || (pois[0] && pois[0].poiId)))
            app.bus.send('event/search', { referrer, searchMethod, query: term, entities: pois.map(poi => poi.poiId) })
        }
      }
    }
  }

  app.bus.on('search/showSearchResults', ({ results, term }) => showSearchResults(results, term))
  app.bus.on('search/showNearby', ({ pois, term }) => showSearchResults(pois, app.gt()('homeView:Nearby'), false))

  function showSearchResults (pois, term, shouldOrder = true) {
    searchResultsWidgetState.update({ pois, nearby: [], locations: pois, keywords: [], isSearchConfirmed: true, toggleState: ToggleViewState.SWITCH_TO_MAP, isFilterChecked: false })

    if (shouldOrder) orderPoisIfDisplaying()

    if (!searchResultsWidgetState.getState().poiView) {
      showResultsWidget()
      adjustBoundsToPois(pois, app)
    } else {
      searchResultsWidgetState.update({ poiView: false })
    }

    app.bus.send('searchResults/showPOIs', { pois })
    app.bus.send('map/selectEntities', { ids: Object.values(pois).map(poi => poi.poiId) })
    app.bus.send('headerOnline/updateTerm', { term, isSearchConfirmed: true })
  }

  /* app.bus.on('map/structureChanged', async ({ structure, userInitiated }) => {
    if (userInitiated && structure) {
      // const isCategoryListVisible = await isLayerShowing(SEARCH_VIEW_CATEGORY_LIST_ID)
      // if (isCategoryListVisible)
      app.bus.send('map/changeFloor', { id: structure.defaultLevelId })
    }
  }) */

  const orderPoisIfDisplaying = async () => {
    if (await areSearchResultsVisible())
      orderPois(searchResultsWidgetState.getState().pois)
  }

  app.bus.on('map/moveEnd', orderPoisIfDisplaying)

  async function areSearchResultsVisible () {
    const isSearchBodyVisible = await isLayerShowing(SEARCH_VIEW_BODY_ID)
    const isSearchTogglerVisible = await isLayerShowing(SEARCH_VIEW_TOGGLER)
    return isSearchBodyVisible || isSearchTogglerVisible
  }

  async function orderPois (pois) {
    if (!pois.length) {
      searchResultsWidgetState.update({ nearby: [], locations: [] })
      return
    }

    const location = await getLocation(app.bus)
    const { structureId } = location
    const { nearby, locations } = await splitNearbyAndLocations(pois, structureId)

    const sortedNearby = (await sortPois(app.bus, nearby, app)).slice(0, MAX_UI_RESULTS_LENGTH)
    const sortedLocations = (await sortPois(app.bus, locations, app)).slice(0, MAX_UI_RESULTS_LENGTH)
    searchResultsWidgetState.update({ nearby: sortedNearby, locations: sortedLocations })
  }

  async function splitNearbyAndLocations (pois, structureId) {
    const partitionByCurrentBuilding = R.partition(R.pathEq(structureId, ['position', 'buildingId']))
    const [nearby, locations] = partitionByCurrentBuilding(pois)
    return { nearby, locations }
  }
  const onEscapeKey = () => {
    app.bus.send('layers/show', { id: SEARCH_VIEW_CATEGORY_LIST_ID })
  }
  app.bus.send('layers/register', {
    id: SEARCH_VIEW_BODY_ID,
    widget: () => (
      <SearchResultView
        isDesktopFn={app.env.isDesktop}
        widgetState={searchResultsWidgetState}
        handleSuggestionClicked={term => performConfirmedQuery(term, 'keyword', 'searchInput')}
        handlePoiClicked={handlePoiClicked}
        handleEscapeKey={onEscapeKey}
        T={app.gt()}
      />
    ),
    layoutId: 'content'
  })

  function handlePoiClicked (poi) {
    app.bus.send('poiDetails/showPoi', { poi, info: { referrer: 'searchResultsList' } })
  }

  app.bus.send('layers/register', {
    id: SEARCH_VIEW_CATEGORY_LIST_ID,
    widget: () =>
      <CategoryListView
        data-cy="CategoryListView"
        widgetState={searchResultsWidgetState}
        isMobile={app.env.isMobile()}
        onPoiCategoryButtonClick={onPoiCategoryButtonClick}
        T={app.gt()}
      />,
    layoutId: 'content'
  })

  const onPoiCategoryButtonClick = poiObject => {
    if (poiObject.category === 'nearby')
      performConfirmedQuery(app.gt()('homeView:Nearby'), 'customAction', 'customAction')
    else
      app.bus.send('search/customAction', poiObject)
    performConfirmedQuery(poiObject.searchTerm || poiObject.category, 'keyword', 'searchInput')
  }

  // todo refactor displaying, hard to track if it's shown
  app.bus.send('layers/register', {
    id: SEARCH_VIEW_TOGGLER,
    widget: () => <SearchViewToggler
      onClickHandler={toggleViewHandler}
      widgetState={searchResultsWidgetState}
      isTogglerVisible={() => {
        const { isSearchConfirmed, pois } = searchResultsWidgetState.getState()
        return app.env.isMobile() && isSearchConfirmed && pois.length !== 0
      }}
    />,
    layoutId: 'bottomBar',
    layoutName: 'fullscreen',
    show: true,
    widgetType: widgetEnum.Mobile
  })

  const toggleViewHandler = () => {
    const { toggleState } = searchResultsWidgetState.getState()
    if (toggleState === ToggleViewState.SWITCH_TO_MAP) {
      app.bus.send('layers/hide', { id: SEARCH_VIEW_BODY_ID })
      app.bus.send('layers/setPreviousLayer', SEARCH_VIEW_BODY_ID)
      searchResultsWidgetState.update({ toggleState: ToggleViewState.SWITCH_TO_LIST })
    } else {
      app.bus.send('layers/show', { id: SEARCH_VIEW_BODY_ID })
      app.bus.send('layers/setPreviousLayer', '')
      searchResultsWidgetState.update({ toggleState: ToggleViewState.SWITCH_TO_MAP })
    }
  }

  app.bus.on('homeview/performSearch', async ({ term, limit, isSearchConfirmed = false, searchMethod, referrer }) => {
    await app.bus.send('system/readywhenyouare')
    performSearchTypeahead(term, limit, isSearchConfirmed, searchMethod, referrer)
  })

  async function performSearchTypeahead (term, limit, isSearchConfirmed, searchMethod, referrer) {
    if (isSearchConfirmed)
      return performConfirmedQuery(term, 'keyword', 'searchInput')

    const { keywords, pois } = await app.bus.get('search/typeahead', { term, limit })
    // locations may not be needed
    searchResultsWidgetState.update({ keywords, pois, locations: pois.slice(0, MAX_UI_RESULTS_LENGTH), isSearchConfirmed, toggleViewState: ToggleViewState.SWITCH_TO_MAP, searchMethod, referrer })

    orderPoisIfDisplaying()

    if (term) showResultsWidget()
    else showCategoryList()

    app.bus.send('searchResults/showPOIs', { pois })
    app.bus.send('map/selectEntities', { ids: Object.values(pois).map(poi => poi.poiId) })
    if (term)
      app.bus.send('event/search', { referrer, searchMethod, query: term, entities: pois.map(poi => poi.poiId) })
  }

  app.bus.on('search/showRecentSearches', async ({ recentSearches }) => {
    const searchSuggestions = await app.bus.get('search/getDefaultSearchTerms', { limit: 10 })
    const keywords = R.uniqBy(R.toLower, [].concat(recentSearches, searchSuggestions))
    searchResultsWidgetState.update({ ...searchResultsDefaultWidgetState, keywords })
    showSearchBody()
  })

  app.bus.on('searchResultsView/show', () => {
    searchResultsWidgetState.update({ ...searchResultsDefaultWidgetState })
    showCategoryList()
  })

  // todo refactor
  app.bus.on('searchResults/searchForTag', ({ tag }) =>
    performConfirmedQuery(tag, 'tag', 'entityView'))

  app.bus.on('homeview/performConfirmedSearch', ({ term, searchMethod, referrer }) => performConfirmedQuery(term, searchMethod, referrer))

  function showCategoryList () {
    app.bus.send('layers/show', { id: SEARCH_VIEW_CATEGORY_LIST_ID })
  }

  function showResultsWidget () {
    showSearchBody()
  }

  function showSearchBody () {
    app.bus.send('layers/show', { id: SEARCH_VIEW_BODY_ID })
  }

  app.bus.on('homeView/hideCategory', ({ category }) => {
    hiddenCategories.add(category)
    setPOICategoryState()
  })

  // todo remove
  // Reset homeview confirmed search state
  app.bus.on('map/cleanMap', () => searchResultsWidgetState.update({ isSearchConfirmed: false }))

  app.bus.on('bluedot/locationUpdate', ({ hasLocation }) => {
    lastHasLocation = hasLocation
    setPOICategoryState()
  })

  const runTest = async (initialState, testRoutine) => {
    searchResultsWidgetState.update({ ...initialState })
    await testRoutine()
    return searchResultsWidgetState.getState()
  }

  return {
    init,
    widgetState: () => searchResultsWidgetState,
    runTest,
    internal: {
      toggleViewHandler
    }
  }
}

export {
  create
}
