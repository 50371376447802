import * as R from 'ramda'
import React, { useEffect } from 'react'
import styled, { withTheme } from 'styled-components'

import ExpandableContent from '../../../../../src/ui/expandableContent.jsx'
import { ColoredIcon, Icon } from '../../../../../src/ui/icons/Icon.jsx'
import { metersToYards } from '../../../../../src/utils/distance.js'
import { DISPLAY_UNITS_METERS } from '../constants.js'

import { EnrouteControls } from './EnRouteControls.js'
import MultiStopStepList from './multiStopStepList.jsx'
import { RouteInfo, ButtonsContainer, StepIconWrapper, MobileStandardButton, MobileStandardSecondaryButton } from './styles.js'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: auto;
`
const RouteInfoStyled = styled(RouteInfo)`
  padding: 0 12px;
`

const ButtonsContainerStyled = styled(ButtonsContainer)`
  margin-bottom: 12px;
`

const FlatButtonSmall = styled(MobileStandardButton)`
  flex: 0 1 40px;
  fill: ${({ theme }) => theme.colors.primary};
`

const MultiStopListStyled = styled(MultiStopStepList)`
  overflow: scroll;
  width: 100%;
  flex: 1 1 auto;
`

const PrimaryButton = styled(MobileStandardButton)`
  width: 143px;
  height: 40px;
`

export const InnerLayout = React.memo(({
  className, endpoints, times, distances, steps, searchInputs, currentStop, currentStep, onPrevStepClick, onNextStepClick, theme, isOpen,
  setIsOpen, isNavigating, toggleIsNavigating, onStepClicked, notifyAboutChange, toggleNavigationHeader, currentRouteType, onEndRouteClick, onEditRouteClick, preferredUnits, locale, T
}) => {
  useEffect(() => {
    setIsOpen(false)
  }, [isNavigating]) // when entering/exiting navigation, collapse bottom bar

  useEffect(() => {
    isNavigating && toggleNavigationHeader(!isOpen)
    notifyAboutChange()
  }, [isOpen]) // when content is expanded during navigation - toggle navigation header

  const toggleNavigation = () => {
    setIsOpen(false)
    toggleIsNavigating(!isNavigating)
  }

  const distanceDisplay = preferredUnits === DISPLAY_UNITS_METERS
    ? T('getDirectionsFromTo:_distance_m', { distance: R.sum(distances) })
    : T('getDirectionsFromTo:_distance_yards', { distance: metersToYards(R.sum(distances)) })

  const minutesText = R.sum(times)
    ? T('getDirectionsFromTo:_minutes_ min total', { minutes: R.sum(times) })
    : T('getDirectionsFromTo:<1 min')

  const stepsStopsButton = steps.length > 1
    ? <MobileStandardSecondaryButton onClick={() => setIsOpen(!isOpen)} data-cy='ShowStopsButton'>
      <ColoredIcon className="icon" width={24} height={24} id="location" fillColor={theme.colors.secondaryButtonText} />
    &nbsp;{T('getDirectionsFromTo:Stops')}
    </MobileStandardSecondaryButton>
    : <MobileStandardSecondaryButton onClick={() => setIsOpen(!isOpen)} data-cy='ShowStepsButton'>
      <ColoredIcon className="icon" width={24} height={24} id="list-view" fillColor={theme.colors.secondaryButtonText} />
        &nbsp;{T('getDirectionsFromTo:Steps')}
    </MobileStandardSecondaryButton>

  return (
    <Wrapper className={className} data-cy='DirectionsResultViewMobile'>
      {isNavigating && !isOpen
        ? <RouteInfoStyled>
          <StepIconWrapper>
            <Icon id={steps[currentStop][currentStep].icon} width={32} height={45} />
          </StepIconWrapper>
          <div>
            <span className="label" data-cy='StepLabel'>
              {steps[currentStop][currentStep]?.secondaryText}
            </span>
            <span className="info" data-cy='StepInfo'>
              {steps[currentStop][currentStep]?.primaryText}
            </span>
          </div>
        </RouteInfoStyled>
        : <RouteInfoStyled>
          <StepIconWrapper>
            <Icon id={'wayfinding.end'} width={32} height={45} />
          </StepIconWrapper>
          <div>
            <span className="info" data-cy='InfoPrimary'>{minutesText}{' • '}{distanceDisplay}</span>
            <span className="label" data-cy='InfoSecondary'>{T(`getDirectionsFromTo:To ${ endpoints[endpoints.length - 1]?.title }`) }</span>
          </div>
        </RouteInfoStyled>
      }

      {!isNavigating && <ButtonsContainerStyled>
        {isOpen
          ? <MobileStandardSecondaryButton onClick={() => setIsOpen(!isOpen)} data-cy='ShowMapButton'>
            <ColoredIcon className="icon" width={24} height={24} id="map" fillColor={theme.colors.secondaryButtonText} />
          &nbsp;{T('getDirectionsFromTo:Map')}
          </MobileStandardSecondaryButton>
          : stepsStopsButton
        }
        <MobileStandardButton onClick={toggleNavigation} data-cy='GoButton'>
          <ColoredIcon className="icon" width={24} height={24} id="directions-filled" fillColor={theme.colors.primaryButtonText} />
          &nbsp;{T('getDirectionsFromTo:Go')}
        </MobileStandardButton>
      </ButtonsContainerStyled>
      }

      {isNavigating && <ButtonsContainerStyled>
        <PrimaryButton onClick={onPrevStepClick} disabled={currentStop === 0 && currentStep === 0} data-cy='PrevStepButton'>
          <ColoredIcon className="icon" width={32} height={32} id="chevron-left" fillColor={theme.colors.secondaryButtonText} />
          &nbsp;{T('getDirectionsFromTo:Prev')}
        </PrimaryButton>
        <FlatButtonSmall onClick={() => setIsOpen(!isOpen)} data-cy='StepsToggleButton' aria-label={T('getDirectionsFromTo:Toggle Steps')}>
          <ColoredIcon className="icon" width={24} height={24} id="list-view" fillColor={theme.colors.secondaryButtonText}/>
        </FlatButtonSmall>
        <PrimaryButton onClick={onNextStepClick} disabled={currentStop === steps.length - 1 && currentStep === steps.at(-1).length - 1} data-cy='NextStepButton'>
          {T('getDirectionsFromTo:Next')}
          <ColoredIcon className="icon" width={32} height={32} id="chevron-right" fillColor={theme.colors.secondaryButtonText} />
        </PrimaryButton>
      </ButtonsContainerStyled>
      }
      <MultiStopListStyled
        legs={steps}
        times={times}
        distances={distances}
        searchInputs={searchInputs}
        onStepClicked={onStepClicked}
        T={T}
        isNavigating={isNavigating}
        currentStop={currentStop}
        currentStep={currentStep}
        className={isOpen ? 'visible' : 'collapsed'}
        mobileView={true}
        locale={locale}
        isOpen={isOpen}/>
      {isNavigating &&
        <EnrouteControls
          isOpen={!isOpen}
          editRouteLabel={T('getDirectionsFromTo:Edit Route')}
          endRouteLabel={T('getDirectionsFromTo:End Route')}
          onEditRouteClick={onEditRouteClick}
          onEndRouteClick={onEndRouteClick}
        />
      }
    </Wrapper>
  )
})

const DirectionsResultViewMobile = (props) => {
  const { T } = props
  return <ExpandableContent
    maxHeight={props.isNavigating ? '100vh' : '66vh'}
    bus={props.bus}
    isDraggable={true}
    T={T}
    isMapDisabled={!props.isNavigating}
    hasInnerScrollingElement={true}>
    <InnerLayout {...props}/>
  </ExpandableContent>
}

export default withTheme(DirectionsResultViewMobile)
