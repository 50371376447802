import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const TopShadow = styled.div`
  position: absolute;
  height: 10px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
  margin-top: -10px;
  z-index: 1;
  width: ${({ width }) => `${width}px`};
`
const BottomShadow = styled.div`
  height: 10px;
  z-index: 1;
  position: absolute;
  bottom: 48px;
  width: ${({ width }) => `${width}px`};
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.10);
`

const ScrollableDropShadow = ({ displayBottomShadow, children, T, isDesktop }) => {
  const [width, setWidth] = useState(0)
  const [showTopShadow, setShowTopShadow] = useState(false)
  const [showBottomShadow, setShowBottomShadow] = useState(false)
  const componentRef = useRef(null)

  const checkIfScrollable = () => {
    const ref = componentRef.current
    if (ref) {
      if (ref.scrollHeight > ref.clientHeight) {
        setShowBottomShadow(true)
      } else {
        setShowBottomShadow(false)
      }
    }
  }
  useEffect(() => {
    setWidth(componentRef.current.offsetWidth)
  }, [])

  useEffect(() => {
    document.addEventListener('click', checkIfScrollable)
    return () => document.removeEventListener('click', checkIfScrollable)
  }, [])

  // Check if component was scrolled
  const onScrollHandler = () => {
    const ref = componentRef.current
    if (ref) {
      const didScroll = ref.scrollTop > 0
      if (didScroll !== showTopShadow) {
        setShowTopShadow(didScroll)
      }

      const scrolledToBottom = ref.scrollHeight - ref.scrollTop - ref.clientHeight < 1
      if (scrolledToBottom) {
        setShowBottomShadow(false)
      } else {
        setShowBottomShadow(true)
      }
    }
  }

  return <Wrapper ref={componentRef} onScroll={onScrollHandler} data-cy='DropShadowWrapper' role={!isDesktop ? 'dialog' : null} aria-modal={!isDesktop && true} aria-label={T('ui:Scrollable content')}>
    {showTopShadow && <TopShadow width={width} data-cy='TopShadow'/>}
    {children}
    {displayBottomShadow && showBottomShadow && <BottomShadow width={width} data-cy='BottomShadow'/>}
  </Wrapper>
}

export default ScrollableDropShadow
