import { path, pipe, propEq, find, has } from 'ramda'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import { ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'
import { getOpenTimeLabel } from '../weekdayPeriodsParser.js'

const InfosList = styled.ul`
  list-style: none;
  margin: 3px 0 12px 47px;
  ${({ theme }) => theme.fontSize('14px')};
  color: ${({ theme }) => theme.colors.secondaryText};
  padding-inline-start: 0;

  li {
    > :first-child {
      margin-right: 5px;
    }
  }
`

const IconWrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
`

const PoiFeatures = withTheme(({ theme, poi, children, T, venueCategory, structures, venueTimezone }) => {
  if (!poi) return null

  const Feature = ({ iconId, children }) => <li>
    <IconWrapper>
      <ColoredIcon className="icon" width={24} height={24} id={iconId} fillColor={theme.colors.secondaryText} />
    </IconWrapper>
    {children}
  </li>

  const DefaultInfo = () => <React.Fragment>
    <Feature iconId="levels">{getFloorDetails(poi, structures)}</Feature>
    <Feature iconId="building">{poi.position.structureName}</Feature>
  </React.Fragment>

  const RoomIdInfo = () => {
    const roomInfoLabel = getRoomInfoLabel(poi)
    return !!roomInfoLabel && <Feature iconId='location'>{roomInfoLabel}</Feature>
  }

  const getRoomInfoLabel = poi => {
    if (poi.roomId) {
      const nearbyLandmark = poi.nearbyLandmark
        ? T('poiView:near _location_', { location: poi.nearbyLandmark })
        : ''
      return joinStr([poi.roomId, nearbyLandmark], ' ')
    } else if (poi.nearbyLandmark)
      return T('poiView:Near _location_', { location: poi.nearbyLandmark })
  }

  const getFloorDetails = (poi, structures) => {
    const { floorName, floorId, buildingId } = poi.position
    const floorDetails = pipe(
      find(propEq(buildingId, 'id')),
      path(['levels', floorId, 'details'])
    )(structures)
    return joinStr([floorName, floorDetails])
  }

  const MeetingInfo = () => <React.Fragment>
    <DefaultInfo />
    {poi.capacity &&
      <Feature iconId="number-of-seats">
        {T('poiView:Seats')}: {poi.capacity.join('-')}
      </Feature>
    }
  </React.Fragment>

  const ParkingInfo = () => {
    // todo maybe change dynamicData to dynamicIntegration
    const { rateHour, rateDay } = path(['dynamicData', 'parking'], poi) || {}
    const getRate = (rate) => {
      if (!rate) return '-'
      return rate.match('[-]') ? rate : rate.split(' ')[0] // if the rate returned was just in '$ -' format return it, otherwise take currency and value
    }

    if (!rateHour && !rateDay)
      return <DefaultInfo />

    const rateForHour = getRate(rateHour)
    const rateForDay = getRate(rateDay)
    return <React.Fragment>
      <DefaultInfo />
      <Feature iconId="coins">
        {T('poiView:_rate_ per hour', { rate: rateForHour })} / {T('poiView:_rate_ per day', { rate: rateForDay })}
      </Feature>
    </React.Fragment>
  }

  const SecurityInfo = () => {
    const nearbyLandmark = poi.nearbyLandmark && T('poiView:Near _location_', { location: poi.nearbyLandmark })
    const isAfterSecurity = getAfterSecurityLabel()
    const securityInfo = joinStr([nearbyLandmark, isAfterSecurity])
    return !!securityInfo && <Feature iconId="location">{securityInfo}</Feature>
  }

  const joinStr = (args, delimiter = ' • ') => args.filter(i => i).join(delimiter)

  const getAfterSecurityLabel = () => {
    if (isSecurityCheckpoint(poi) || isParking(poi)) return '' // do not show this info for security checkpoint pois or parking
    if (!has('isAfterSecurity', poi)) return ''
    return poi.isAfterSecurity ? T('poiView:After Security') : T('poiView:Outside Security')
  }

  const OpenTimeInfo = () => (isSecurityCheckpoint(poi) && Boolean(poi.operationHours)) &&
    <Feature iconId="opening-hours">
      {getOpenTimeLabel(poi.operationHours, new Date(), T, venueTimezone)}
    </Feature>

  const isSecurityCheckpoint = ({ category }) => category === 'security.checkpoint' || category === 'security'
  const isParking = ({ category }) => category.startsWith('parking')

  const getFeatures = (category) => {
    if (category.startsWith('meeting')) return <MeetingInfo />
    if (isParking({ category })) return <ParkingInfo />
    return <DefaultInfo />
  }

  const getCasinoNearLocationLabel = () => {
    if (venueCategory === 'casino' && poi.nearbyLandmark)
      return <Feature iconId='location'>{T('poiView:Near _location_', { location: poi.nearbyLandmark })}</Feature>
  }

  return <InfosList>
    {venueCategory === 'airport' && <SecurityInfo />}
    {venueCategory === 'smartcampus' && <RoomIdInfo/>}
    {getCasinoNearLocationLabel()}
    {getFeatures(poi.category)}
    <OpenTimeInfo />
  </InfosList>
})

export default PoiFeatures
