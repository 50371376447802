/**
 * This manages the creation and organization of your App instances.
 * Each instance is created via the create function - passing in a configuration
 * object, which extends any existing configuration template.
 */

import { create as createApp } from './app.js'

// Note, we are no longer using IOBject - but we can freeze apps via Object.freeze(apps)
// IObject.freeze = 'DEEP'

// This is a list of "instances" of your full app stack. Often this will be only one.
// If you wish to give it a name, use the appName property. Else one will be assigned.
const apps = { }

const sendAlert = msg => typeof window !== 'undefined' && window.alert ? window.alert(msg) : console.error(msg)

/**
 * Create a new instance of the engine. Pass in a configuration object which will
 * extend the config template.
 * @param  {} config Configuration for this instance. Will be shallow copied.
 */
async function create (config) {
  if (!config) { throw Error('Attempt to create App instance with no configuration') }

  // If no name was defined for this instance, create one.
  const appName = config.appName || 'Instance' + (Object.keys(apps).length + 1)
  config.appName = appName

  try {
    const app = await createApp(config)
    apps[appName] = app
    return app
  } catch (e) { console.error(e); e.message ? sendAlert(e.message) : sendAlert('Error creating map. Please try again later.') }
}

export {
  create
}
