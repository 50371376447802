import { Marker, Popup } from 'maplibre-gl'
import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'

export default function MarkerController (app, mapInitialized) {
  const markers = {}
  const lineSources = {}

  const renderToDOM = (reactComponent) => {
    const el = document.createElement('div')
    const { getTheme } = app.themePack
    ReactDOM.render(<ThemeProvider theme={ getTheme() }>{reactComponent}</ThemeProvider>, el)
    return el
  }

  app.bus.on('map/addMarker', ({ id, ordinal, markerComponent, lng, lat, markerOptions = {}, hoverHTML }) => {
    mapInitialized().then(async map => {
      if (markers[id]) {
        if (markers[id].ordinal === ordinal && markers[id].hoverHTML === hoverHTML) { // i.e. if anything changed other than lat,lng
          markers[id].instance.setLngLat([lng, lat]) // we don't know if it moved - do this in case
          return
        } else
          markers[id].instance.remove() // ordinal changed, so lets remove the instance and add to new ordinal
      }
      const renderedComponent = markerComponent ? renderToDOM(markerComponent) : undefined
      const newMarker = new Marker({ element: renderedComponent, anchor: renderedComponent ? 'bottom' : undefined, ...markerOptions })
        .setLngLat([lng, lat])
      markers[id] = { instance: newMarker, ordinal, isGhosted: false }
      const currentOrdinal = await app.bus.get('map/getCurrentOrdinal')

      if (hoverHTML && renderedComponent) {
        let popup

        renderedComponent.addEventListener('mouseenter', () => {
          const coordinates = [lng, lat]
          if (popup) popup.remove() // Make sure to remove any previous popup before creating a new one

          popup = new Popup({
            closeButton: false,
            closeOnClick: false,
            offset: renderedComponent.getBoundingClientRect().height / 2,
            maxWidth: 'none'
          })
          popup.setLngLat(coordinates).setHTML(hoverHTML).addTo(map)
          markers[id].popup = popup
          // map.getCanvas().style.cursor = 'pointer'
        })
        renderedComponent.addEventListener('mouseleave', () => {
          popup.remove()
        })
      }

      if (currentOrdinal === ordinal) {
        newMarker.addTo(map)
        // map.getCanvas().style.cursor = ''
      }
    })
  })

  app.bus.on('map/clearLines', ({ groupName }) => {
    mapInitialized().then(async (map) => {
      const standardSourceName = lineSourceName(groupName)
      if (map.getSource(standardSourceName)) {
        map.removeLayer(lineLayerName(groupName))
        map.removeSource(standardSourceName)
        delete lineSources[standardSourceName]
      }
    })
  })

  const lineSourceName = groupName => `drawlines-${groupName}`
  const lineLayerName = groupName => lineSourceName(groupName) + '-layer'

  app.bus.on('map/drawLines', ({ groupName, ord, coords, style = {} }) => {
    mapInitialized().then(async map => {
      if (ord === undefined)
        ord = await app.bus.get('map/getCurrentOrdinal')

      if (groupName.length < 1 || !/^[-a-zA-Z0-9]*$/.test(groupName))
        throw Error(`Illegal lines group name ${groupName}. Must be alphanumeric only.`)

      const sourceName = lineSourceName(groupName)
      const gradient = style.gradient

      if (map.getSource(sourceName)) {
        map.removeLayer(lineLayerName(groupName))
        map.removeSource(sourceName)
      }

      map.addSource(sourceName, {
        type: 'geojson',
        lineMetrics: Boolean(gradient),
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: coords
          }
        }
      })

      const layerProperties = {
        id: lineLayerName(groupName),
        type: 'line',
        minZoom: 0,
        maxZoom: 24,
        source: sourceName,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': style.color || '#888',
          'line-width': style.width || 8
        }
      }
      if (gradient)
        layerProperties.paint['line-gradient'] = [
          'interpolate',
          ['linear'],
          ['line-progress'],
          ...gradient.flatMap((color, index) => [
            index / (gradient.length - 1), // Calculate gradient stops
            color
          ])
        ]
      map.addLayer(layerProperties)
    })

    lineSources[groupName] = { ordinal: ord }
  })

  const removeMarker = (id) => {
    const marker = markers[id]
    if (marker) {
      if (marker.popup)
        marker.popup.remove()
      marker.instance.remove()
      if (marker.isGhosted) {
        marker.ghostInstance.remove()
      }
      delete markers[id]
    }
  }

  app.bus.on('map/addGhostedMarker', ({ id, ordinal, markerComponent, ghostMarkerComponent, lng, lat, markerOptions = {}, ghostMarkerOptions = {} }) => {
    mapInitialized().then(async map => {
      const renderedComponent = renderToDOM(markerComponent)
      const newMarker = new Marker({ element: renderedComponent, anchor: 'bottom', ...markerOptions })
        .setLngLat([lng, lat])
      const renderedGhostComponents = renderToDOM(ghostMarkerComponent)
      const newGhostMarker = new Marker({ element: renderedGhostComponents, anchor: 'bottom', ...ghostMarkerOptions })
        .setLngLat([lng, lat])
      if (markers[id]) {
        removeMarker(id)
      }
      markers[id] = { instance: newMarker, ordinal, isGhosted: true, ghostInstance: newGhostMarker }
      const currentOrdinal = await app.bus.get('map/getCurrentOrdinal')
      if (currentOrdinal === ordinal) {
        markers[id].instance.addTo(map)
      } else {
        markers[id].ghostInstance.addTo(map)
      }
    })
  })

  app.bus.on('map/removeMarker', ({ id }) => {
    mapInitialized().then(() => removeMarker(id))
  })

  app.bus.on('map/ordinalChanged', ({ ordinal }) => {
    // remove any markers not on the destination ordinal.. add any markers that are.
    mapInitialized().then(map => {
      Object.values(markers).forEach(marker => {
        if (marker.ordinal === ordinal) {
          marker.instance.addTo(map)
          if (marker.isGhosted) {
            marker.ghostInstance.remove()
          }
        } else {
          marker.instance.remove()
          if (marker.isGhosted) {
            marker.ghostInstance.addTo(map)
          }
        }
      })

      // hide any lines that are NOT on the destination ordinal.. show any that are
      Object.keys(lineSources).forEach(groupName => {
        const layerName = lineLayerName(groupName)
        if (lineSources[groupName].ordinal === ordinal)
          map.setLayoutProperty(layerName, 'visibility', 'visible')
        else
          map.setLayoutProperty(layerName, 'visibility', 'none')
      })
    })
  })

  app.bus.on('map/removeAllMarkers', () => {
    mapInitialized().then(() => {
      Object.keys(markers).forEach(id => removeMarker(id))
    })
  })
}
