import { DateTime } from 'luxon'
import { rgba } from 'polished'
import * as R from 'ramda'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import { ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'
import Link from '../../../../../src/ui/Link.jsx'
import { formatTime } from '../../../../../src/utils/date.js'
import { getThemeColor } from '../../../../../src/utils/styled-component-tools.js'

const weekdays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

const Wrapper = styled.div`
  margin: 16px -12px 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${getThemeColor('primaryText', '#333333', 0.1)};

`

// Note: Is this really unique to the Information section? It feels this should be pulled out to be usable in other sections for consistency.
const Title = styled.h2`
&& {
  margin: 0px;
  background: ${getThemeColor('primary', '#2266DD', 0.05)};
  ${({ theme }) => theme.fontSize('14px')};
  line-height: 18px;
  font-weight: 500;
  padding: 7px 8px 7px 64px;
  border-top: 1px solid ${getThemeColor('primaryText', '#333333', 0.1)};
  &:lang(ar) { padding: 7px 64px 7px 8px; }
  text-transform: uppercase;
}
`

const InformationsList = styled.ul`
  ${({ theme }) => theme.fontSize('16px')};
  line-height: 150%;
  margin: 0;
  padding: 0;
  list-style: none;
`

const Information = styled.li`
  padding: 12px 16px;
  border-top: 1px solid ${getThemeColor('primaryText', '#333333', 0.1)};
  display: flex;
  align-items: center;

  & > span {
    flex: 1 0 auto;
  }

  a {
    text-decoration: none;
    color: #333;
    cursor: pointer;
  }
`

const IconWrapper = styled.div`
  margin-right: 16px;
  flex: none;
  flex: 0 0 auto;

  &:lang(ar) { margin-right: 0; margin-left: 16px; }
`

const WebsiteUrl = styled.div`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
`
const OpeningTime = styled.div`
  text-transform: capitalize;
`

// Takes a time in HH:MM format and reformats it for the specified locale
const reformatTime = (t, locale) => {
  let [hours, minutes] = t.split(':').map(Number)
  hours %= 24
  minutes %= 60

  return formatTime(DateTime.fromObject({ hour: hours, minute: minutes }), locale)
}

const PoiInformation = withTheme(({ theme, poi, T, lang, submitAnalyticsEvent, submitAnalyticsEventWithAppInsights }) => {
  if (!poi)
    return null

  const info = R.pick(['phone', 'operationHours', 'roomInfo'], poi)

  R.pipe(
    R.propOr([], 'links'),
    R.find(R.propEq('primary', 'type')),
    R.prop('url'),
    R.unless(R.isNil, url => { info.url = url })
  )(poi)

  if (R.isEmpty(info)) return null

  const getOpeningHours = (operationHours) => {
    if (!operationHours) return null

    if (poi.operationHours === 'Mo-Su 00:00-24:00')
      return T('poiView:Open 24 hours')

    const formatTimeToLocale = (t, locale) => t === 'lastFlight' ? T('poiView:Last Flight') : reformatTime(t, locale)

    // another hack to parse out the times.. <sigh>
    const splitDayHours = time => {
      const firstSpace = time.indexOf(' ')
      return [time.substring(0, firstSpace), time.substring(firstSpace + 1)]
    }

    let hours = operationHours.split(';').map(hourStr => {
      const [day, time] = hourStr.trim().split(' ')
      const newTime = time.split('-').map(t => formatTimeToLocale(t, lang)).join('-')
      return `${day} ${newTime}`
    })

    if (lang !== 'en') {
      hours = hours.filter(Boolean).map(time => { // get separate time frames
        const arr = splitDayHours(time) // separate day from opening hours
        const days = arr[0].split('-') // if day was in Mo-Fr format, separate it
        const openingHours = arr.slice(1, arr.length)
        const translatedDays = days.map(day => {
          const dayOfWeek = weekdays.findIndex(el => el === day) // get day of the week
          const currentDay = new Date()
          const distance = dayOfWeek - currentDay.getDay() // check the difference between day and the current day of the week
          const newDate = currentDay.setDate(currentDay.getDate() + distance)
          const newDay = DateTime.fromMillis(newDate).setLocale(lang).weekdayShort // get localized day of the week
          return newDay
        })
        return translatedDays.join('-') + ' ' + openingHours
      })
    }

    return hours
  }

  const getTitle = (category) => {
    if (category.startsWith('workstation')) return 'Contact'
    if (category.startsWith('meeting')) return 'Room Info'
    return 'Information'
  }
  const formattedHours = getOpeningHours(poi.operationHours)

  return <Wrapper>
    <Title>{T(`poiView:${getTitle(poi.category)}`)}</Title>
    <InformationsList>

      {info.url && <Information>
        <IconWrapper>
          <ColoredIcon width={32} height={32} id={poi.category.startsWith('workstation') ? 'email' : 'website'} fillColor={theme.colors.primaryText} />
        </IconWrapper>
        <WebsiteUrl aria-label={T(`poiView:Link to External Website`)} >
          <Link data-cy='WebsiteUrl' href={poi.category.startsWith('workstation') ? `mailto:${info.url}` : info.url} onClick={() => submitAnalyticsEventWithAppInsights(poi, 'website', info.url)}>
            {info.url}
          </Link>
        </WebsiteUrl>
      </Information>}

      {info.phone && <Information>
        <IconWrapper>
          <ColoredIcon width={32} height={32} id="phone" fillColor={theme.colors.primaryText} />
        </IconWrapper>
        <span aria-label={T('poiView:Link to Phone Number')} tabIndex={0}>
          <Link href={`tel:${info.phone}`} onClick={() => submitAnalyticsEvent(poi, 'phone', info.phone)}>
            {info.phone}
          </Link>
        </span>
      </Information>}

      {info.operationHours && <Information>
        <IconWrapper>
          <ColoredIcon width={32} height={32} id="opening-hours" fillColor={theme.colors.primaryText} />
        </IconWrapper>
        <div>
          {Array.isArray(formattedHours)
            ? formattedHours.map((hours, index) => <OpeningTime key={index} tabIndex={0}>{hours}</OpeningTime>)
            : <span aria-label={T('poiView:Operational Hours')} tabIndex={0}>{formattedHours}</span>}
        </div>
      </Information>}

      {info.roomInfo &&
        info.roomInfo.map(el => <Information>
          <IconWrapper>
            <ColoredIcon width={32} height={32} id={el.svgId} fillColor={theme.colors.primaryText} />
          </IconWrapper>
          <div>
            {el.name}
          </div>
        </Information>)
      }
    </InformationsList>
  </Wrapper>
})

export default PoiInformation
export { reformatTime }
