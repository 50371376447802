import { v4 as uuidv4 } from 'uuid'
import Zousan from 'zousan'

import pkg from '../../../package.json'

const version = pkg.version

export function createSession (app) {
  const sessionConfig = {}
  const sessionInitializedProm = new Zousan()
  // const sessionTimer = null

  async function initSession (venueId, accountId) {
    const id = uuidv4()

    const event = {
      type: 'sessionStart',
      id,
      sdkVersion: version
    }

    event.browserUserAgent = window.navigator.userAgent
    event.locale = window.navigator.language
    event.sessionId = id
    event.venueId = venueId

    sessionConfig.sessionId = id
    sessionConfig.venueId = venueId
    sessionConfig.accountId = accountId
    sessionConfig.locale = event.locale

    sessionConfig.sessionWasInteractive = false

    const kioskData = (await app.bus.send('kioskAdmin/getKioskData'))[0]
    if (kioskData && kioskData.kiosk) {
      event.deviceName = kioskData.kiosk.name
      const { lat, lng, floorId } = kioskData.kiosk.location
      event.position = { lat, lng, floorId }
    }

    app.bus.send('event/submitEvent', { event, venueId, accountId })

    // resetTimeout()
    sessionInitializedProm.resolve(sessionConfig)
  }

  app.bus.on('venueData/mapDataLoaded', ({ venueId, accountId }) => {
    initSession(venueId, accountId)
  })

  // function resetTimeout () {
  //   clearTimeout(sessionTimer)
  //   sessionTimer = setTimeout(endSession, 60000)
  // }

  app.bus.on('session/submitEvent', (event) => {
    // resetTimeout()
    sessionInitializedProm
      .then(sessionConfig => {
        sessionConfig.sessionWasInteractive = true
        event.sessionId = sessionConfig.sessionId
        app.bus.send('event/submitEvent', { event, venueId: sessionConfig.venueId, accountId: sessionConfig.accountId })
      })
  })

  app.bus.on('session/getId', () => sessionInitializedProm
    .then(sessionConfig => sessionConfig.sessionId))

  app.bus.on('events/endSession', endSession)

  function endSession () {
    const event = {}
    event.sessionId = sessionConfig.sessionId
    event.endtime = new Date().toISOString()
    event.interactive = sessionConfig.sessionWasInteractive
    event.locale = sessionConfig.locale
    event.sdkVersion = version
    event.type = 'sessionFinish'

    app.bus.send('event/submitEvent', { event, venueId: sessionConfig.venueId, accountId: sessionConfig.accountId })
    initSession(sessionConfig.venueId, sessionConfig.accountId)
  }
}
