import MaplibreGL from 'maplibre-gl'
import * as R from 'ramda'
import React from 'react'
import Zousan from 'zousan'

import AnimationController from './animationController.js'
import BadgeController from './badgeController.js'
import DebugToolsController from './debugToolsController.js'
import DynamicPoiStyleController from './dynamicPoiStyleController.js'
import FloorController from './floorController.js'
import HeatmapController from './heatmapController.js'
import LabelController from './labelController.js'
import MapComponent from './mapComponent.js'
import MapThemeController from './mapThemeController.js'
import MarkerController from './markerController.js'
import NavlineController from './navlineController.js'
import SelectionController from './selectionController.js'
import StateController from './stateController.js'
import UserInteractionController from './userInteractionController.js'

// MaplibreGL.accessToken = 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA'

MaplibreGL.setRTLTextPlugin(
  // find out the latest version at https://www.npmjs.com/package/@mapbox/mapbox-gl-rtl-text
  'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
  true // lazy: only load when the map first encounters Hebrew or Arabic text
) // returns a Promise<void>

// insert Mapbox CSS to fix styling issues and let things like markers work correctly
// document.head.insertAdjacentHTML('beforeend', `<link href='https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css' rel='stylesheet' />`)
document.head.insertAdjacentHTML('beforeend', `<link href='https://unpkg.com/maplibre-gl@4.7.1/dist/maplibre-gl.css' rel='stylesheet' />`)

function create (app, config) {
  let mapInitializedPromise = new Zousan()
  const mapInstanceReceived = new Zousan()
  let thisMap = null

  const dlp = config?.deepLinkProps || {}

  const mapInitialized = () => mapInitializedPromise

  const controllers = [
    new SelectionController(app, mapInitialized),
    new UserInteractionController(app, mapInitialized, config),
    new FloorController(app, mapInitialized),
    new NavlineController(app, mapInitialized),
    new MarkerController(app, mapInitialized),
    new AnimationController(app, mapInitialized, config),
    new LabelController(app, mapInitialized),
    new BadgeController(app, mapInitialized),
    new StateController(app, mapInitialized, config),
    new MapThemeController(app, mapInitialized),
    new DynamicPoiStyleController(app, mapInitialized),
    new HeatmapController(app, mapInitialized)
  ]

  if (app.config.debug)
    controllers.push(new DebugToolsController(app, mapInitialized))

  const init = () => {
    app.bus.send('venueData/loadMap')
    if (dlp.visualTest === 'true') {
      app.bus.send('map/visualTest', { hideMap: true })
    }
  }

  const onMapComponentInitialized = (map) => {
    if (thisMap) {
      const oldContainer = thisMap.getContainer()
      const newContainer = map.getContainer()
      newContainer.classList.add('maplibregl-map')
      while (oldContainer.childNodes.length > 0) {
        newContainer.appendChild(oldContainer.childNodes[0])
      }
    }
    thisMap = map
    mapInstanceReceived.resolve(thisMap)
  }

  const viewLimits = R.pick(['minZoom', 'maxZoom', 'minPitch', 'maxPitch'], config.viewLimits)

  app.bus.send('layers/register', {
    id: 'mapRenderer',
    widget: () => <MapComponent onInit={onMapComponentInitialized} viewLimits={viewLimits} />,
    layoutId: 'map',
    layoutName: app.config.initialLayout || 'standard'
  })

  const buildLayers = (style) => {
    const newLayers = controllers.reduce((layers, controller) => controller.initMapLayers ? controller.initMapLayers(layers) : layers, style.layers)
    const fixLayer = (layer) => {
      // if a layer requires a source we haven't initialized, then print a warning and remove the layer
      if (layer.source && !style.sources[layer.source]) {
        console.warn(`No '${layer.source}' source found for layer '${layer.id}'`)
        return []
      }
      return R.dissoc('source-layer', layer)
    }
    return newLayers.flatMap(fixLayer)
  }

  const buildInitialMapStyle = ({ mapStyleSource, badgesSpriteUrl, mapGlyphsUrl }) => {
    const rawMapStyle = JSON.parse(mapStyleSource)
    let style = R.mergeRight(rawMapStyle, {
      sprite: badgesSpriteUrl,
      glyphs: mapGlyphsUrl
    })
    style = R.omit(['bearing', 'name', 'owner', 'pitch', 'sources', 'visibility', 'zoom'], style)
    style.sources = controllers.reduce((sources, controller) => controller.initMapSources ? controller.initMapSources(sources) : sources, {})
    style.layers = buildLayers(style)
    augmentStyle(style)
    return style
  }

  function addTilemapLayer (map, tileServerAuthInfo) {
    map.on('load', () => {
      map.addSource('tiles', {
        type: 'raster',
        tiles: [tileServerAuthInfo.tileUrl],
        minzoom: 14,
        maxzoom: 24
      })

      map.addLayer({
        id: 'tiles',
        type: 'raster',
        source: 'tiles' // ID of the tile source created above
      }, 'outdoor')
    })
  }

  // Takes a style and a string that might exist in a filter expression operand. It returns
  // a list of matches - with each match being represented by an object containing properties p and i where:
  //  p : parent array in which the operand exists
  //  i : indexn into parent array where operand was found
  // This assists in finding operands for matching expressions so they may be amended/augmented.
  const findFilterItems = (style, name) => findStringsInNestedArrays(style.layers.map(layer => layer.filter), name)

  const findStringsInNestedArrays = (ar, name) => ar
    .reduce((plist, fitem, i) =>
      typeof fitem === 'string'
        ? (fitem === name
          ? plist.concat({ p: ar, i })
          : plist)
        : (Array.isArray(fitem)
          ? plist.concat(findStringsInNestedArrays(fitem, name))
          : plist), [])

  // this style augmentation routine finds all references to nav.alternative in all filters, and adds
  // nav.multipoint and nav.alternativemultipoint to it. Once we are happy with how multipoint navlines
  // look we can roll out a change to the style to all customers and remove this.
  const augmentStyleForMultipoint = style => {
    findFilterItems(style, 'nav.alternative')
      .forEach(match =>
        match.p.splice(match.i, 0, 'nav.multipoint', 'nav.alternativemultipoint'))

    const linePaint = style.layers.find(layer => layer.id === 'nav - alternative')?.paint
    if (linePaint)
      linePaint['line-opacity'] = ['get', 'fillColorOpacity']
  }

  // Place any style processing that must occur for both initial and replaced styles
  const augmentStyle = style => {
    // temporarily augment style for multipoint routing until we can migrate all styles
    augmentStyleForMultipoint(style)
  }

  const initializeMap = ({ venueBounds, style, map, tileServerAuthInfo }) => {
    if (app.config.debug && typeof window !== 'undefined')
      window._mapbox = map
    map.setStyle(style, { diff: false })
    map.once('styledata', () => {
      mapInitializedPromise.resolve(map)
    })
    // const maxBounds = [[venueBounds.w, venueBounds.s], [venueBounds.e, venueBounds.n]]
    // map.setMaxBounds(maxBounds)
    if (config.padding)
      app.bus.send('map/changePadding', { padding: config.padding })
    if (tileServerAuthInfo)
      addTilemapLayer(map, tileServerAuthInfo)
  }

  app.bus.on('venueData/mapDataLoaded', async (params) => {
    const initialMapStyle = buildInitialMapStyle(params)
    mapInstanceReceived.then((map) => initializeMap({ ...params, style: initialMapStyle, map }))
    await app.bus.send('layers/show', { id: 'mapRenderer' })
  })

  app.bus.on('venueData/loadNewVenue', () => {
    mapInitializedPromise = new Zousan()
    init()
  })

  app.bus.on('map/blur', ({ px }) =>
    mapInitialized().then(map => {
      map.getCanvas().style.filter =
        !px
          ? ''
          : `blur(${px}px)`
    }))

  app.bus.on('map/resize', () =>
    mapInitialized().then(map => map.resize()))

  app.bus.on('map/replaceStyle', ({ styleSrc }) => {
    mapInitialized().then(map => {
      const loadedStyle = JSON.parse(styleSrc)
      const currentStyle = map.getStyle()
      loadedStyle.sources = { ...currentStyle.sources }
      const newLayers = buildLayers(loadedStyle)
      const newStyle = { ...currentStyle, layers: newLayers }
      augmentStyle(newStyle) // apply any manual processing
      map.setStyle(newStyle)
    })
  })

  app.bus.on('map/resize', () => mapInitialized().then(map => map.resize()))

  return {
    init
  }
}

export {
  create
}
