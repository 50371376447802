import React, { useEffect, useState } from 'react'

import DirectionsSearchControlsViewMulti from './DirectionsSearchControlsViewMulti.jsx'

const DirectionsSearchControls = ({
  handleInputSelect,
  onInputClearButtonClick,
  handleSwitchInputsButtonClicked,
  handleKeyPress,
  widgetState,
  logoUrl,
  logoLinkUrl,
  clientName,
  isDesktop,
  handleInputValueChange,
  onBackButtonClicked,
  handleSearchInputsChange,
  T
}) => {
  const [state, setWidgetState] = useState(widgetState.getState())
  useEffect(() => widgetState.addCallback(setWidgetState))

  return <DirectionsSearchControlsViewMulti {...state}
    logoUrl={logoUrl}
    logoLinkUrl={logoLinkUrl}
    clientName={clientName}
    onInputSelect={handleInputSelect}
    onInputClearButtonClick={onInputClearButtonClick}
    onSingleInputValueChange={handleInputValueChange}
    onBackButtonClicked={onBackButtonClicked}
    onSwitchInputsButtonClicked={handleSwitchInputsButtonClicked}
    handleKeyPress={handleKeyPress}
    isMobile={!isDesktop()}
    handleSearchInputsChange={handleSearchInputsChange}
    T={T}
    widgetState={widgetState}
  />
}

export default DirectionsSearchControls
