export default function UserInteractionController (app, mapInitialized, config) {
  let state = {
    enablePoiSelection: 'enablePoiSelection' in config ? config.enablePoiSelection : true
  }

  mapInitialized().then(map => {
    config.enableDoubleClickZoom ? map.doubleClickZoom.enable() : map.doubleClickZoom.disable()
    config.disableTouchRotation ? map.touchZoomRotate.disableRotation() : map.touchZoomRotate.enableRotation()

    map.on('click', async function (e) {
      app.bus.get('map/getCurrentOrdinal')
        // todo change name of ord to ordinal
        .then(ord => app.bus.send('map/click', { lat: e.lngLat.lat, lng: e.lngLat.lng, ord }))

      if (!state.enablePoiSelection) {
        return
      }
      const features = map.queryRenderedFeatures(e.point)
      const entity = features
        ? features.find(f => f.source === 'llNavSource') || // top priority: nav badge
          features.find(f => f.properties.aiLayer === 'poi') || // second: general poi
          features[0] // else: first one
        : undefined
      if (entity) {
        if (entity.layer.source === 'llBuildingLabelsSource') {
          app.bus.send('mapLevelSelector/selectBuilding', { id: entity.properties.buildingId })
        } else {
          const poi = await app.bus.get('poi/getById', { id: entity.properties.id })
          if (poi) {
            app.bus.send('map/poiClicked', { poi })
          }
          if (entity.properties.category === 'basemap.jetway') {
            app.bus.send('map/animateToPoint', ({
              lat: entity.geometry.coordinates[1],
              lng: entity.geometry.coordinates[0],
              zoom: 19.5
            }))
          }
        }
      }
    })
  })

  app.bus.on('map/setEnablePoiSelection', (value) => {
    state = { ...state, enablePoiSelection: value }
  })

  app.bus.on('map/disableMap', ({ disable }) => {
    mapInitialized().then(map => {
      const interactionHandlers = ['boxZoom', 'doubleClickZoom', 'dragPan', 'dragRotate', 'scrollZoom', 'touchPitch', 'touchZoomRotate']
      interactionHandlers.forEach(handler => {
        if (disable) {
          map[handler].disable()
        } else {
          map[handler].enable()
        }
      })
    })
  })
}
