import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import { remove } from 'ramda'
import React, { useRef, useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'

import { handleKeyboardSelect } from '../../../plugins/utils/keyboardUtils.js'
import { ColoredIcon } from '../../../src/ui/icons/Icon.jsx'

import DraggableOptions from './subcomponents/DraggableOptions.jsx'

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  position: relative;
`

const InputWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 4px 4px 4px 12px;
  position: relative;
  display: flex;
  box-sizing: border-box;
  background-color: ${({ theme }) => rgba(theme.colors.widgetText, 0.1)};
  line-height: 150%;
  min-height: 40px;
  align-items: center;
  border-radius: ${({ $first, $last }) => {
  if ($first) {
    return `8px 8px 0 0`
  }
  if ($last) {
    return '0 0 8px 8px'
  }
}};

  .-desktop & {
    padding: 6px 4px 6px 12px;
    min-height: 44px;
  }

  input {
    flex: 1 1 auto;
    min-width: 0;
    ${({ theme }) => theme.fontSize('16px')};
    line-height: 150%;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.widgetText};
    border: 0;
    display: block;
    background-color: transparent;
    text-overflow: ellipsis;
    padding: 0;

    &::placeholder {
      color: ${({ theme }) => theme.colors.widgetText};
      opacity: 0.8;
    }

    &:focus:not([readonly]) {
      box-shadow: none;
      border-bottom: none;
    }

    &:focus {
      outline: none;
    }
  }

  div:last-child {
    position: relative;
  }
`

const ClosePositionHolder = styled.div`
  height: 32px;
  width: 32px;
`
const IconWrapper = styled.div`
  cursor: pointer;
`

const DraggableDiv = styled.div`
  touch-action: none;
`
const DraggableIcon = styled(ColoredIcon)`
  cursor: grab;
`

const Spacer = styled.div`
  width: 12px;
`
// Replace "remove x" with dropdown menu
// make the drag area not all over the page!
const SearchInputLabelledMulti = ({
  autoFocus,
  value,
  index,
  searchInputs,
  id,
  placeholder,
  onInputChange,
  onInputClearButtonClick,
  onInputSelect,
  handleKeyPress = () => {},
  theme,
  className,
  clickableItemOptions,
  handleRemoveClick,
  handleMoveClick,
  draggable,
  T,
  'data-cy': dataCy,
  hasClearButton = true,
  first,
  last,
  isMobile,
  ...rest
}) => {
  const [term, setTerm] = useState('')

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id }) // This is important. Value passed to Id here needs to be unique and have the key 'id'.
  const style = {
    transition,
    transform: CSS.Transform.toString(transform) // Take the transform prop and apply it here
  }

  useEffect(() => {
    setTerm(value)
  }, [value])

  const inputRef = useRef()

  const handleUpdate = (e) => {
    if (onInputSelect)
      onInputSelect(index) // set the currentIndex when we begin typing in the input field
    setTerm(e.target.value)
    onInputChange(e.target.value)
  }

  const handleClearClick = () => {
    if (onInputClearButtonClick)
      onInputClearButtonClick(index) // Calls resetInputData({ index })
    if (onInputSelect)
      onInputSelect(index)
  }

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [autoFocus])

  const handleKey = (e) => {
    handleKeyPress(e, term, index)
  }

  const clearClick = () => {
    setTerm('')
    inputRef.current.focus()
    handleClearClick()
  }

  const menuItems = [
    { iconId: 'arrow.left', label: 'Move to start', onClick: () => handleMoveClick('Move to start') },
    { iconId: 'arrow.left', label: 'Move up', onClick: () => { handleMoveClick('Move up') } },
    { iconId: 'arrow.right', label: 'Move down', onClick: () => { handleMoveClick('Move down') } },
    { iconId: 'arrow.right', label: 'Move to end', onClick: () => { handleMoveClick('Move to end') } },
    { iconId: 'delete.text', label: 'Remove stop', onClick: handleRemoveClick }
  ]

  const filterStopOptionsForIndex = (options, currentInputs, index) => {
    if (currentInputs.length === 3 && index === 1) { // its in the middle of a list of only three options
      return remove(1, 2, options)
    }
    if (index === 0) { // its at the top of the list
      return remove(0, 2, options)
    }
    if (index === 1) { // its second to the top of the list
      return remove(1, 1, options)
    }
    if (index === currentInputs.length - 2) { // its second to the bottom of the list
      return remove(2, 1, options)
    }
    if (index === currentInputs.length - 1) { // its at the bottom of the list
      return remove(2, 2, options)
    }
    return options // its in the middle of the list
  }

  const filteredOptions = filterStopOptionsForIndex(menuItems, searchInputs, index)

  return (

    <ItemWrapper ref={setNodeRef} style={style} role={'group'} aria-label={placeholder} >
      <InputWrapper
        data-cy={dataCy}
        className={className}
        $first={first}
        $last={last}
      >
        <input
          ref={inputRef}
          {...rest}
          value={term}
          onChange={handleUpdate}
          onKeyDown={handleKey}
          placeholder={placeholder}
          aria-label={T('')}
          aria-autocomplete="list"
          aria-haspopup={false}
          aria-controls='SuggestedLocations'
        />
        <ClosePositionHolder>

          {hasClearButton && term.length
            ? <IconWrapper
              role={'button'}
              className='closeButton'
              aria-label={T('Clear field')}
              onClick={clearClick}
              term={term}
              data-cy='ClearButton'
              tabIndex={0}
              onKeyDown={handleKeyboardSelect(() => clearClick())}>
              <ColoredIcon id="delete-text" fillColor={theme?.colors?.secondaryText} width={32} height={32} />
            </IconWrapper>
            : null}
        </ClosePositionHolder>
        {draggable &&
          <DraggableDiv
            data-cy='DraggableDiv'
            aria-label={T(`Move field`)}
            {...attributes}
            {...listeners}
          >
            <DraggableIcon
              id="drag-2"
              fillColor={theme?.colors?.secondaryText
              }
              width={32}
              height={32} />
          </DraggableDiv>
        }

      </InputWrapper>
      {clickableItemOptions &&
        <>
          <Spacer />
          <DraggableOptions menuItems={filteredOptions} isMobile={isMobile} T={T} />
        </>
      }
    </ItemWrapper>
  )
}

SearchInputLabelledMulti.propTypes = {
  autoFocus: PropTypes.bool,
  id: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onInputClearButtonClick: PropTypes.func,
  onInputSelect: PropTypes.func,
  onInputChange: PropTypes.func.isRequired
}

SearchInputLabelledMulti.defaultProps = {
  autoFocus: false
}

export default withTheme(SearchInputLabelledMulti)
