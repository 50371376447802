import { PoiFeatures, GetDirectionsButton, PoiImages, PoiDescription, PoiTags, PoiInformation, PoiAmenities, CTAButtons, OtherSecurityLanes, PersonalInfo, OpenClosedStatus, PoiFeedback } from './components/index.js'

// Perahps custom sections should always start with custom_ or something. :-)
const sectionMap = {
  openClosedStatus: OpenClosedStatus,
  personalInfo: PersonalInfo,
  features: PoiFeatures,
  getDirections: GetDirectionsButton,
  gallery: PoiImages,
  CTA: CTAButtons,
  description: PoiDescription,
  amenities: PoiAmenities,
  tags: PoiTags,
  information: PoiInformation,
  poiFeedback: PoiFeedback,
  security: OtherSecurityLanes
}

export const defineSection = (name, widget) => { sectionMap.name = widget }

export const getSectionWidget = name => sectionMap[name]
