const DIRECTIONS_RESULT_VIEW_ID_DESKTOP = 'DirectionsResultDesktop'
const DIRECTIONS_RESULT_VIEW_ID_MOBILE = 'DirectionsResultMobile'
const DIRECTIONS_RESULT_CONTROLS_ID = 'DirectionsResultControls'
const DIRECTIONS_SEARCH_CONTROLS_ID = 'DirectionsSearchControls'
const DIRECTIONS_SEARCH_VIEW_ID = 'DirectionsSearchView'
const SHOW_DIRECTIONS_FROM_TO_CALLBACK_ID = 'showDirectionsFromToCallback'
const LANE_PICKER_VIEW_ID = 'LanePickerView'

const MAX_UI_RESULTS_LENGTH = 50
const ANIMATION_TIME = 0.5
const ANIMATION_DELAY = 2
const DEBOUNCE_TIME = 300
const FROM_SEARCH_FIELD_ID = 0
const TO_SEARCH_FIELD_ID = 1
const MAX_SEARCH_INPUT_LENGTH = 7

const DISPLAY_UNITS_METERS = 'meters'
const DISPLAY_UNITS_YARDS = 'yards'

// Should probably be called "AccessibilityOptions"
const RouteType = {
  DIRECT: 'direct',
  ACCESSIBLE: 'accessible'
}

const RouteOptionsClassification = {
  NO_ACCESSIBLE_ROUTES: 'noAccessibleRoute',
  DEFAULT_IS_ACCESSIBLE: 'defaultRouteAccessible',
  DISTINCT: 'distinct'
}

export {
  ANIMATION_DELAY,
  ANIMATION_TIME,
  DEBOUNCE_TIME,
  DIRECTIONS_RESULT_CONTROLS_ID,
  DIRECTIONS_RESULT_VIEW_ID_DESKTOP,
  DIRECTIONS_RESULT_VIEW_ID_MOBILE,
  DIRECTIONS_SEARCH_CONTROLS_ID,
  DIRECTIONS_SEARCH_VIEW_ID,
  DISPLAY_UNITS_METERS,
  DISPLAY_UNITS_YARDS,
  FROM_SEARCH_FIELD_ID,
  LANE_PICKER_VIEW_ID,
  MAX_SEARCH_INPUT_LENGTH,
  MAX_UI_RESULTS_LENGTH,
  RouteType,
  RouteOptionsClassification,
  SHOW_DIRECTIONS_FROM_TO_CALLBACK_ID,
  TO_SEARCH_FIELD_ID
}
