import * as R from 'ramda'

import { orphanTest } from '../plugins/wayfinder/src/navGraphDebug.js'

import { $, $$, ad, del } from './utils/dom.js'
import { singleFile } from './utils/funcs.js'

if (typeof window !== 'undefined')
  window.R = R // helps use Rambda in console... probably don't want to do this once we integrate with customer content

// NOTE:
// The exported functions from this module all get placed into the app.debug (accessable from console via _app.debug) and bound to
// the app instance. So the `this` context will contain the app instance when these are run. This allows you to access objects like
// the bus via `this.bus` or configuration via `this.config`
// Update: these are now also available in MoL by searching for `debug:<funcname>` - such as `debug:showIcons`

const showIcons = () => { $('#mapRenderDiv').innerHTML = '<style> div { display: inline-block; text-align: center; border: 1px solid lightblue; }</style>' + $$('svg symbol').map(e => `<div><svg><use xlink:href="#${e.id}"/></svg><br/>${e.id}</div>`).join('') }

function poisByCategory () {
  return this.bus.send('poi/getAll')
    .then(r => r[0])
    .then(p => Object.values(p))
    .then(R.groupBy(o => o.category))
}

const highlightNodes = () => ad({ tag: 'style', html: '* { background-color: rgba(255,0,0,.2); } * * { background-color: rgba(0,255,0,.2); } * * * { background-color: rgba(0,0,255,.2); } * * * * { background-color: rgba(255,0,255,.2); } * * * * * { background-color: rgba(0,255,255,.2); } * * * * * * { background-color: rgba(255,255,0,.2); } * * * * * * * { background-color: rgba(255,0,0,.2); } * * * * * * * * { background-color: rgba(0,255,0,.2); } * * * * * * * * * { background-color: rgba(0,0,255,.2); }' }, $('head'))

function getPoiById (id) {
  return this.bus.send('poi/getById', { id })
    .then(r => r[0])
}

async function showOrphaned () {
  const navGraph = await this.bus.get('wayfinder/_getNavGraph')
  const oob = orphanTest(navGraph._nodes)
  this.bus.send('map/showOrphanedGraphNodes', { orphanedNodes: oob.orphaned })
}

async function showgraph () {
  const navGraph = await this.bus.get('wayfinder/getNavGraphFeatures')
  this.bus.send('map/showNavGraphFeatures', { navGraph })
  monitorDebugFeaturesReset('map/resetNavGraphFeatures', this.bus)
}

async function bounds () {
  const venueCenter = await this.bus.get('venueData/getVenueCenter')
  const { venueRadius, bounds } = await this.bus.get('venueData/getVenueData')
  this.bus.send('map/showVenueBounds', { venueCenter, venueRadius, bounds })
  monitorDebugFeaturesReset('map/resetVenueBounds', this.bus)
}

async function buildingBounds (nameFilter) {
  this.bus.send('map/showBuildingBounds', { nameFilter })
  monitorDebugFeaturesReset('map/resetVenueBounds', this.bus)
  showCenter.apply(this)
}

async function floorBounds (nameFilter) {
  const bus = this.bus
  bus.send('map/showFloorBounds', { nameFilter })

  monitorDebugFeaturesReset('map/resetVenueBounds', bus)

  const unsubFloorChanges = bus.monitor('map/floorChanged', () =>
    bus.send('map/showFloorBounds', { nameFilter }))
  onNextSearchClear(unsubFloorChanges, bus)
  showCenter.apply(this)
}

async function padding () {
  this.bus.send('map/togglePadding')
  monitorDebugFeaturesReset('map/togglePadding', this.bus)
}

const monitorDebugFeaturesReset = (resetEventName, bus) =>
  onNextSearchClear(() => bus.send(resetEventName), bus)

const onNextSearchClear = (fn, bus) => {
  const unsubscribe = bus.monitor('homeview/performSearch', ({ term }) => {
    if (!term) {
      fn()
      unsubscribe()
    }
  })
}

async function showCenter () {
  const padding = await this.bus.get('map/getPadding')
  const leftAdj = (padding.left - padding.right) / 2 // how much to adjust left from true center to account for padding
  const topAdj = (padding.top - padding.bottom) / 2 // how much to adjust top from true center to account for padding
  const crosshair = ad({ html: `<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg"><path stroke="#fF000080" stroke-width="2" d="M0 50h45M55 50h45M50 0v45M50 55v45"/></svg>`, styles: { position: 'absolute', left: '50%', top: '50%', 'margin-left': (-50 + leftAdj) + 'px', 'margin-top': (-50 + topAdj) + 'px' } }, $('.maplibregl-map'))
  onNextSearchClear(() => del(crosshair), this.bus)
}

function dndGo () {
  const bus = this.bus
  const toggleActive = (e, indicateDragVisual) => {
    e.preventDefault()
    if (indicateDragVisual)
      e.target.classList.add('dragover')
    else
      e.target.classList.remove('dragover')
  }

  const handlers = {

    drop: async function (e) {
      toggleActive(e, false)

      // Load the file into the player
      for (const file of e.dataTransfer.files) {
        const filename = file.name
        if (file.type === 'application/json' || (file.type.startsWith('text/'))) {
          const reader = new FileReader()
          reader.onload = singleFile(async e =>
            bus.send(`debugTools/fileDrop`, { file, filename, content: e.target.result }))
          reader.readAsText(file)
        }
      }
    },

    // Drag-over event
    dragover: function (e) { toggleActive(e, true) },

    // Drag-leave event
    dragleave: function (e) { toggleActive(e, false) }

  } // end of handlers def

  Object.keys(handlers).forEach(function (event) {
    document.body.addEventListener(event, handlers[event])
  })

  console.log('DnD Listeners installed')
} // end of function installDragNDrop

export default {
  bounds,
  buildingBounds,
  dndGo,
  floorBounds,
  getPoiById,
  highlightNodes,
  orphanTest,
  padding,
  poisByCategory,
  showCenter,
  showgraph,
  showIcons,
  showOrphaned
}
