import BuildingLevelSelector from '@locus-labs/mol-desktop-building-level-selector'
import PropTypes from 'prop-types'
import {
  complement,
  pathEq,
  map,
  groupBy,
  prop as rProp,
  pipe
} from 'ramda'
import React, { useState, useEffect, useCallback } from 'react'
import { withTheme } from 'styled-components'

import { ColoredIcon } from '../../../../src/ui/icons/Icon.jsx'

import {
  Wrapper,
  ToggleShowMenuButtonMobile,
  OtherResultsIndicatorMobile
} from './styles.js'

const MapLevelSelectorOnline = withTheme(({ widgetState, bus, theme, T, isMobile }) => {
  const [state, setState] = useState(widgetState.getState())

  useEffect(() => widgetState.addCallback(setState))

  useEffect(() => setState(widgetState.getState()), [])

  const onBuildingClick = (buildingId) => {
    bus.send('mapLevelSelector/selectBuilding', { id: buildingId })
  }

  const onLevelClick = (levelId) => {
    bus.send('mapLevelSelector/selectLevel', { id: levelId, centerMap: true })
  }

  const areResultsOnOtherFloorsOrBuildings = useCallback(({ selectedBuilding, selectedLevel, searchResults }) => {
    if (!(selectedLevel || selectedBuilding)) return Boolean(searchResults && searchResults.length)
    const anyDifferent = (path, value) => searchResults.some(complement(pathEq(value, path)))
    if (selectedLevel) return anyDifferent(['position', 'floorId'], selectedLevel.id)
    if (selectedBuilding) return anyDifferent(['position', 'buildingId'], selectedBuilding.id)
  })

  const getBuildingsSearchResults = pipe(
    map(rProp('position')),
    groupBy(rProp('buildingId'))
  )

  const { isMenuOpen, buildings, selectedBuilding, selectedLevel } = state

  const buildingsSearchResults = getBuildingsSearchResults(state.searchResults)
  const getLevelsSearchResults = groupBy(rProp('floorId'))

  const getLevelsWithSearchResults = (levels, buildingSearchResults) => {
    const levelsSearchResults = getLevelsSearchResults(buildingSearchResults)

    return levels.map(level => {
      const levelSearchResults = levelsSearchResults[level.id]
      if (!levelSearchResults) return level

      return { ...level, resultsNumber: levelSearchResults.length }
    })
  }
  const buildingsWithSearchResults = buildings && buildings.map(building => {
    const buildingSearchResults = buildingsSearchResults[building.id]
    if (!buildingSearchResults) return building
    return { ...building, resultsNumber: buildingSearchResults.length, levels: getLevelsWithSearchResults(building.levels, buildingSearchResults) }
  })

  return (
    <Wrapper className={isMenuOpen ? 'open' : 'closed'}>
      {isMobile
        ? (
          <ToggleShowMenuButtonMobile
            onClick={() =>
              bus.send('mapLevelSelectorOnline/openLevelSelector', {
                isOpen: !isMenuOpen
              })
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault()
                bus.send('mapLevelSelectorOnline/openLevelSelector', {
                  isOpen: !isMenuOpen
                })
              }
            }}
            title="Toggle Level Selector Menu"
            data-cy="MapLevelSelectorMobileButton"
          >
            {areResultsOnOtherFloorsOrBuildings(state)
              ? (
                <OtherResultsIndicatorMobile data-cy="otherResultsIndicator" />
              )
              : null}
            <ColoredIcon
              id="levels"
              width={32}
              height={32}
              fillColor={theme.colors.primaryText}
            />
          </ToggleShowMenuButtonMobile>
        )
        : (
          <BuildingLevelSelector
            data-cy="MapLevelSelector"
            onBuildingClick={onBuildingClick}
            onLevelClick={onLevelClick}
            fallbackLevelName={T('mapLevelSelectorOnline:...')}
            fallbackBuildingName={
              T('mapLevelSelectorOnline:Select a Building') + '…'
            }
            selectedBuildingId={selectedBuilding && selectedBuilding.id}
            currentBuildingName={selectedBuilding && selectedBuilding.name}
            selectedLevelId={selectedLevel && selectedLevel.id}
            currentLevelName={selectedLevel && selectedLevel.name}
            tooltipContent={T('mapLevelSelectorOnline:Show Buildings & Levels')}
            aria-label={T('mapLevelSelectorOnline:Show Buildings & Levels')}
            buildings={buildingsWithSearchResults || buildings}
            isExpanded={isMenuOpen}
            onTriggerClick={() =>
              bus.send('mapLevelSelectorOnline/openLevelSelector', {
                isOpen: !isMenuOpen
              })
            }
            withSearchResults={areResultsOnOtherFloorsOrBuildings(state)}
          />
        )}
    </Wrapper>
  )
})

MapLevelSelectorOnline.propTypes = {
  bus: PropTypes.shape({
    on: PropTypes.func.isRequired,
    send: PropTypes.func.isRequired
  })
}

export default MapLevelSelectorOnline
