function pad (total, str) {
  while (str.length < total) { str += ' ' }
  return str
}

export const getHelpHeader = () => pad(18, 'Command') + 'Arguments\n' + pad(18, '----------------') + '----------------\n'

/**
 *  Returns help on the passed command signature
 */
export const getHelp = sig => pad(18, sig.command) +
  (sig.args ? sig.args.map(argob => `${argob.name} {${argob.type}} ${argob.optional ? ' (optional)' : ' (required)'}`).join(', ') : '')

const sigsort = (s1, s2) => s1.command > s2.command ? 1 : -1
/**
 *  Returns help on the passed command signatures
 */
export const getHelpList = sigList => {
  return sigList.sort(sigsort).reduce((ret, sig) => `${ret}${getHelp(sig)}\n`, getHelpHeader())
}
