import { getStructureForFloorId, getFloor, getStructureAndFloorAtPoint } from './geom.js'

/**
 * Attempts to grab the user's location (bluedot, kiosk, etc.) and if none found, then
 * falls back on the map center.
 * @param {Object} bus bus for querying for location
 * @returns {Object} location ({lat, lng, floorId, ordinal, structureId }) (floorId and structureId can be null)
 */
async function getLocation (bus) {
  const location = await bus.getFirst('user/getPhysicalLocation')

  return location || bus.get('map/getMapCenter')
}

/**
 * Allows for flexibility in the manner in which you specify a location. This may call to getVenueData, so you should not
 * call this function until you are in the init stage of your plugin, to ensure the venueDataLoader plugin has had a chance
 * to register its getVenueData listener.
 *
 * @param {Object} app The app object
 * @param {Object} location A location in the map, which can be a {poid} or {lat,lng,ord} or {lat,lng,ordinal} or {lat,lng,floorId}
 * @returns an Endpoint { lat, lng, ordinal, floorId, title }
 */
async function locationToEndpoint (app, location) {
  if (location.poiId)
    return app.bus.get('wayfinder/getNavigationEndpoint', { ep: location.poiId })

  let { lat, lng, ord, ordinal, floorId, title = '', structureId } = location

  if (lat == null || lng == null)
    throw Error('To obtain a location, you must provide a lat,lng or a poiId')

  if (ordinal === undefined && ord !== undefined)
    ordinal = ord // normalize on "ordinal"

  // Now lets normalize the return structure - determine ordinal, floorId and structureId
  const structures = await getStructures(app)

  if (ordinal == null) {
    if (floorId == null) // if neither ordinal nor floorId is defined bad dog!
      throw Error('Call to locationToEndpoint with no ordinal and no floorId')
    else {
      const floor = getFloor(structures, floorId)
      if (!floor)
        throw Error(`floor with id ${floorId} not found.`)
      ordinal = floor.ordinal
    }
  } else {
    if (floorId == null) {
      const mapviewBBox = await app.bus.get('map/getViewBBox')
      const { structure, floor } = getStructureAndFloorAtPoint(structures, lat, lng, ordinal, mapviewBBox, true)
      floorId = floor?.id
      structureId = structure?.id
    }
  }

  if (floorId != null && structureId == null)
    structureId = getStructureForFloorId(structures, floorId)?.id

  return { lat, lng, floorId, ordinal, title, structureId }
}

const getStructures = async app => app.bus.get('venueData/getVenueData').then(vd => vd.structures)

export {
  getLocation,
  getStructures,
  locationToEndpoint
}
