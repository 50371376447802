import PropTypes from 'prop-types'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import { Button } from '../../../../src/ui/buttons/Button.jsx'
import { ColoredIcon, Icon } from '../../../../src/ui/icons/Icon.jsx'

const NavBarMobile = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  flex: 0 0 auto;
  min-width: 0;
  min-height: 0;
  overflow: hidden;

  button {
    padding: 0;
    margin: 0 0 0 10px;
  }

  .userMenu {
    width: 40px;
    height: 40px;
    padding: 0;
    color: ${({ fillColor }) => fillColor};
  }
`

const SearchModeMobile = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .arrowBack {
    margin: 0 16px 0 6px;
  }

  input {
    width: 100%;
    outline: none;
    border: none;
    height: 40px;
    font-size: 16px;
    line-height: 150%;
    padding: 0 9px;
    opacity: 0.1;
    border-radius: 2px;

    &::placeholder {
      color: #FFFFFF;
    }
  }
`

const StyledButton = styled(Button)`
  &:focus-visible {
    outline: 1px solid orange;
    outline-offset: -1px;
  }
`

const SearchNavMobile = withTheme(({
  searchPlaceholder,
  logoUrl,
  onToggleSearchButtonClick,
  onOpenDirectionsSearchButtonClick,
  theme
}) => {
  if (logoUrl) {
    return (
      <NavBarMobile fillColor={theme.colors.widgetIconFill}>
        <StyledButton
          className="search"
          onClick={onToggleSearchButtonClick}
          title="Search"
          data-cy="searchButton">
          <ColoredIcon id="search" height={40} width={40} fillColor={theme.colors.widgetIconFill} />
        </StyledButton>
        <StyledButton className="directions" onClick={onOpenDirectionsSearchButtonClick} title="Directions" data-cy='OpenDirectionsButton'>
          <ColoredIcon id="directions-filled" height={40} width={40} fillColor={theme.colors.widgetIconFill} />
        </StyledButton>
      </NavBarMobile>
    )
  }

  return (
    <SearchModeMobile>
      <div className="arrowBack">
        <Icon id="arrow-left" height={32} width={32} />
      </div>
      <input value="" readOnly placeholder={searchPlaceholder} />
    </SearchModeMobile>
  )
})

SearchNavMobile.propTypes = {
  searchPlaceholder: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  onToggleSearchButtonClick: PropTypes.func.isRequired,
  onOpenDirectionsSearchButtonClick: PropTypes.func.isRequired,
  onUserMenuButtonClick: PropTypes.func.isRequired
}

export default SearchNavMobile
