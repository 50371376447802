import { pathEq } from 'ramda'
import React, { useState, useEffect } from 'react'

import { runOnce } from '../../../../src/utils/funcs.js'
import { StateObjMultiple } from '../../../../src/utils/stateObj.js'
import { widgetEnum } from '../../../../utils/constants.js'
import { POI_VIEW_ID_DESKTOP, POI_VIEW_ID_MOBILE } from '../src/constants.js'

import HandlerContext from './handlerContext.js'
import PoiViewWidgetDesktop from './poiViewWidgetDesktop.js'
import PoiViewWidgetMobile from './poiViewWidgetMobile.jsx'

const PoiView = ({ bus, widgetState, isDesktop, T, lang }) => {
  const [state, setState] = useState(widgetState.getState())

  useEffect(() => widgetState.addCallback(setState))

  const submitAnalyticsEvent = (poi, actionType, actionValue) => bus.send('events/externalAction', { poi, actionType, actionValue })
  const submitAnalyticsEventWithAppInsights = (poi, actionType, actionValue) => {
    submitAnalyticsEvent(poi, actionType, actionValue)
    bus.send('appInsights/log', { name: 'poi information link click', properties: { poiId: poi.poiId, actionValue } })
  }

  const { poi, layout, venueCategory, venueTimezone, structures, searchInputsLength, hidePoiFeedback, venueId } = state

  return isDesktop()
    ? <PoiViewWidgetDesktop poi={poi} structures={structures} layout={layout} T={T} venueCategory={venueCategory} venueTimezone={venueTimezone} lang={lang} submitAnalyticsEvent={submitAnalyticsEvent} submitAnalyticsEventWithAppInsights={submitAnalyticsEventWithAppInsights} searchInputsLength={searchInputsLength} hidePoiFeedback={hidePoiFeedback} venueId={venueId} />
    : <PoiViewWidgetMobile id={POI_VIEW_ID_MOBILE} poi={poi} structures={structures} layout={layout} T={T} bus={bus} venueCategory={venueCategory} venueTimezone={venueTimezone} lang={lang} submitAnalyticsEvent={submitAnalyticsEvent} submitAnalyticsEventWithAppInsights={submitAnalyticsEventWithAppInsights} searchInputsLength={searchInputsLength} hidePoiFeedback={hidePoiFeedback} venueId={venueId} />
}

const testableCreate = (widgetState) => async function create (app, config) {
  const init = async () => {
    app.bus.get('venueData/getVenueCategory').then(venueCategory => widgetState.update({ venueCategory }))
    app.bus.get('venueData/getVenueTimezone').then(venueTimezone => widgetState.update({ venueTimezone }))

    const dlp = config.deepLinkProps

    if (dlp) {
      app.bus.on('map/mapReadyToShow', runOnce(() => {
        if (dlp?.poiId) showPoi(dlp.poiId)
      }))
    }

    const venueId = await app.bus.get('venueData/getVenueId')
    widgetState.update({ venueId })
    if (config.hidePoiFeedback) {
      widgetState.update({ hidePoiFeedback: config.hidePoiFeedback })
    }
  }

  const initialState = {
    poi: null,
    layout: [],
    searchInputsLength: 0
  }

  widgetState.update(initialState)

  const shouldSuppressPOIView = () => app.env.isMobile() && app.config.uiHide && app.config.uiHide.mobileHidePOIView

  const handlers = {
    onGetDirectionsClicked: () => {
      // only keep existing stops if we are adding stops, not if we are initiating a new directions session
      const keepExistingStops = widgetState.getState().searchInputsLength > 1
      app.bus.send('poiDetails/navigateToPoi', { keepExistingStops })
    },
    onCloseClicked: closePoiView,
    onTagClicked: tag => {
      app.bus.send('layers/hideMultiple', [POI_VIEW_ID_DESKTOP, POI_VIEW_ID_MOBILE])
      app.bus.send('searchResults/searchForTag', ({ tag }))
    },
    onSecurityLanePoiClick: poiId => app.bus.send('poiDetails/showPoi', { poi: poiId, info: { referrer: 'entityView' } }),
    submitPoiFeedback: (accurate, poi, venueId) => app.bus.send('appInsights/log', { name: `poi information accuracy click`, properties: { accurate, poiAccuratePoiId: poi.poiId, poiAccurateVenueId: venueId, poiAccurateCategory: poi.category } })
  }

  const widget = () =>
    <HandlerContext.Provider value={handlers}>
      <PoiView
        bus={app.bus}
        widgetState={widgetState}
        T={app.gt()}
        isDesktop={app.env.isDesktop}

        lang={app.i18n().language} />
    </HandlerContext.Provider>

  app.bus.send('layers/register', {
    id: POI_VIEW_ID_DESKTOP,
    widget,
    layoutId: 'content',
    layoutName: 'fullscreen',
    widgetType: widgetEnum.Desktop
  })

  app.bus.send('layers/register', {
    id: POI_VIEW_ID_MOBILE,
    widget,
    layoutId: 'bottomBar',
    layoutName: 'fullscreen',
    widgetType: widgetEnum.Mobile
  })

  app.bus.on('mapLevelSelector/selectBuilding', async () => {
    if (!(await isPoiViewVisible())) return
    if (widgetState.getState().poi) closePoiView()
  })

  app.bus.on('mapLevelSelector/selectLevel', async ({ id }) => {
    if (!(await isPoiViewVisible())) return
    if (!pathEq(id, ['poi', 'position', 'floorId'], widgetState.getState()))
      closePoiView()
  })

  async function isPoiViewVisible () {
    return app.bus.send('layers/isShowing', { id: POI_VIEW_ID_DESKTOP })
      .then(res => res.length > 0 && res[0]) || app.bus.send('layers/isShowing', { id: POI_VIEW_ID_MOBILE }).then(res => res.length > 0 && res[0])
  }

  function closePoiView () {
    app.bus.send('history/stepBack')
    app.bus.send('layers/hideMultiple', [POI_VIEW_ID_DESKTOP, POI_VIEW_ID_MOBILE])
    app.bus.send('map/cleanMap')
    widgetState.update({ ...initialState })
  }

  const notifyState = () => {
    const { poi } = widgetState.getState()
    const sendState = {
      id: 'online/poiView'
    }
    if (poi && poi?.poiId) sendState.poiId = poi.poiId
    app.bus.send('deepLinking/notifyState', sendState)
  }
  widgetState.addCallback(notifyState)

  app.bus.on('deepLinking/setState', stateOb => {
    if (stateOb.id === 'online/poiView') {
      if (stateOb.poiId)
        showPoi(stateOb.poiId)
      else
        widgetState.update({ poi: null })
    }
  })

  const showPoi = async (poi, info = {}) => {
    widgetState.update({ ...initialState, shouldAnimate: app.env.isMobile() }) // reset previous poi data, ensure POI will be animated to on mobile env
    const poiId = typeof poi !== 'object'
      ? poi
      : poi.poiId

    poi = await app.bus.get('poi/getById', { id: poiId })
    const searchInputsLength = await app.bus.get('navigation/getSearchInputsLength')

    if (app.debug)
      window._poi = poi

    poi = await app.bus.get('poi/addOtherSecurityLanes', { poi })

    const structures = await app.bus.get('venueData/getStructures')

    const layoutName = Object.keys(config.layouts).find(key => poi.category === key || poi.category.startsWith(key + '.'))
    const layout = layoutName ? config.layouts[layoutName] : config.layouts.default
    widgetState.update({ poi, layout, structures, searchInputsLength })

    app.bus.send('bluedot/stopFollowing')

    app.bus.send('map/selectEntities', { ids: [poi.poiId] })

    if (!shouldSuppressPOIView()) {
      app.bus.send('layers/show', { id: POI_VIEW_ID_DESKTOP })
      app.bus.send('layers/show', { id: POI_VIEW_ID_MOBILE })
      app.bus.send('layers/hideWithLayoutId', 'headerOnline')
      if (!app.env.isDesktop()) app.bus.send('layers/hideWithLayoutId', 'content')
      app.bus.send('history/register', { viewId: app.env.isMobile() ? POI_VIEW_ID_MOBILE : POI_VIEW_ID_DESKTOP, event: 'poiDetails/showPoi', params: { poi, info } })
    }

    // If app is not mobile, animate to POI, else wait for POI view to report its height
    if (!app.env.isMobile() || shouldSuppressPOIView()) {
      const { latitude, longitude, floorId } = poi.position
      app.bus.send('map/animateToPointWithRadius', { lat: latitude, lng: longitude, floorId, radius: poi.zoomRadius })
    }
  }

  app.bus.on('poiDetails/showPoi', ({ poi, info }) => showPoi(poi, info))

  app.bus.monitor('poi/setDynamicData', async ({ idValuesMap }) => {
    const { poi } = widgetState.getState()
    if (poi && Object.hasOwnProperty.call(idValuesMap, poi.poiId)) {
      const updatedPoi = await app.bus.get('poi/getById', { id: poi.poiId })
      // showPoi(updatedPoi)
      widgetState.update({ poi: updatedPoi })
    }
  })

  app.bus.on('poiDetails/navigateToPoi', ({ keepExistingStops }) => {
    app.bus.send('layers/hideMultiple', [POI_VIEW_ID_DESKTOP, POI_VIEW_ID_MOBILE])
    app.bus.send('navigation/searchDirectionsToId', { poiId: widgetState.getState().poi.poiId, referrer: 'entityView', keepExistingStops })
  })

  app.bus.on('map/poiClicked', async ({ poi }) => {
    const isNavigationSearchView = await app.bus.send('layers/isShowing', { id: 'DirectionsSearchControls' })
      .then(res => res.length > 0 && res[0])
    if (!isNavigationSearchView)
      app.bus.send('poiDetails/showPoi', { poi, info: { referrer: 'map' } })
  })

  app.bus.on('expandableContent/reportHeight', ({ height }) => {
    if (widgetState.getState().shouldAnimate) {
      const poi = widgetState.getState().poi
      const { latitude, longitude, floorId } = poi.position

      // Commenting out the following code, as it appears to be assuming that the
      // map gets OVERLAID by the expandable content - but that is not (no longer)
      // the case, so no need for a buttom padding - just continue centering.

      //   let mapPadding = height
      //   // const screenHeight = document.getElementById('mapRenderDiv').clientHeight
      //   const screenHeight = $('.atrius-map').clientHeight

      //   // If POI view is higher than half of the screen, assume it will render as only 50% of the screen height and set padding as half of the screen
      //   if (mapPadding > screenHeight / 2) {
      //     mapPadding = Math.floor(screenHeight / 2)
      //   }

      app.bus.send('map/animateToPointWithRadius', { lat: latitude, lng: longitude, floorId, radius: poi.zoomRadius, padding: { bottom: 0 } })
      widgetState.update({ shouldAnimate: false }) // ensure padding gets added only once - when new POI is displayed
    }
  })

  return {
    init,
    widgetState: () => widgetState,
    internal: {
      handlers,
      notifyState,
      isPoiViewVisible,
      closePoiView,
      showPoi
    }
  }
}

const create = (app, config) => {
  const widgetState = new StateObjMultiple()
  const createFn = testableCreate(widgetState)(app, config)
  return createFn
}

export { create, testableCreate }
