import React, { useState, useEffect } from 'react'
import { ReactSVG } from 'react-svg'
import styled, { withTheme } from 'styled-components'

const BaseIcon = withTheme(({ width, height, id, theme, title, desc, role = 'img', className = '', removeFill = false, onClick, ...props }) => {
  const [baseUrl, setBaseUrl] = useState(null)
  useEffect(() => {
    setBaseUrl(theme.getBaseUIUrl())
  }, [])

  // Convert aria-label to title as ReactSVG doesn't support aria-label and also because this is the preferred pattern
  // for SVG. See https://www.smashingmagazine.com/2021/05/accessible-svg-patterns-comparison/ PATTERN #5
  if (props['aria-label'] && !title)
    title = props['aria-label']

  // If we don't have a11y name for this icon, hide from screen readers...
  if (!title)
    props['aria-hidden'] = true

  return baseUrl
    ? <ReactSVG
      data-cy='Icon'
      className={className}
      src={`${baseUrl}${id}.svg`}
      width={width} height={height}
      onClick={onClick}
      wrapper='span'
      title={title}
      desc={desc}
      {...props}
      beforeInjection={svg => {
        if (removeFill) {
          svg.removeAttribute('fill')
          const children = [...svg.querySelectorAll('*')]
          children.forEach(child => child.removeAttribute('fill'))
        }
      }}
    />
    : null
})

const Icon = styled(BaseIcon)`
  span {
    display: inline-block;
    vertical-align: middle;
    min-width: ${({ width }) => `${width}px`};
    width: ${({ width }) => `${width}px`};
    min-height: ${({ height }) => `${height}px`};
    height: ${({ height }) => `${height}px`};
  }
  svg {
    min-width: ${({ width }) => `${width}px`};
    width: ${({ width }) => `${width}px`};
    min-height: ${({ height }) => `${height}px`};
    height: ${({ height }) => `${height}px`};
  }
`

const BaseColoredIcon = (props) => {
  return <Icon removeFill={true} {...props} />
}

// the following notation strips "fillColor" from the properties used in render of BaseColoredIcon as intended
const ColoredIcon = styled(({ fillColor, ...props }) => <BaseColoredIcon {...props} />)`
  fill: ${({ fillColor }) => fillColor};
`

const ShadowedIcon = styled(Icon)`
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15));
`

export { Icon, ColoredIcon, ShadowedIcon }
