import React from 'react'
import { withTheme } from 'styled-components'

import { ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'

import { WarningTheme } from './styles.js'

const LeavingSecurityWarning = ({ theme, T }) => {
  const leavingSecurityWarning = <WarningTheme data-cy='LeavingSecurityWarning' color={theme.colors.statusWarningBackground}>
    <ColoredIcon className="icon" width={24} height={24} id="triangle-warning" fillColor={theme.colors.widgetIconFill} />
    {T('getDirectionsFromTo:This route exits security')}
  </WarningTheme>
  return leavingSecurityWarning
}

export default withTheme(LeavingSecurityWarning)
