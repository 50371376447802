import Zousan from 'zousan-plus'

import localStorage from './localStorage.js'

export function initEventHandler (bus, config, log) {
  const configFinal = {}
  let current = null
  const options = {
    sendNow: false,
    maxBatchSize: 120,
    maxBatchAge: 10000,
    initialBackoff: 100,
    maxBackoff: 1000 * 60 * 60,
    maxPendingSize: 1024 * 1000
  }
  let pending = null

  setInterval(function () {
    checkCurrent()
  }, options.maxBatchAge)

  bus.on('event/submitEvent', ({ event, venueId, accountId }) => {
    configFinal.accountId = accountId

    event.timestamp = new Date().toISOString()
    event.venueId = venueId

    getAccountGUIDFromREST(accountId).then(accountId => {
      event.accountId = accountId
      loadInstallId(accountId, config.platformSdk).then(async installId => {
        event.installId = installId
        await addEvent(event)
        checkCurrent()
        // return app.bus.send('event/sendEvent', {data: data.event});
      })
    })
  })

  bus.on('events/getInstallId', () =>
    bus.get('venueData/getAccountId')
      .then(accountId => loadInstallId(accountId, config.platformSdk)))

  async function addEvent (event) {
    if (!current) {
      current = []
    }

    log.info(event)
    if (config.validateEvents)
      if (!await validateEvent(event))
        return // don't send invalid events
    current.push(event)
  }

  async function validateEvent (event) {
    const { validator, EVENT_TYPES } = await getValidationResources()
    const isKnown = event !== null && typeof event === 'object' && EVENT_TYPES[event.type]
    if (!isKnown) {
      log.error('Analytics event validation failed - unknown event type', event)
      return
    }
    const details = validator.validate(event, EVENT_TYPES[event.type])
    if (details.valid)
      return true

    log.error('Analytics event validation failed: ', event, details)
    return false
  }

  // This function loads and provides event validation resources, including a
  // Validator instance from the jsonschema library - loaded up with all schemas
  // defined in the eventTypes.json file - and the EVENT_TYPES array coming from that
  // same file. If this function is never called, these resources are never loaded.
  // This allows production builds to not be burdened by this.
  let validationResourcesPromise = null
  function getValidationResources () {
    if (!validationResourcesPromise)
      validationResourcesPromise = Zousan.evaluate(
        { name: 'jsonSchema', value: import('jsonschema') },
        { name: 'eventTypes', value: import('./eventTypes.json').then(m => m.default) })
        .then(eo => {
          // Validator: eo.jsonSchema.Validator,
          // SCHEMAS: eo.eventTypes.SCHEMAS,
          // EVENT_TYPES: eo.eventTypes.EVENT_TYPES
          const validator = new eo.jsonSchema.Validator()
          eo.eventTypes.SCHEMAS
            .forEach(schema => validator.addSchema(schema))
          return { validator, EVENT_TYPES: eo.eventTypes.EVENT_TYPES }
        })
    return validationResourcesPromise
  }

  function checkCurrent () {
    if (!current) {
      return
    }

    if (options.sendNow || isBatchTooBig(current) || isBatchTooOld(current)) {
      sendNow()
    }
  }

  function sendNow () {
    if (current && current.length) {
      addToPending()
      current = []
    }
  }

  function isBatchTooBig (batch) {
    return batch.length >= options.maxBatchSize
  }

  function isBatchTooOld (batch) {
    if (batch.length === 0) {
      return false
    }

    const now = new Date()
    return (now.getTime() - (new Date(batch[0].timestamp)).getTime()) >= options.maxBatchAge
  }

  function sendRequest (batch, index) {
    const controller = new AbortController()
    const signal = controller.signal
    const url = 'https://api.locuslabs.com/events'
    const params = {
      method: 'POST',
      body: batch.data,
      headers: new Headers({
        Accept: '*/*',
        'Content-Type': 'application/vnd.api+json'
      }),
      signal
    }

    function successful () {
      batch.done = true
      removeFromPending(index)
    }

    function failure () {
      let nextDelay = batch.nextDelay

      if (batch.canceled) {
        return
      }

      batch.timeout = setTimeout(function () {
        batch.request = sendRequest(batch)
      }, nextDelay)

      nextDelay = nextDelay * 2
      if (nextDelay > options.maxBackoff) {
        nextDelay = options.maxBackoff
      }
      batch.nextDelay = nextDelay
    }

    return {
      abort: () => controller.abort(),
      ready: fetch(url, params).then(response => {
        if (response.ok)
          successful()
        else
          failure()
      })
    }
  }

  function addToPending () {
    if (!pending) {
      pending = {
        batches: {},
        dataSize: 0,
        first: 0,
        next: 0
      }
    }

    const data = JSON.stringify(current)

    const batch = {
      nextDelay: options.initialBackoff,
      submitted: new Date(),
      done: false,
      events: current,
      data
    }

    const index = pending.next
    pending.batches[index] = batch
    pending.dataSize += data.length
    pending.next += 1

    batch.request = sendRequest(batch, index)

    while (pending.dataSize >= options.maxPendingSize) {
      removeFromPending(pending.first)
    }
  }

  function cancelBatch (batch) {
    batch.canceled = true

    if (batch.request) {
      batch.request.abort()
    }

    if (batch.timeout) {
      clearTimeout(batch.timeout)
    }
  }

  function removeFromPending (index) {
    if (index < pending.first) {
      return
    }

    if (index >= pending.next) {
      return
    }

    if (!pending.batches[index]) {
      return
    }

    const batch = pending.batches[index]
    cancelBatch(batch)
    pending.dataSize -= batch.data.length
    delete pending.batches[index]

    if (index === pending.first) {
      pending.first += 1
      while (!pending.batches[pending.first] && (pending.first < pending.next)) {
        pending.first += 1
      }
    }
  }

  function retreiveAccountGUID () {
    if (typeof (Storage) === 'undefined') {
      return null
    }

    if (!configFinal.accountId) {
      return null
    }

    return localStorage.getItem('locuslabs.' + configFinal.accountId + '.id')
  }

  function storeAccountGUID (accountGUID) {
    if (typeof (Storage) === 'undefined') {
      return
    }

    if (!configFinal.accountId) {
      return
    }

    localStorage.setItem('locuslabs.' + configFinal.accountId + '.id', accountGUID)
  }

  function getAccountGUIDFromREST (configAccountId) {
    return new Promise((resolve, reject) => {
      if (typeof (Storage) === 'undefined') {
        reject(new Error('invalid storage defined for analytics events'))
      }
      const accountGUID = retreiveAccountGUID()
      if (accountGUID) {
        resolve(accountGUID)
      } else {
        const accountId = configAccountId
        if (!accountId)
          throw Error('Unpsecified Account Id')
        const url = `https://api.accounts.people.atrius.com/accounts?shortId=${accountId}`
        fetch(url)
          .then(response => {
            if (response.ok)
              return response.json()
            else
              reject(new Error())
          })
          .then(data => {
            if (!data)
              reject(new Error('Unable to retreive account data from REST'))
            else if (!data.accounts)
              reject(new Error('missing accounts array in REST data'))
            else if (!data.accounts.length)
              reject(new Error('accounts array empty in REST data'))
            else {
              const accountGUID = data.accounts[0]
              storeAccountGUID(accountGUID)
              resolve(accountGUID)
            }
          })
      }
    })
  }

  function getInstallId () {
    if (typeof (Storage) === 'undefined') {
      return null
    }
    return localStorage.getItem('locuslabs.' + retreiveAccountGUID() + '.install.id')
  }

  function setInstallId (installId) {
    if (typeof (Storage) === 'undefined') {
      return
    }
    localStorage.setItem('locuslabs.' + retreiveAccountGUID() + '.install.id', installId)
  }

  function loadInstallId (accountId, platformSdk) {
    return new Promise((resolve, reject) => {
      if (typeof (Storage) === 'undefined') {
        reject(new Error())
      }

      const installId = getInstallId()

      if (installId) {
        resolve(installId)
      } else {
        bus.get('venueData/getAccountId')
          .then(getAccountGUIDFromREST)
          .then(
            accountId => {
              const data = JSON.stringify({
                installs: {
                  sdk: platformSdk || 'js',
                  links: { account: accountId }
                }
              })

              const url = `https://api.locuslabs.com/installs`
              const opt = {
                method: 'POST',
                body: data,
                headers: new Headers({
                  Accept: '*/*',
                  'Content-Type': 'application/vnd.api+json'
                })
              }

              log.info('Sending installs event', url, opt)
              fetch(url, opt)
                .then(response => {
                  if (response.ok)
                    return response.json()
                  else
                    reject(new Error())
                })
                .then(data => {
                  if (!data)
                    reject(new Error('no data returned'))
                  else if (!data.installs)
                    reject(new Error('missing installs object'))
                  else {
                    const install = data.installs
                    // log.debug('Created install:',install.id)
                    setInstallId(install.id)
                    resolve(install.id)
                  }
                })
            })
      }
    })
  }
}
