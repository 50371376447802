import * as R from 'ramda'

/**
 * Grabs the relevant locations (ie start and end) to calculate transit times and distances for pois
 * @param {Object} bus bus for querying for locations and integrating with wayfinder module
 * @param {Array} pois array of pois objects
 * @param {POI} previousLocation the previous location in the array of waypoints
 * @param {POI} nextLocation the next location in the array of waypoints
 * @param {Location} currentLocation the next location in the array of waypoints
 * */
export async function getPoisWithTransitTimes (bus, pois, previousLocation, nextLocation, currentLocation) {
  if (typeof pois === 'string' || !pois.length) return pois

  return await getMultipointPoisTransitTimes(bus, pois, previousLocation, nextLocation, currentLocation)
}

async function getMultipointPoisTransitTimes (bus, pois, previousLocation, nextLocation, currentLocation) {
  if (!previousLocation && !nextLocation && !currentLocation) return pois
  return await bus.get('wayfinder/multipointAddPathTimeMultiple', ({ pois, startLocation: previousLocation, endLocation: nextLocation, currentLocation }))
}

// Integration with search service  (currently searchForTerm in getDirectionsFromTo)
export async function getPoisByTerm (bus, term, previousLocation, nextLocation, currentLocation) {
  if (term === 'nearby') return await getNearbyPois(bus, previousLocation, nextLocation, currentLocation)
  else return bus.get('search/queryAsync', { term })
}

// replaces the searchNearby() in searchService and removes the call to wayfinder module
export async function getNearbyPois (bus, previousLocation, nextLocation, currentLocation) {
  const poisSameFloor = await getPoisByFloors(bus, previousLocation, nextLocation, currentLocation)
  return filterNearby(poisSameFloor)
}

async function getPoisByFloors (bus, previousLocation, nextLocation, currentLocation) {
  const nextFloorId = nextLocation?.position?.floorId
  const previousFloorId = previousLocation?.position?.floorId
  const currentFloorId = currentLocation?.floorId
  // previous and next locations are not specified, so use the current location
  if (!nextFloorId && !previousFloorId) return await bus.get('poi/getByFloorId', { floorId: currentFloorId })
  // previous and next locations are on different floors, so grab results for both
  if (nextFloorId !== previousFloorId) {
    const list1 = await bus.get('poi/getByFloorId', { floorId: previousFloorId })
    const list2 = await bus.get('poi/getByFloorId', { floorId: nextFloorId })
    return R.concat(Object.values(list1), Object.values(list2))
    // previous location and next location are on the same floor, so only need one set of results
  } else return await bus.get('poi/getByFloorId', { floorId: previousFloorId })
}

function filterNearby (pois) {
  const poisArray = Object.values(pois)
  const uniquePois = R.uniqBy(R.prop('poiId'), poisArray)
  const isNotPortal = poi => poi.category.indexOf('portal') === -1 && poi.category !== 'element.door'
  const noPortalPois = Object.values(R.pickBy(isNotPortal, uniquePois))
  return noPortalPois
}

export async function checkRoutePath (bus, endpoints = [], direction = 'forward') {
  if (endpoints.length === 0) {
    return { routeExists: false, hasSecurity: false, hasImmigration: false }
  }

  const { routeExists, hasSecurity, hasImmigration } = await R.aperture(2, endpoints).reduce(
    async (promiseAcc, value) => {
      const [from, to] = direction === 'reverse' ? [value[1], value[0]] : [value[0], value[1]]

      const routeCheck = await bus.get('wayfinder/checkIfPathHasSecurity', {
        fromEndpoint: from,
        toEndpoint: to
      })

      const acc = await promiseAcc

      acc.routeExists &&= routeCheck.routeExists
      acc.hasSecurity ||= routeCheck.hasSecurity
      acc.hasImmigration ||= routeCheck.hasImmigration

      return acc
    },
    { routeExists: true, hasSecurity: false, hasImmigration: false }
  )

  return {
    routeExists,
    hasSecurity,
    hasImmigration
  }
}

export async function leavingSecurityWarning (bus, endpoints = []) {
  if (endpoints.length === 0) return

  const { hasSecurity } = await checkRoutePath(bus, endpoints, 'reverse')
  return hasSecurity
}

export async function getDirectAlternateRoutes (bus, endpoints = [], options = {}) {
  const { directRoutes, alternativeRoutes } = await R.aperture(2, endpoints).reduce(async (promiseAcc, value) => {
    const directRoute = await bus.get('wayfinder/getRoute', { fromEndpoint: value[0], toEndpoint: value[1], options })
    const alternativeRoute = await bus.get('wayfinder/getRoute', { fromEndpoint: value[0], toEndpoint: value[1], options: { ...options, requiresAccessibility: true } })

    const acc = await promiseAcc

    acc.directRoutes = acc.directRoutes.concat(directRoute)
    acc.alternativeRoutes = acc.alternativeRoutes.concat(alternativeRoute)

    return acc
  }, { directRoutes: [], alternativeRoutes: [] })

  return {
    directRoutes,
    alternativeRoutes
  }
}

// an endpoint is considered "defined" if it has either a `chosenPoi` object defined or a `location` object
export const endPointDefined = R.either(R.prop('chosenPoi'), R.prop('location'))
// for multipoint routing we don't just need the 'from' and 'to' to be defined but every search input box should have a defined value
export const allEndPointsDefined = (searchInputs) =>
  Boolean(
    searchInputs &&
        Object.keys(searchInputs).every((key) =>
          endPointDefined(searchInputs[key])
        ) &&
        Object.keys(searchInputs).length >= 2
  )
export const definedEndpointCount = (searchInputs) =>
  Object.keys(searchInputs).reduce((count, key) => count + (endPointDefined(searchInputs[key]) ? 1 : 0), 0)
