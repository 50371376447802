import {
  sortBy as rSortBy,
  prop as rProp,
  propEq
} from 'ramda'
import React from 'react'
import Zousan from 'zousan'

import { StateObjMultiple } from '../../../../src/utils/stateObj.js'
import { widgetEnum } from '../../../../utils/constants.js'

import MapLevelSelectorOnline from './mapLevelSelectorOnline.jsx'
import MapLevelSelectorViewMobile from './MapLevelSelectorViewMobile.jsx'
import { ResultsOnOtherFloorsBanner } from './ResultsOnOtherFloorsBanner.js'

function create (app, config) {
  const init = () => {
    app.bus.send('venueData/loadBuildingSelectorData')
  }
  const widgetState = new StateObjMultiple({
    buildings: [],
    searchResults: [],
    viewport: {},
    isMenuOpen: false
  })
  let selectorDataLoaded = new Zousan()

  app.bus.send('layers/register', {
    id: 'ResultsOnOtherLevelsBanner',
    widget: () => <ResultsOnOtherFloorsBanner
      widgetState={widgetState}
      bus={app.bus} />,
    isOverlay: true,
    layoutId: 'map',
    show: true,
    widgetType: widgetEnum.Mobile,
    shouldShow: currentlyShowing => currentlyShowing.includes('SearchResultsHeader')
  })

  app.bus.send('layers/register', {
    id: 'mapLevelSelectorOnline',
    widget: () => <MapLevelSelectorOnline
      bus={app.bus}
      widgetState={widgetState}
      T={app.gt()}
      isMobile={app.env.isMobile()}
    />,
    isOverlay: true,
    layoutId: 'mapControls',
    shouldShow: currentlyShowing => {
      if (app.config.uiHide && app.config.uiHide.levelSelector) return false // allows uiHide configuration parameter to hide this control
      if (!app.env.isMobile()) return true
      if (currentlyShowing.includes('SearchResultsBody') && currentlyShowing.includes('SearchResultsHeader')) return true
      const widgetsToCheck = ['SearchResultsHeader', 'DirectionsSearchControls', 'DirectionsResultControls', 'DirectionsResultMobile', 'poiViewMobile', 'SearchResultsCategoryList']
      return !widgetsToCheck.some(widget => currentlyShowing.includes(widget))
    },
    order: 30
  })

  app.bus.send('layers/register', {
    id: 'mapLevelSelectorViewMobile',
    widget: () =>
      <MapLevelSelectorViewMobile
        widgetState={widgetState}
        bus={app.bus}
        T={app.gt()}
      />,
    layoutId: 'mobileLevelSelector',
    isOverlay: true,
    show: true,
    widgetType: widgetEnum.Mobile
  })

  const notifyState = () => {
    // const { selectedBuilding, selectedLevel } = widgetState.getState()
    // app.bus.send('deepLinking/notifyState', {
    //   id: 'mapLevelSelectorOnline',
    //   selectedBuildingId: selectedBuilding ? selectedBuilding.id : null,
    //   selectedLevelId: selectedLevel ? selectedLevel.id : null
    // })
  }

  app.bus.on('venueData/buildingSelectorDataLoaded', ({ buildings, structureOrder, selectorOrder }) => {
    const orderBy = selectorOrder || structureOrder || []
    const buildingsWithOrder = buildings.map((building, index) => ({
      ...building,
      order: (orderBy.length > 0 ? orderBy.indexOf(building.id) : index) + 1
    }))
    const orderedBuildings = rSortBy(rProp('order'), buildingsWithOrder)
    const selectorData = { buildings: orderedBuildings }
    widgetState.update(selectorData)
    selectorDataLoaded.resolve(selectorData)
    notifyState()
  })

  const changeSelection = (structure, floor) => {
    const { buildings } = widgetState.getState()
    // do not show building which is not listed in level selector
    if (structure && buildings.find(propEq(structure.id, 'id')))
      widgetState.update({ selectedBuilding: structure, selectedLevel: floor })
    else
      widgetState.update({ selectedBuilding: null, selectedLevel: null })
    notifyState()
  }

  app.bus.on('map/floorChanged', ({ structure, floor }) => changeSelection(structure, floor))

  app.bus.on('map/getCurrentStructure', async () => {
    const { selectedBuilding, selectedLevel } = widgetState.getState()
    return { building: selectedBuilding, level: selectedLevel }
  })

  app.bus.on('venueData/loadNewVenue', () => {
    selectorDataLoaded = new Zousan()
    init()
  })

  app.bus.on('searchResults/showPOIs', ({ pois = [] }) => widgetState.update({ searchResults: pois }))

  app.bus.on('app/reset', () => {
    app.bus.send('mapLevelSelectorOnline/openLevelSelector', ({ isOpen: false }))
  })

  const clearSearchResults = () => widgetState.update({ searchResults: [] })

  app.bus.on('headerOnline/show', clearSearchResults)
  app.bus.on('navigation/show', clearSearchResults)

  app.bus.on('mapLevelSelectorOnline/openLevelSelector', ({ isOpen = true }) => {
    const { isMenuOpen } = widgetState.getState()
    if (isMenuOpen !== isOpen) {
      widgetState.update({ isMenuOpen: isOpen })
      if (app.env.isMobile()) app.bus.send('screen/displayHeader', { display: !isOpen })
    }
  })

  app.bus.on('map/userMoveStart', () => {
    const { isMenuOpen } = widgetState.getState()
    if (isMenuOpen && app.env.isMobile()) {
      app.bus.send('mapLevelSelectorOnline/openLevelSelector', { isOpen: false })
    }
  })

  return {
    init
  }
}

export {
  create
}
