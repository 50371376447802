/* eslint-disable camelcase */
import { pipeline, normalize, regex_whitespace, regex } from './lang.js'

export const rtl = /* normalize: */ /* collapse: */ /* normalize: */ /* collapse: */ /* normalize: */ /* collapse: */ /* normalize: */ /* collapse: */
/* collapse: */!1
export const tokenize = ''
export default {
  encode,
  rtl: !1,
  tokenize: ''

  // Charset Normalization

}; const // regex_whitespace = /\W+/,
// regex_strip = regex("[^a-z0-9 ]"),
  regex_a = regex('[àáâãäå]')
const regex_e = regex('[èéêë]')
const regex_i = regex('[ìíîï]')
const regex_o = regex('[òóôõöő]')
const regex_u = regex('[ùúûüű]')
const regex_y = regex('[ýŷÿ]')
const regex_n = regex('ñ')
const regex_c = regex('[çc]')
const regex_s = regex('ß')
const regex_and = regex(' & ')
const pairs = [regex_a, 'a', regex_e, 'e', regex_i, 'i', regex_o, 'o', regex_u, 'u', regex_y, 'y', regex_n, 'n', regex_c, 'k', regex_s, 's', regex_and, ' and '
  // regex_whitespace, " "
  // regex_strip, ""
]

/**
 * @param {string|number} str
 * @this IndexInterface
 */

export function encode (str) {
  str = '' + str

  return pipeline.call(this,
    /* string: */normalize(str).toLowerCase(),
    /* normalize: */!str.normalize && pairs,
    /* split: */regex_whitespace, !1)
}
