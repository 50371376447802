import * as R from 'ramda'
import Zousan from 'zousan'

import redCrosshatchUrl from '../img/red-crosshatch.png'

import * as utils from './utils.js'

const CROSSHATCH_LAYER = 'poi-crosshatch'
const POI_POLYGON_LAYER = 'polygons'
const PATTERN_NAME = 'red-crosshatch'

export default function DynamicPoiStyleController (app, mapInitialized) {
  if (Object.prototype.hasOwnProperty.call(app.config.plugins, 'nexusDynamicPois')) {
    waitForMapIdle(mapInitialized).then(map => {
      addCrossHatchLayer(map)

      whenOpenClosedEvent(app, renderClosedPoisOnMap(map))
    })
  }
}

const waitForMapIdle = (mapInitialized) => {
  return mapInitialized().then(async map => {
    const isIdle = new Zousan()

    map.once('idle', () => {
      // Have to wait for polygons to load
      isIdle.resolve(map)
    })

    return isIdle
  })
}

const addCrossHatchLayer = async map => {
  await loadCrosshatchImage(map) // will throw if image not found
  const polygonHeight = getPolygonLayerExtrusionHeight(map)

  map.addLayer({
    id: CROSSHATCH_LAYER,
    type: 'fill-extrusion',
    source: 'llOrdinalSource', // Source containing features for all POIs?
    paint: {
      'fill-extrusion-pattern': PATTERN_NAME,
      'fill-extrusion-height': polygonHeight
    },
    filter: ['in', 'DOES NOT EXIST', ''] // match nothing
  })

  map.moveLayer(CROSSHATCH_LAYER, POI_POLYGON_LAYER)
  map.moveLayer(POI_POLYGON_LAYER, CROSSHATCH_LAYER) // Put this layer in front of "polygons" layer
}

const loadCrosshatchImage = async map =>
  map.loadImage(redCrosshatchUrl)
    .then(image => map.addImage(PATTERN_NAME, image.data))

const getPolygonLayerExtrusionHeight = (map) => {
  // Polygon layer is the layer, which is responsible for POI polygon height?
  const layer = R.find(R.propEq(POI_POLYGON_LAYER, 'id'), map.getStyle().layers)
  return layer.paint['fill-extrusion-height']
}

const whenOpenClosedEvent = (app, fn) => {
  app.bus.monitor('poi/setNexusDynamicData', event => {
    if (event.plugin === 'open-closed-status') {
      fn(event)
    }
  })
}

const renderClosedPoisOnMap = map => event => {
  const poiIds = utils.getClosedPois(event)

  showCrosshatchLayerForPois(map, poiIds)
}

const showCrosshatchLayerForPois = async (map, poiIds) => map.setFilter(CROSSHATCH_LAYER, ['in', 'id', ...poiIds])
