import React from 'react'
import styled from 'styled-components'

import PoiHeader from './components/PoiHeader.jsx'
import { getSectionWidget } from './poiSections.js'

const InnerLayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ScrollableContent = styled.div`
  padding: 12px 12px 0;
  overflow-y: auto;
  position: relative;
`

export const PoiViewWidgetDesktopImpl = ({ className, poi, layout, T, venueCategory, venueTimezone, lang, structures, submitAnalyticsEvent, submitAnalyticsEventWithAppInsights, searchInputsLength, hidePoiFeedback, venueId }) => {
  return (
    <InnerLayoutStyled className={className} data-cy='PoiViewWidgetDesktop'>
      <ScrollableContent>
        <PoiHeader poi={poi} T={T} />
        {layout.map(sectionName => {
          const $Component = getSectionWidget(sectionName)
          return $Component
            ? <$Component
              key={sectionName}
              poi={poi} T={T}
              venueCategory={venueCategory}
              venueTimezone={venueTimezone}
              structures={structures}
              lang={lang}
              submitAnalyticsEvent={submitAnalyticsEvent}
              submitAnalyticsEventWithAppInsights={submitAnalyticsEventWithAppInsights}
              searchInputsLength={searchInputsLength}
              hidePoiFeedback={hidePoiFeedback}
              venueId={venueId}
            />
            : null
        })
        }
      </ScrollableContent>
    </InnerLayoutStyled>
  )
}

export default PoiViewWidgetDesktopImpl
